import * as React from 'react';
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }
    componentDidCatch() {
        this.setState({
            hasError: true,
        });
    }
    render() {
        if (this.state.hasError) {
            return (React.createElement("div", { className: "error-boundary" }, this.props.errorMessage || 'Something went wrong.'));
        }
        return this.props.children;
    }
}
