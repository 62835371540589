import { EDIT_STYLE, REPLACE_STATE } from 'actions';
import { styleDefaults } from 'utils';
export function style(state = styleDefaults, action) {
    switch (action.type) {
        case EDIT_STYLE:
            return Object.assign(Object.assign({}, state), action.edits);
        case REPLACE_STATE:
            return action.replaceWith.style;
        default:
            return state;
    }
}
