import { Icon } from '@blueprintjs/core';
import * as React from 'react';
export class BaseEditor extends React.Component {
    constructor() {
        var _a;
        super(...arguments);
        this.state = {
            isOpen: (_a = this.props.defaultOpen) !== null && _a !== void 0 ? _a : BaseEditor.defaultProps.defaultOpen,
        };
        this.toggleEditor = () => {
            this.setState({ isOpen: !this.state.isOpen });
        };
    }
    render() {
        return (React.createElement("div", { "data-testid": "base-editor", className: `${this.props.name.toLowerCase().replace(/\s/g, '-')}-editor base-editor` },
            React.createElement("h4", { title: `${this.state.isOpen ? 'Collapse' : 'Open'} this editor.`, className: "font-bold flex content-center justify-between m-1 mb-2 cursor-pointer text-base", onClick: this.toggleEditor },
                React.createElement("span", null, this.props.name),
                React.createElement(Icon, { icon: this.state.isOpen ? 'caret-up' : 'caret-down' })),
            this.state.isOpen ? this.props.children : null));
    }
}
BaseEditor.defaultProps = {
    defaultOpen: true,
};
