export const TrainerKeys = {
    name: 'Red',
    id: '00123',
    time: '04:33',
    money: '$144',
    badges: [],
    expShareStatus: 'ON',
    image: 'http://placeholder.com',
    title: 'Trainer Title',
    notes: 'None yet.',
    totalTime: '190:33',
    hasEditedCheckpoints: false,
};
