import * as React from 'react';
import { Button, Intent, Popover, Icon, PopoverInteractionKind, Classes, Toaster } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { addBox } from 'actions';
import { wallpapers } from './Box';
const baseBox = {
    name: '',
    background: 'grass-meadow',
    inheritFrom: 'Team',
};
export class BoxFormBase extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isBoxFormOpen: false,
            newBox: baseBox,
        };
        this.toggleBoxForm = (e) => {
            this.setState({ isBoxFormOpen: !this.state.isBoxFormOpen });
        };
        this.confirmNewBox = (e) => {
            try {
                this.props.addBox(this.state.newBox);
                this.setState({ newBox: baseBox });
            }
            catch (e) {
                const toaster = Toaster.create();
                toaster.show({
                    message: 'Cannot name a box the same as a current one.',
                    intent: Intent.DANGER,
                });
            }
        };
        this.editFormInput = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            this.setState({
                newBox: Object.assign(Object.assign({}, this.state.newBox), { [name]: value }),
            });
        };
    }
    render() {
        const { boxes } = this.props;
        const { isBoxFormOpen } = this.state;
        const inputStyle = {
            margin: '0 auto',
            marginTop: '0.25rem',
            display: 'flex',
            alignItems: 'baseline',
        };
        const labelStyle = {
            marginLeft: '1rem',
            marginRight: '.5rem',
            width: '8rem',
            display: 'flex',
            alignItems: 'center',
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: this.toggleBoxForm, icon: 'plus', className: Classes.SMALL, style: {
                    margin: '.25rem',
                    height: '2rem',
                    width: '2rem',
                    float: 'right',
                    marginTop: '-.75rem',
                    borderRadius: '50%',
                    transition: '200ms',
                    transform: isBoxFormOpen ? 'rotate(135deg)' : undefined,
                }, intent: Intent.SUCCESS }),
            React.createElement("div", { style: { clear: 'both' } }),
            isBoxFormOpen && (React.createElement("div", { style: {
                    border: this.props.style.editorDarkMode
                        ? '1px solid #333'
                        : '1px solid #ddd',
                    borderRadius: '.25rem',
                    boxShadow: 'rgba(0, 0, 0, 0.33)',
                    margin: '.25rem',
                    marginTop: '-1rem',
                    padding: '0.5rem',
                } },
                React.createElement("div", { style: inputStyle },
                    React.createElement("label", { style: labelStyle, className: Classes.LABEL }, "Name"),
                    React.createElement("input", { required: true, className: Classes.INPUT, autoComplete: "false", onInput: this.editFormInput, value: this.state.newBox.name, name: "name", placeholder: "Box Name" })),
                React.createElement("div", { style: inputStyle },
                    React.createElement("label", { style: labelStyle, className: Classes.LABEL },
                        "Background",
                        ' ',
                        React.createElement(Popover, { minimal: true, interactionKind: PopoverInteractionKind.HOVER, content: React.createElement("div", { style: { width: '160px', padding: '0.25rem' } },
                                "Can be a URL or",
                                ' ',
                                wallpapers.map((w) => w.background).join(', ')) },
                            React.createElement(Icon, { style: { marginLeft: '.25rem' }, icon: "info-sign" }))),
                    React.createElement("input", { className: Classes.INPUT, onInput: this.editFormInput, value: this.state.newBox.background, name: "background", placeholder: "Box Background" })),
                React.createElement("div", { style: inputStyle },
                    React.createElement("label", { style: labelStyle, className: Classes.LABEL }, "Inherit From..."),
                    React.createElement("div", { className: Classes.SELECT },
                        React.createElement("select", { onChange: this.editFormInput, value: this.state.newBox.inheritFrom, name: "inheritFrom" }, ['Team', 'Boxed', 'Dead', 'Champs'].map((box, idx) => (React.createElement("option", { key: idx, value: box }, box)))))),
                React.createElement("div", { style: {
                        display: 'flex',
                        justifyContent: 'center',
                    } },
                    React.createElement(Button, { onClick: this.toggleBoxForm, intent: Intent.DANGER, style: { margin: '0 .5rem' }, minimal: true }, "Cancel"),
                    React.createElement(Button, { style: { margin: '0 .5rem' }, onClick: this.confirmNewBox, intent: Intent.SUCCESS, disabled: !this.state.newBox.name }, "Confirm"))))));
    }
}
export const BoxForm = connect((state) => ({
    style: state.style,
}), {
    addBox,
})(BoxFormBase);
