import * as React from 'react';
import { style } from 'reducers/style';
export var LayoutDisplay;
(function (LayoutDisplay) {
    LayoutDisplay["Block"] = "flex";
    LayoutDisplay["Inline"] = "inline-flex";
})(LayoutDisplay || (LayoutDisplay = {}));
export var LayoutDirection;
(function (LayoutDirection) {
    LayoutDirection["Column"] = "column";
    LayoutDirection["Row"] = "row";
    LayoutDirection["ColumnReverse"] = "column-reverse";
    LayoutDirection["RowReverse"] = "row-reverse";
})(LayoutDirection || (LayoutDirection = {}));
export var LayoutSpacing;
(function (LayoutSpacing) {
    LayoutSpacing["End"] = "flex-end";
    LayoutSpacing["Start"] = "flex-start";
    LayoutSpacing["Center"] = "center";
    LayoutSpacing["SpaceBetween"] = "space-between";
    LayoutSpacing["SpaceEvenly"] = "space-evenly";
    LayoutSpacing["SpaceAround"] = "space-around";
})(LayoutSpacing || (LayoutSpacing = {}));
export var LayoutAlignment;
(function (LayoutAlignment) {
    LayoutAlignment["End"] = "flex-end";
    LayoutAlignment["Start"] = "flex-start";
    LayoutAlignment["Center"] = "center";
    LayoutAlignment["SpaceBetween"] = "space-between";
    LayoutAlignment["SpaceAround"] = "space-around";
    LayoutAlignment["SpaceEvenly"] = "space-evenly";
})(LayoutAlignment || (LayoutAlignment = {}));
export var LayoutWrap;
(function (LayoutWrap) {
    LayoutWrap["Wrap"] = "wrap";
    LayoutWrap["NoWrap"] = "no-wrap";
})(LayoutWrap || (LayoutWrap = {}));
export class Layout extends React.PureComponent {
    render() {
        const { display, direction, alignment, spacing, wrap, name } = this.props;
        return (React.createElement("div", { "data-testid": "layout", "data-name": (name !== null && name !== void 0 ? name : '').replace(/\s/g, '-').toLowerCase(), style: Object.assign({ display: display, flexDirection: direction, justifyContent: spacing, alignItems: alignment, flexWrap: wrap }, style) }, this.props.children));
    }
}
Layout.defaultProps = {
    display: LayoutDisplay.Block,
    direction: LayoutDirection.Row,
    alignment: LayoutAlignment.Start,
    spacing: LayoutSpacing.Start,
    wrap: 'wrap',
    style: {},
    name: '',
};
