import * as React from 'react';
import { connect } from 'react-redux';
import { editStyle } from 'actions';
import { capitalize, gameOfOriginToColor, listOfThemes, feature, } from 'utils';
import { RadioGroup, Radio, TextArea, Checkbox, Button, Intent, Classes, Drawer, Icon, } from '@blueprintjs/core';
import { BaseEditor } from 'components/BaseEditor';
import { ColorEdit, rgbaOrHex } from 'components/Shared';
import { cx } from 'emotion';
import * as Styles from './styles';
import { ThemeEditor } from 'components/ThemeEditor';
import { customCSSGuide as text } from 'utils/customCSSGuide';
const ReactMarkdown = require('react-markdown');
const debounce = require('lodash.debounce');
const editEvent = (e, props, name, game) => {
    const propName = name || e.target.name;
    props.editStyle({ [propName]: e.target.value });
    if (propName === 'template' && e.target.value === 'Default Light') {
        props.editStyle({ bgColor: '#eeeeee', topHeaderColor: '#dedede' });
    }
    if (propName === 'template' && e.target.value === 'Default Dark') {
        props.editStyle({ bgColor: '#383840', topHeaderColor: '#333333' });
    }
    if (propName === 'template' && e.target.value === 'Compact with Icons') {
        props.editStyle({ imageStyle: 'square' });
    }
    if (propName === 'template' && e.target.value === 'Cards') {
        props.editStyle({ imageStyle: 'square', movesPosition: 'horizontal' });
    }
    if (propName === 'template' && e.target.value === 'Hexagons') {
        props.editStyle({ resultWidth: 1320, accentColor: 'rgba(0, 0, 0, 0)', movesPosition: 'horizontal' });
    }
    if ((propName === 'template' && e.target.value === 'Generations') ||
        e.target.value === 'Generations Classic') {
        props.editStyle({
            bgColor: game ? gameOfOriginToColor(game) : '',
            minimalBoxedLayout: true,
            resultHeight: 870,
            resultWidth: 1460,
            movesPosition: 'vertical'
        });
    }
};
export const IconsNextToTeamPokemon = (props) => (React.createElement("div", { className: "style-edit" },
    React.createElement(Checkbox, { checked: props.style.iconsNextToTeamPokemon, name: "iconsNextToTeamPokemon", label: "Icons Next to Team Pok\u00E9mon", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'iconsNextToTeamPokemon') })));
export const smallItemOptions = ['outer glow', 'round', 'square', 'text'];
export const TextAreaDebounced = ({ edit, props, name, }) => {
    const [value, setValue] = React.useState('');
    const delayedValue = React.useCallback(debounce((e) => edit(e, props, name), 300), [props.style[name]]);
    const onChange = (e) => {
        e.persist();
        setValue(e.target.value);
        delayedValue(e);
    };
    React.useEffect(() => {
        setValue(props.style[name]);
    }, [props.style[name]]);
    return (React.createElement(TextArea, { large: true, onChange: onChange, className: cx('custom-css-input', Classes.FILL), value: value, name: name }));
};
export class StyleEditorBase extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { isThemeEditorOpen: false, showChromePicker: false, isCSSGuideOpen: false };
        this.toggleThemeEditor = () => this.setState({ isThemeEditorOpen: !this.state.isThemeEditorOpen });
        this.toggleCSSGuide = () => this.setState({ isCSSGuideOpen: !this.state.isCSSGuideOpen });
    }
    render() {
        var _a;
        const props = this.props;
        const createStyleEdit = (isWidthHeight) => cx(Styles.styleEdit, {
            [Styles.styleEdit_dark]: props.style.editorDarkMode,
            [Styles.widthHeightInputs]: isWidthHeight,
        });
        const styleEdit = createStyleEdit(false);
        const teamImages = ['standard', 'sugimori', 'dream world', 'shuffle', 'tcg'];
        const calloutStyle = {
            marginLeft: '2px',
            fontSize: '80%',
            padding: '7px',
        };
        return (React.createElement(BaseEditor, { icon: 'style', name: "Style" },
            feature.themeEditing ? (React.createElement(Drawer, { isOpen: this.state.isThemeEditorOpen, onClose: this.toggleThemeEditor, size: Drawer.SIZE_LARGE, title: "Theme Editor", icon: "style", className: cx(Styles.dialog, {
                    [Classes.DARK]: props.style.editorDarkMode,
                }) },
                React.createElement(ThemeEditor, null))) : null,
            React.createElement(Drawer, { isOpen: this.state.isCSSGuideOpen, onClose: this.toggleCSSGuide, size: Drawer.SIZE_LARGE, title: "CSS Guide", icon: "style", className: cx(Styles.dialog, {
                    [Classes.DARK]: props.style.editorDarkMode,
                }, 'release-notes-wrapper') },
                React.createElement(ReactMarkdown, { source: text })),
            React.createElement("div", { className: styleEdit },
                React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Template"),
                React.createElement("div", { className: Classes.SELECT },
                    React.createElement("select", { className: Classes.SELECT, name: "template", onChange: (e) => editEvent(e, props, undefined, props.game.name), value: props.style.template }, listOfThemes.map((o) => (React.createElement("option", { key: o }, o))))),
                feature.themeEditing ? (React.createElement(Button, { onClick: this.toggleThemeEditor, style: { marginLeft: '.25rem' }, intent: Intent.PRIMARY, minimal: true }, "Edit Theme")) : null),
            React.createElement("div", { className: styleEdit },
                React.createElement(RadioGroup, { className: cx(Styles.radioGroup), label: "Image Style", onChange: (e) => editEvent(e, props, 'imageStyle'), selectedValue: props.style.imageStyle },
                    React.createElement(Radio, { label: "Round", value: "round" }),
                    React.createElement(Radio, { label: "Square", value: "square" }))),
            React.createElement("div", { className: styleEdit },
                React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Item Style"),
                React.createElement("div", { className: Classes.SELECT },
                    React.createElement("select", { name: "itemStyle", onChange: (e) => editEvent(e, props, undefined), value: props.style.itemStyle }, smallItemOptions.map((v) => {
                        return (React.createElement("option", { key: v, value: v }, capitalize(v)));
                    })))),
            React.createElement("div", { className: styleEdit },
                React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Pok\u00E9ball Style"),
                React.createElement("div", { className: Classes.SELECT },
                    React.createElement("select", { name: "pokeballStyle", onChange: (e) => editEvent(e, props, undefined), value: props.style.pokeballStyle }, smallItemOptions.map((v) => {
                        return (React.createElement("option", { key: v, value: v }, capitalize(v)));
                    })))),
            React.createElement("div", { className: createStyleEdit(true) },
                React.createElement("div", null,
                    React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Result Dimensions"),
                    React.createElement("span", { style: { fontSize: '80%', marginRight: '2px' } }, "w"),
                    React.createElement("input", { name: "resultWidth", className: cx(Classes.INPUT, 'small-input'), onChange: (e) => editEvent(e, props), value: props.style.resultWidth, type: "number", min: "0", step: "10" }),
                    React.createElement(Icon, { icon: 'cross', style: { marginRight: '0' } }),
                    React.createElement("span", { style: { fontSize: '80%', marginRight: '2px' } }, "h"),
                    React.createElement("input", { name: "resultHeight", className: cx(Classes.INPUT, 'small-input'), style: {
                            opacity: props.style.useAutoHeight ? 0.3 : 1,
                        }, onChange: (e) => editEvent(e, props), value: props.style.resultHeight, type: "number", min: "0", step: "10" })),
                React.createElement("div", { className: Styles.autoHeightCheckbox },
                    React.createElement("span", null, " "),
                    React.createElement(Checkbox, { style: {
                            marginBottom: '0',
                            marginLeft: '10px',
                        }, checked: props.style.useAutoHeight, name: "useAutoHeight", label: "Auto Height", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'useAutoHeight') }))),
            React.createElement("div", { className: createStyleEdit(true) },
                React.createElement("div", null,
                    React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Trainer Dimensions"),
                    React.createElement("span", { style: { fontSize: '80%', marginRight: '2px' } }, "w"),
                    React.createElement("input", { name: "trainerWidth", className: cx(Classes.INPUT, 'small-input'), onChange: (e) => editEvent(e, props), style: {
                            opacity: props.style.trainerAuto ? 0.3 : 1,
                        }, value: props.style.trainerWidth }),
                    React.createElement(Icon, { icon: 'cross', style: { marginRight: '0' } }),
                    React.createElement("span", { style: { fontSize: '80%', marginRight: '2px' } }, "h"),
                    React.createElement("input", { name: "trainerHeight", className: cx(Classes.INPUT, 'small-input'), style: {
                            opacity: props.style.trainerAuto ? 0.3 : 1,
                        }, onChange: (e) => editEvent(e, props), value: props.style.trainerHeight })),
                React.createElement("div", { className: Styles.autoHeightCheckbox },
                    React.createElement("span", null, " "),
                    React.createElement(Checkbox, { style: {
                            marginBottom: '0',
                            marginLeft: '10px',
                        }, checked: props.style.trainerAuto, name: "trainerAuto", label: "Auto Dimensions", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'trainerAuto') }))),
            React.createElement("div", { className: styleEdit },
                React.createElement(RadioGroup, { className: cx(Styles.radioGroup), label: "Trainer Section Position", onChange: (e) => editEvent(e, props, 'trainerSectionOrientation'), selectedValue: props.style.trainerSectionOrientation },
                    React.createElement(Radio, { label: "Horizontal", value: "horizontal" }),
                    React.createElement(Radio, { label: "Vertical", value: "vertical" }))),
            React.createElement("div", { className: styleEdit },
                React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Background color"),
                React.createElement(ColorEdit, { onChange: (e) => editEvent(e, props), name: 'bgColor', value: rgbaOrHex(props.style.bgColor), onColorChange: (color) => editEvent({ target: { value: rgbaOrHex(color) } }, props, 'bgColor') })),
            React.createElement("div", { className: styleEdit },
                React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Accent color"),
                React.createElement(ColorEdit, { onChange: (e) => editEvent(e, props), name: 'accentColor', value: props.style.accentColor, onColorChange: (color) => editEvent({ target: { value: rgbaOrHex(color) } }, props, 'accentColor') })),
            React.createElement("div", { className: styleEdit },
                React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Header color"),
                React.createElement(ColorEdit, { name: "topHeaderColor", onChange: (e) => editEvent(e, props), value: props.style.topHeaderColor, onColorChange: (color) => editEvent({ target: { value: rgbaOrHex(color) } }, props, 'topHeaderColor') })),
            React.createElement("div", { className: styleEdit },
                React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Background Image"),
                React.createElement("input", { value: props.style.backgroundImage, name: "backgroundImage", onChange: (e) => editEvent(e, props), className: Classes.INPUT }),
                React.createElement("span", null, " "),
                React.createElement(Checkbox, { style: {
                        marginBottom: '0',
                        marginLeft: '10px',
                    }, checked: props.style.tileBackground, name: "tileBackground", label: "Tile", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'tileBackground') })),
            React.createElement("div", { className: styleEdit },
                React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Rules Location"),
                React.createElement("div", { className: Classes.SELECT },
                    React.createElement("select", { name: "displayRulesLocation", onChange: (e) => editEvent(e, props, undefined), value: props.style.displayRulesLocation },
                        React.createElement("option", { key: 'inside trainer section' }, 'inside trainer section'),
                        React.createElement("option", { key: 'bottom' }, "bottom"),
                        React.createElement("option", { key: 'top' }, "top")))),
            React.createElement("div", { className: styleEdit },
                React.createElement(RadioGroup, { className: cx(Styles.radioGroup), label: "Icon Rendering", onChange: (e) => editEvent(e, props, 'iconRendering'), selectedValue: props.style.iconRendering },
                    React.createElement(Radio, { label: "Pixelated", value: "pixelated" }),
                    React.createElement(Radio, { label: "Automatic", value: "auto" }))),
            React.createElement("div", { className: styleEdit },
                React.createElement(RadioGroup, { className: cx(Styles.radioGroup), label: "Moves Position", onChange: (e) => editEvent(e, props, 'movesPosition'), selectedValue: props.style.movesPosition },
                    React.createElement(Radio, { label: "Horizontal", value: "horizontal" }),
                    React.createElement(Radio, { label: "Vertical", value: "vertical" }))),
            React.createElement("div", { className: styleEdit },
                React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Team Images"),
                React.createElement("div", { className: Classes.SELECT },
                    React.createElement("select", { name: "teamImages", onChange: (e) => editEvent(e, props, undefined, props.game.name), value: props.style.teamImages }, teamImages.map((o) => (React.createElement("option", { value: o, key: o }, capitalize(o)))))),
                (props.game.name === 'Sword' || props.game.name === 'Shield') &&
                    props.style.teamImages === 'shuffle' ? (React.createElement("div", { className: cx(Classes.CALLOUT, Classes.INTENT_DANGER), style: calloutStyle }, "Shuffle images are not supported for this game")) : null,
                [
                    'Sword',
                    'Shield',
                    'X',
                    'Y',
                    'Sun',
                    'Moon',
                    'Ultra Sun',
                    'Ultra Moon',
                ].includes(props.game.name) && props.style.teamImages === 'dream world' ? (React.createElement("div", { className: cx(Classes.CALLOUT, Classes.INTENT_DANGER), style: calloutStyle }, "Dream world images are not supported for this game")) : null,
                ['Sword', 'Shield'].includes(props.game.name) &&
                    props.style.teamImages === 'tcg' ? (React.createElement("div", { className: cx(Classes.CALLOUT, Classes.INTENT_DANGER), style: calloutStyle }, "TCG images are not fully supported for this game")) : null),
            React.createElement("div", { className: styleEdit },
                React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Pokemon Per Line (Boxed)"),
                React.createElement("input", { name: "boxedPokemonPerLine", className: cx(Classes.INPUT, 'small-input'), onChange: (e) => editEvent(e, props), value: props.style.boxedPokemonPerLine, type: "number", min: "01", step: "1", max: "20" })),
            React.createElement("div", { className: styleEdit },
                React.createElement("label", { className: cx(Classes.LABEL, Classes.INLINE) }, "Linked Pokemon Text"),
                React.createElement("input", { name: "linkedPokemonText", className: cx(Classes.INPUT), onChange: (e) => editEvent(e, props), value: (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.linkedPokemonText })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.teamPokemonBorder, name: "teamPokemonBorder", label: "Team Pok\u00E9mon Gradient Backgrounds", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'teamPokemonBorder') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.showPokemonMoves, name: "showPokemonMoves", label: "Show Pok\u00E9mon Moves", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'showPokemonMoves') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.minimalTeamLayout, name: "minimalTeamLayout", label: "Minimal Team Layout", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'minimalTeamLayout') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.minimalBoxedLayout, name: "minimalBoxedLayout", label: "Minimal Boxed Layout", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'minimalBoxedLayout') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.minimalDeadLayout, name: "minimalDeadLayout", label: "Minimal Dead Layout", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'minimalDeadLayout') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.minimalChampsLayout, name: "minimalChampsLayout", label: "Minimal Champs Layout", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'minimalChampsLayout') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.displayBadges, name: "displayBadges", label: "Display Badges", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'displayBadges') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.displayRules, name: "displayRules", label: "Display Nuzlocke Rules", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'displayRules') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.displayStats, name: "displayStats", label: "Display Stats", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'displayStats') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.displayExtraData, name: "displayExtraData", label: "Display Extra Data from Save Files", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'displayExtraData') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.usePokemonGBAFont, name: "usePokemonGBAFont", label: "Use Pok\u00E9mon GBA Font", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'usePokemonGBAFont') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.oldMetLocationFormat, name: "oldMetLocationFormat", label: "Old Met Location Format", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'oldMetLocationFormat') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.grayScaleDeadPokemon, name: "grayScaleDeadPokemon", label: "Gray Scale Filter Dead Pok\u00E9mon Images", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'grayScaleDeadPokemon') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.spritesMode, name: "spritesMode", label: "Sprites Mode", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'spritesMode') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.scaleSprites, name: "scaleSprites", label: "Scale Sprites", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'scaleSprites') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.useSpritesForChampsPokemon, name: "useSpritesForChampsPokemon", label: "Use Sprites for Champs Pok\u00E9mon", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'useSpritesForChampsPokemon') })),
            React.createElement("div", { className: styleEdit },
                React.createElement(Checkbox, { checked: props.style.displayGameOriginForBoxedAndDead, name: "displayGameOriginForBoxedAndDead", label: "Display Game Origin for Boxed and Dead", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'displayGameOriginForBoxedAndDead') })),
            React.createElement("div", { className: styleEdit, style: {
                    marginLeft: '1rem',
                    opacity: props.style.displayGameOriginForBoxedAndDead ? '1' : '0.3',
                    pointerEvents: props.style.displayGameOriginForBoxedAndDead
                        ? undefined
                        : 'none',
                } },
                React.createElement(Checkbox, { checked: props.style.displayBackgroundInsteadOfBadge, name: "displayBackgroundInsteadOfBadge", label: "Display Background Color Instead of Badge", onChange: (e) => editEvent(Object.assign(Object.assign({}, e), { target: { value: e.target.checked } }), props, 'displayBackgroundInsteadOfBadge') })),
            React.createElement("div", { className: "custom-css-input-wrapper" },
                React.createElement("label", { className: cx(Classes.LABEL, 'flex', 'justify-between') },
                    React.createElement("span", null, "Custom CSS"),
                    feature.themeEditing && React.createElement(Button, { minimal: true, intent: Intent.PRIMARY, onClick: this.toggleCSSGuide }, "Check out the CSS Guide!")),
                React.createElement(TextAreaDebounced, { name: "customCSS", props: props, edit: editEvent })),
            feature.resultv2 && React.createElement("div", { className: "custom-css-input-wrapper" },
                React.createElement("label", { style: { padding: '.5rem', marginBottom: 0 }, className: Classes.LABEL }, "Custom Team HTML "),
                React.createElement(TextAreaDebounced, { name: "customTeamHTML", props: props, edit: editEvent }))));
    }
}
export const StyleEditor = connect((state) => ({ style: state.style, game: state.game }), {
    editStyle,
})(StyleEditorBase);
