import { Button, Classes, Intent, Spinner } from '@blueprintjs/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { generateEmptyPokemon } from 'utils';
import { CurrentPokemonEdit } from '.';
import { AddPokemonButton } from 'components/AddPokemonButton';
import { BaseEditor } from 'components/BaseEditor';
import { Box, BoxForm } from 'components/Box';
import { ErrorBoundary } from 'components/Shared';
import { cx } from 'emotion';
import { addPokemon } from 'actions';
export class BoxesComponent extends React.Component {
    renderBoxes(boxes, team) {
        return boxes
            .sort((a, b) => {
            const positionA = a.position || 0;
            const positionB = b.position || 1;
            return positionA - positionB;
        })
            .map((box) => {
            return React.createElement(Box, Object.assign({ searchTerm: this.props.searchTerm || '' }, box, { key: box.id, pokemon: team }));
        });
    }
    render() {
        const { boxes, team } = this.props;
        return this.renderBoxes(boxes, team);
    }
}
const MassEditor = React.lazy(() => import('components/PokemonEditor/MassEditor'));
const PokemonLocationChecklist = React.lazy(() => import('components/PokemonEditor/PokemonLocationChecklist'));
export class PokemonEditorBase extends React.Component {
    constructor(props) {
        super(props);
        this.openMassEditor = (e) => {
            this.setState({
                isMassEditorOpen: true,
            });
        };
        this.state = {
            isMassEditorOpen: false,
            searchTerm: '',
        };
    }
    componentDidMount() {
    }
    render() {
        const { team, boxes, game, style, excludedAreas, customAreas } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(BaseEditor, { icon: 'circle', name: "Pokemon" },
                React.createElement("div", { "data-testid": "pokemon-editor", className: "button-row", style: { display: 'flex', alignItems: 'flex-start' } },
                    React.createElement(AddPokemonButton, { pokemon: Object.assign(Object.assign({}, generateEmptyPokemon(team)), { gameOfOrigin: this.props.game.name || 'None' }) }),
                    React.createElement("div", { style: { marginLeft: 'auto', width: '50%' } },
                        React.createElement(Button, { icon: 'heat-grid', intent: Intent.PRIMARY, onClick: this.openMassEditor, className: cx(Classes.MINIMAL, Classes.FILL) }, "Open Mass Editor"),
                        React.createElement("input", { type: 'search', placeholder: 'Search...', className: Classes.INPUT, value: this.state.searchTerm, onChange: e => this.setState({
                                searchTerm: e.target.value,
                            }), style: { margin: '0.25rem', width: '100%' } }))),
                React.createElement(BoxesComponent, { searchTerm: this.state.searchTerm, boxes: boxes, team: team }),
                React.createElement(BoxForm, { boxes: boxes }),
                React.createElement(CurrentPokemonEdit, null),
                React.createElement(BaseEditor, { name: "Location Checklist", defaultOpen: false },
                    React.createElement(React.Suspense, { fallback: React.createElement(Spinner, null) },
                        React.createElement(PokemonLocationChecklist, { customAreas: customAreas, excludedAreas: excludedAreas, boxes: boxes, style: style, pokemon: team, game: game })))),
            React.createElement(React.Suspense, { fallback: React.createElement(Spinner, null) }, this.state.isMassEditorOpen && (React.createElement(ErrorBoundary, null,
                React.createElement(MassEditor, { isOpen: this.state.isMassEditorOpen, toggleDialog: () => this.setState({
                        isMassEditorOpen: !this.state.isMassEditorOpen,
                    }) }))))));
    }
}
export const PokemonEditor = connect((state) => ({
    team: state.pokemon,
    boxes: state.box,
    game: state.game,
    style: state.style,
    excludedAreas: state.excludedAreas,
    customAreas: state.customAreas,
}), {
    addPokemon: addPokemon,
}, null, { pure: true })(PokemonEditorBase);
