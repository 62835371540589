import { CREATE_CUSTOM_TYPE, REPLACE_STATE, DELETE_CUSTOM_TYPE, EDIT_CUSTOM_TYPE, } from 'actions';
const uuid = require('uuid');
export function customTypes(state = [], action) {
    switch (action.type) {
        case CREATE_CUSTOM_TYPE:
            return [
                ...state,
                Object.assign({ id: uuid() }, action.typeInfo),
            ];
        case DELETE_CUSTOM_TYPE:
            return state.filter((type) => type.id !== action.id);
        case EDIT_CUSTOM_TYPE:
            return state.map((type) => {
                if (type.id !== action.id) {
                    return type;
                }
                return Object.assign(Object.assign({}, type), action.typeInfo);
            });
        case REPLACE_STATE:
            return action.replaceWith.customTypes || [];
        default:
            return state;
    }
}
