var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { connect } from 'react-redux';
import { getIconFormeSuffix, significantGenderDifferenceList, } from 'utils';
import { Gender } from 'components/Shared';
import { editPokemon, selectPokemon } from 'actions';
import { store } from 'store';
import { DragSource, DropTarget } from 'react-dnd';
import { normalizeSpeciesName } from 'utils/getters/normalizeSpeciesName';
import { PokemonImage } from 'components/Shared/PokemonImage';
const iconSourceDrop = {
    drop(props, monitor, component) {
        const newPosition = props.position;
        const newId = props.id;
        const newStatus = props.status;
        const item = monitor.getItem();
        const oldId = item === null || item === void 0 ? void 0 : item.id;
        const oldPosition = item === null || item === void 0 ? void 0 : item.position;
        const oldStatus = item === null || item === void 0 ? void 0 : item.status;
        if (oldId == null || oldPosition == null || oldStatus == null) {
            return;
        }
        store.dispatch(editPokemon({
            position: oldPosition,
            status: oldStatus,
        }, newId));
        store.dispatch(editPokemon({
            position: newPosition,
            status: newStatus,
        }, oldId));
        return {};
    },
    hover(props, monitor) {
        return {
            isHovering: monitor.isOver({ shallow: true })
        };
    }
};
const iconSource = {
    beginDrag(props) {
        store.dispatch(selectPokemon(props.id));
        return {
            id: props.id,
            position: props.position,
            status: props.status,
        };
    },
    isDragging(props) {
        return {
            id: props.id,
            position: props.position,
            status: props.status,
        };
    },
};
export const getIconURL = ({ id, species, forme, shiny, gender, customIcon, egg }) => {
    const baseURL = 'icons/pokemon/';
    const isShiny = shiny ? 'shiny' : 'regular';
    const isFemaleSpecific = significantGenderDifferenceList.includes(species) && Gender.isFemale(gender)
        ? 'female/'
        : '';
    if (species === 'Egg' || egg)
        return `${baseURL}egg.png`;
    return `${baseURL}${isShiny}/${isFemaleSpecific}${normalizeSpeciesName(species)}${getIconFormeSuffix(forme)}.png`;
};
export function PokemonIconPlain({ isDragging, canDrop, id, gender, species, forme, onClick, selectedId, className, shiny, egg, style, customIcon, includeTitle, imageStyle, }) {
    return (React.createElement("div", { role: "presentation", onClick: (e) => {
            e.preventDefault();
            onClick && onClick();
        }, id: id, title: includeTitle ? species : undefined, style: style, className: `${id === selectedId ? 'pokemon-icon selected' : 'pokemon-icon'} ${className || ''} ${isDragging ? 'opacity-medium' : ''} ${canDrop ? 'droppable' : ''}` }, customIcon ? React.createElement(PokemonImage, { url: customIcon }, (image) => React.createElement("img", { style: imageStyle, alt: species, src: image })) : React.createElement("img", { style: imageStyle, alt: species, onError: ({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = 'icons/pokemon/unknown.png';
        }, src: getIconURL({
            id,
            species,
            forme,
            shiny,
            gender,
            egg,
            customIcon,
        }) })));
}
let PokemonIconBase = class PokemonIconBase extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { connectDragSource, connectDropTarget, styles, hidden } = this.props;
        const imageStyle = {
            maxHeight: '100%',
            opacity: hidden ? 0.5 : 1,
            height: '32px',
            maxWidth: 'auto',
            imageRendering: styles === null || styles === void 0 ? void 0 : styles.iconRendering,
        };
        return connectDropTarget(connectDragSource(React.createElement("div", null,
            React.createElement(PokemonIconPlain, Object.assign({ imageStyle: imageStyle }, this.props)))));
    }
};
PokemonIconBase = __decorate([
    DragSource('ICON', iconSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    })),
    DropTarget('ICON', iconSourceDrop, (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        canDrop: monitor.canDrop(),
    }))
], PokemonIconBase);
export { PokemonIconBase };
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClick: () => {
            dispatch(selectPokemon(ownProps.id));
        },
    };
};
export const PokemonIcon = connect((state) => ({ selectedId: state.selectedId, styles: state.style }), mapDispatchToProps)(PokemonIconBase);
