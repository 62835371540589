import { Types } from 'utils';
export const PokemonKeys = {
    id: '',
    species: '',
    nickname: '',
    status: '',
    level: 0,
    gender: 'f',
    met: '',
    metLevel: 0,
    nature: '',
    ability: '',
    moves: [],
    causeOfDeath: '',
    deathTimestamp: undefined,
    forme: 'Normal',
    item: '',
    types: [Types.Normal, Types.Normal],
    teraType: Types.Normal,
    customImage: '',
    customIcon: '',
    customItemImage: '',
    shiny: false,
    champion: false,
    badges: [],
    num: '',
    position: 0,
    wonderTradedFor: '',
    mvp: false,
    gameOfOrigin: 'Red',
    egg: false,
    hidden: false,
    extraData: {},
    pokeball: 'None',
    notes: '',
    checkpoints: [],
    gift: false,
    linkedTo: null,
    alpha: undefined,
};
