import { SEE_RELEASE } from 'actions';
export function sawRelease(state = {}, action) {
    switch (action.type) {
        case SEE_RELEASE:
            const release = action === null || action === void 0 ? void 0 : action.release;
            return release ? Object.assign(Object.assign({}, state), { [release]: true }) : state;
        default:
            return state;
    }
}
