import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { matchSpeciesToTypes, getMoveType, formatBallText, typeToColor, getContrastColor, matchNatureToToxtricityForme, } from 'utils';
import { editPokemon } from 'actions';
import { ErrorBoundary } from 'components/Shared';
import { TagInput, Classes, TextArea } from '@blueprintjs/core';
import { cx } from 'emotion';
import { useDebounceCallback } from '@react-hook/debounce';
import { useMemo } from 'react';
const createEdit = ({ inputName, value, pokemon, edit }) => {
    if (inputName === 'species') {
        return Object.assign(Object.assign({}, edit), { types: matchSpeciesToTypes(edit['species']) });
    }
    else if (inputName === 'nature' && (pokemon === null || pokemon === void 0 ? void 0 : pokemon.species) === 'Toxtricity') {
        return Object.assign(Object.assign({}, edit), { forme: matchNatureToToxtricityForme(value) });
    }
    else if (inputName === 'forme') {
        return Object.assign(Object.assign({}, edit), { types: pokemon && matchSpeciesToTypes(pokemon === null || pokemon === void 0 ? void 0 : pokemon.species, value) });
    }
    return edit;
};
export const renderItems = (visibleItems, setSelectedItem, selectedItem) => (visibleItems.map((v, i) => {
    return (React.createElement("li", { key: i, onClick: (e) => setSelectedItem(v), style: v === selectedItem ? { color: 'lightblue' } : {} }, v));
}));
export function PokemonAutocompleteInput({ className, placeholder, inputName, edit, disabled, onChange, setEdit, items, }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [visibleItems, setVisibleItems] = React.useState(items);
    const [selectedItem, setSelectedItem] = React.useState();
    const handleKeyDown = () => { };
    const updateItems = () => { };
    const closeList = () => { };
    const openList = () => { };
    return React.createElement(React.Fragment, null,
        React.createElement("input", { autoComplete: "off", className: cx(className), onKeyDown: handleKeyDown, onFocus: openList, onChange: closeList, placeholder: placeholder, name: inputName, type: "text", value: edit[inputName], disabled: disabled, onInput: (e) => setEdit({ [inputName]: e.currentTarget.value }) }),
        isOpen ? (React.createElement("ul", { className: "autocomplete-items has-nice-scrollbars" }, renderItems(visibleItems, setSelectedItem, selectedItem))) : null);
}
export function PokemonTextInput({ inputName, type, value, placeholder, disabled, selectedId, edit, setEdit, onChange, }) {
    return (React.createElement("input", { onChange: onChange, onInput: (e) => setEdit({ [inputName]: e.currentTarget.value }), type: type, name: inputName, value: edit[inputName], placeholder: placeholder, disabled: disabled, className: disabled ? `${Classes.DISABLED} ${Classes.TEXT_MUTED}` : '' }));
}
export function PokemonTextAreaInput({ inputName, type, value, placeholder, disabled, onChange, setEdit, edit, }) {
    return (React.createElement(TextArea, { onChange: onChange, onInput: (e) => setEdit({ [inputName]: e.currentTarget.value }), name: inputName, value: edit[inputName], placeholder: placeholder, disabled: disabled, style: { width: '100%' }, className: disabled ? `${Classes.DISABLED} ${Classes.TEXT_MUTED} ${Classes.FILL}` : '' }));
}
export function PokemonNumberInput({ inputName, type, value, placeholder, disabled, onChange, setEdit, edit, }) {
    return (React.createElement("input", { onChange: onChange, onInput: (e) => setEdit({ [inputName]: e.currentTarget.value }), type: type, name: inputName, value: edit[inputName], placeholder: placeholder, disabled: disabled }));
}
export function PokemonSelectInput({ inputName, value, type, usesKeyValue, options, placeholder, onChange, edit, setEdit, }) {
    var _a, _b;
    return (React.createElement("div", { className: Classes.SELECT, style: inputName === 'status' ? { width: '120px' } : {} },
        inputName === 'pokeball' && value && value !== 'None' ? (React.createElement("img", { style: { position: 'absolute' }, alt: value, src: `icons/pokeball/${formatBallText(value)}.png` })) : null,
        React.createElement("select", { onChange: (e) => {
                onChange(e);
                setEdit({ [inputName]: e.currentTarget.value });
            }, value: value, style: inputName === 'pokeball' ? { paddingLeft: '2rem' } : {}, name: inputName }, !usesKeyValue
            ? options
                ?
                    (_a = options) === null || _a === void 0 ? void 0 : _a.map((item, index) => React.createElement("option", { key: index }, item))
                : null
            :
                (_b = options) === null || _b === void 0 ? void 0 : _b.map((item, index) => (React.createElement("option", { value: item.value, key: index }, item.key))))));
}
export function PokemonDoubleSelectInput({ inputName, value, type, usesKeyValue, options, placeholder, onChange, edit, setEdit, }) {
    var _a, _b;
    if (!Array.isArray(edit[inputName])) {
        throw new Error('Could not read input as Array');
    }
    const onSelect = React.useMemo(() => (position) => (e) => {
        onChange(e);
        const newEdit = [
            ...edit[inputName]
        ];
        newEdit[position] = e.currentTarget.value;
        setEdit({ [inputName]: newEdit });
    }, [inputName, edit]);
    return (React.createElement("span", { className: "double-select-wrapper" },
        React.createElement("div", { className: Classes.SELECT },
            React.createElement("select", { onChange: onSelect(0), value: (_a = edit === null || edit === void 0 ? void 0 : edit[inputName]) === null || _a === void 0 ? void 0 : _a[0], name: inputName }, options
                ?
                    options.map((item, index) => (React.createElement("option", { value: item, key: index }, item)))
                : null)),
        React.createElement("span", null, "\u00A0"),
        React.createElement("div", { className: Classes.SELECT },
            React.createElement("select", { onChange: onSelect(1), value: (_b = edit === null || edit === void 0 ? void 0 : edit[inputName]) === null || _b === void 0 ? void 0 : _b[1], name: inputName }, options
                ?
                    options.map((item, index) => (React.createElement("option", { value: item, key: index }, item)))
                : null))));
}
;
export function PokemonCheckboxInput({ inputName, value, type, usesKeyValue, options, placeholder, onChange, edit, setEdit, }) {
    return (React.createElement("label", { className: cx(Classes.CONTROL, Classes.CHECKBOX) },
        React.createElement("input", { onChange: (e) => {
                onChange(e);
                setEdit({ [inputName]: e.currentTarget.checked });
            }, checked: edit[inputName], type: type, name: inputName }),
        React.createElement("span", { className: Classes.CONTROL_INDICATOR })));
}
export function PokemonMoveInput({ inputName, value, type, usesKeyValue, options, placeholder, onChange, edit, setEdit, customTypes, customMoveMap, selectedId, }) {
    const dispatch = useDispatch();
    const moves = useMemo(() => (v) => { var _a; return (_a = customMoveMap === null || customMoveMap === void 0 ? void 0 : customMoveMap.find((m) => (m === null || m === void 0 ? void 0 : m.move) === v)) === null || _a === void 0 ? void 0 : _a.type; }, [customMoveMap]);
    return React.createElement(ErrorBoundary, null,
        React.createElement(TagInput, { fill: true, leftIcon: "ninja", tagProps: (v, i) => {
                var _a;
                const background = typeToColor(moves(v) || getMoveType(((_a = v === null || v === void 0 ? void 0 : v.toString()) === null || _a === void 0 ? void 0 : _a.trim()) || ''), customTypes) || 'transparent';
                const color = getContrastColor(background);
                return {
                    style: {
                        background,
                        color,
                    },
                };
            }, onChange: (values) => {
                const edit = {
                    moves: values,
                };
                selectedId && dispatch(editPokemon(edit, selectedId));
            }, values: value || [] }));
}
export function CurrentPokemonInput(props) {
    const { inputName, value, className } = props;
    const selectedId = useSelector((state) => state.selectedId);
    const customMoveMap = useSelector((state) => state.customMoveMap);
    const customTypes = useSelector((state) => state.customTypes);
    const dispatch = useDispatch();
    const [edit, setEdit] = React.useState({ [inputName]: value });
    if (!selectedId) {
        return null;
    }
    const onChange = useDebounceCallback(() => dispatch(editPokemon(createEdit({ inputName, value: edit[inputName], edit, pokemon: props.pokemon }), selectedId)), 300);
    React.useEffect(() => setEdit({ [inputName]: value }), [inputName, value]);
    return (React.createElement("span", { className: `current-pokemon-input-wrapper current-pokemon-${props.type} ${props.type === 'autocomplete' && 'autocomplete'} current-pokemon-${props.inputName} ${className}` },
        React.createElement("label", null, props.labelName),
        getInput(Object.assign(Object.assign({}, props), { selectedId, onChange, setEdit, edit, customMoveMap }))));
}
export function getInput(props) {
    switch (props.type) {
        case 'text':
            return React.createElement(PokemonTextInput, Object.assign({}, props));
        case 'textArea':
            return React.createElement(PokemonTextAreaInput, Object.assign({}, props));
        case 'select':
            return React.createElement(PokemonSelectInput, Object.assign({}, props));
        case 'checkbox':
            return React.createElement(PokemonCheckboxInput, Object.assign({}, props));
        case 'moves':
            return React.createElement(PokemonMoveInput, Object.assign({}, props));
        case 'number':
            return React.createElement(PokemonNumberInput, Object.assign({}, props));
        case 'double-select':
            return React.createElement(PokemonDoubleSelectInput, Object.assign({}, props));
        case 'autocomplete':
            return React.createElement(PokemonAutocompleteInput, Object.assign({}, props));
        default:
            return 'No input for this type exists.';
    }
}
