import * as React from 'react';
import { Tooltip, Position } from '@blueprintjs/core';
import { PokemonIcon } from 'components/PokemonIcon';
import { sortPokes } from 'utils';
import { connect } from 'react-redux';
import { editPokemon } from 'actions';
const matchesStatus = (searchTerm) => ((poke) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return ((_a = poke.nickname) === null || _a === void 0 ? void 0 : _a.toLowerCase().startsWith(searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.toLowerCase())) ||
        ((_b = poke.species) === null || _b === void 0 ? void 0 : _b.toLowerCase().startsWith(searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.toLowerCase())) ||
        ((_c = poke.forme) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === (searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.toLowerCase()) ||
        ((_d = poke.nickname) === null || _d === void 0 ? void 0 : _d.toLowerCase().startsWith(searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.toLowerCase())) ||
        ((_e = poke.gender) === null || _e === void 0 ? void 0 : _e.toLowerCase()) === (searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.toLowerCase()) ||
        ((_f = poke.moves) === null || _f === void 0 ? void 0 : _f.includes(searchTerm)) ||
        ((_g = poke.gameOfOrigin) === null || _g === void 0 ? void 0 : _g.toLowerCase()) === (searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.toLowerCase()) ||
        ((_h = poke.item) === null || _h === void 0 ? void 0 : _h.toLowerCase()) === (searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.toLowerCase()) ||
        ((_j = poke.types) === null || _j === void 0 ? void 0 : _j.includes(searchTerm));
});
export class PokemonByFilterBase extends React.PureComponent {
    render() {
        const { team, status, searchTerm } = this.props;
        return team
            .sort(sortPokes)
            .filter(poke => poke.status === status)
            .map((poke) => (React.createElement(Tooltip, { key: poke.id, content: poke.nickname || poke.species, position: Position.TOP },
            React.createElement(PokemonIcon, { style: {
                    backgroundColor: searchTerm !== '' && matchesStatus(searchTerm)(poke) ? '#90EE90' : undefined,
                    borderRadius: '50%'
                }, id: poke.id, status: poke.status, species: poke.species, forme: poke.forme, shiny: poke.shiny, gender: poke.gender, customIcon: poke.customIcon, hidden: poke.hidden, position: poke.position, egg: poke.egg }))));
    }
}
export const PokemonByFilter = connect(null, {
    editPokemon,
})(PokemonByFilterBase);
