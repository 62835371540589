var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { connect } from 'react-redux';
const uuid = require('uuid');
import { cx } from 'emotion';
import { selectPokemon, toggleMobileResultView } from 'actions';
import { TeamPokemon } from 'components/TeamPokemon';
import { DeadPokemon } from 'components/DeadPokemon';
import { BoxedPokemon } from 'components/BoxedPokemon';
import { ChampsPokemon } from 'components/ChampsPokemon';
import { TrainerResult } from 'components/Result';
import { TopBar } from 'components/TopBar';
import { ErrorBoundary } from 'components/Shared';
import { Stats } from './Stats';
import { getGameRegion, sortPokes, getContrastColor, feature, getIconFormeSuffix } from 'utils';
import * as Styles from './styles';
import './Result.css';
import './themes.css';
import isMobile from 'is-mobile';
import { Button, Classes } from '@blueprintjs/core';
import { clamp } from 'ramda';
import { resultSelector } from 'selectors';
import { PokemonImage } from 'components/Shared/PokemonImage';
import { normalizeSpeciesName } from 'utils/getters/normalizeSpeciesName';
function load() {
    return __awaiter(this, void 0, void 0, function* () {
        const resource = yield import('@emmaramirez/dom-to-image');
        return resource.domToImage;
    });
}
const getNumberOf = (status, pokemon) => status
    ? pokemon === null || pokemon === void 0 ? void 0 : pokemon.filter((v) => v.hasOwnProperty('id')).filter((poke) => poke.status === status && !poke.hidden).length
    : 0;
export function BackspriteMontage({ pokemon }) {
    return (React.createElement("div", { className: "backsprite-montage", style: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'bottom',
            margin: '0 auto',
            height: '92px',
        } }, pokemon.map((poke, idx) => {
        const image = `https://img.pokemondb.net/sprites/platinum/back-normal/${(normalizeSpeciesName(poke.species) || '').toLowerCase()}${getIconFormeSuffix(poke.forme)}.png`;
        return (React.createElement(PokemonImage, { key: poke.id, url: image }, (backgroundImage) => React.createElement("img", { className: "backsprite-montage-sprite", "data-sprite-id": idx, "data-sprite-species": poke.species, style: {
                height: '128px',
                marginLeft: '-32px',
                zIndex: 6 - idx,
                imageRendering: 'pixelated',
            }, alt: "", role: "presentation", src: backgroundImage })));
    })));
}
export class ResultBase extends React.PureComponent {
    constructor(props) {
        super(props);
        this.getBoxClass = (s) => {
            if (s === 'Dead')
                return 'dead';
            if (s === 'Boxed')
                return 'boxed';
            if (s === 'Champs')
                return 'champs';
            if (s === 'Team')
                return 'team';
            return 'boxed';
        };
        this.getBoxStyle = (s) => {
            if (s === 'Champs')
                return {
                    margin: this.props.style.template === 'Compact' ? 0 : '.5rem',
                    display: 'flex',
                    flexWrap: 'wrap',
                };
            if (s === 'Dead')
                return {
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    margin: this.props.style.template === 'Compact' ? 0 : '.5rem',
                };
            return {};
        };
        this.getH3 = (box, n) => {
            if (box.name === 'Dead' || box.name === 'Champs') {
                if (n) {
                    return ` (${n})`;
                }
            }
            return null;
        };
        this.renderContainer = (pokemon, paddingForVerticalTrainerSection, box) => {
            var _a;
            return box && pokemon && getNumberOf(box === null || box === void 0 ? void 0 : box.name, pokemon) > 0 ? (React.createElement("div", { key: box.id, style: paddingForVerticalTrainerSection, className: `${this.getBoxClass((box === null || box === void 0 ? void 0 : box.inheritFrom) || box.name)}-container` },
                (box === null || box === void 0 ? void 0 : box.name) !== 'Team' && (React.createElement("h3", { style: { color: getContrastColor(this.props.style.bgColor || '#383840') } }, box === null || box === void 0 ? void 0 :
                    box.name,
                    this.getH3(box, (_a = getNumberOf(box === null || box === void 0 ? void 0 : box.name, pokemon)) !== null && _a !== void 0 ? _a : 0))),
                React.createElement("div", { className: "boxed-container-inner", style: this.getBoxStyle((box === null || box === void 0 ? void 0 : box.name) || (box === null || box === void 0 ? void 0 : box.inheritFrom)) }, pokemon.map((poke, index) => {
                    if ((box === null || box === void 0 ? void 0 : box.name) === 'Boxed' || (box === null || box === void 0 ? void 0 : box.inheritFrom) === 'Boxed')
                        return React.createElement(BoxedPokemon, Object.assign({ key: index }, poke));
                    if ((box === null || box === void 0 ? void 0 : box.name) === 'Dead' || (box === null || box === void 0 ? void 0 : box.inheritFrom) === 'Dead')
                        return React.createElement(DeadPokemon, Object.assign({ minimal: false, key: index }, poke));
                    if ((box === null || box === void 0 ? void 0 : box.name) === 'Champs' || (box === null || box === void 0 ? void 0 : box.inheritFrom) === 'Champs')
                        return (React.createElement(ChampsPokemon, Object.assign({ useSprites: this.props.style.useSpritesForChampsPokemon, showGender: !this.props.style.minimalChampsLayout, showLevel: !this.props.style.minimalChampsLayout, showNickname: !this.props.style.minimalChampsLayout, key: index }, poke)));
                    if ((box === null || box === void 0 ? void 0 : box.name) === 'Team' || (box === null || box === void 0 ? void 0 : box.inheritFrom) === 'Team')
                        return React.createElement(TeamPokemon, { key: index, pokemon: poke });
                    return null;
                })))) : null;
        };
        this.onPan = (e) => {
            e === null || e === void 0 ? void 0 : e.preventDefault();
            e === null || e === void 0 ? void 0 : e.persist();
            if ((e === null || e === void 0 ? void 0 : e.buttons) === 1) {
                this.setState(state => { var _a, _b, _c, _d; return ({ panningCoordinates: [((_b = (_a = state.panningCoordinates) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : 0) + (e === null || e === void 0 ? void 0 : e.movementX), ((_d = (_c = state.panningCoordinates) === null || _c === void 0 ? void 0 : _c[1]) !== null && _d !== void 0 ? _d : 0) + (e === null || e === void 0 ? void 0 : e.movementY)] }); });
            }
        };
        this.onZoom = (e) => {
            var _a;
            if (e.shiftKey) {
                this.setState({ zoomLevel: clamp(0.1, 5, (((_a = -(e === null || e === void 0 ? void 0 : e.deltaY)) !== null && _a !== void 0 ? _a : 3) / 3)) });
            }
        };
        this.resetPan = (e) => {
            this.setState({ panningCoordinates: [0, 0], zoomLevel: 1 });
        };
        this.resultRef = React.createRef();
        this.state = {
            isDownloading: false,
            downloadError: null,
            panningCoordinates: [undefined, undefined],
            zoomLevel: 1,
        };
    }
    renderTeamPokemon() {
        return this.props.pokemon
            .filter((v) => v.hasOwnProperty('id'))
            .filter((poke) => poke.status === 'Team')
            .filter((poke) => !poke.hidden)
            .sort(sortPokes)
            .map((poke, index) => {
            return React.createElement(TeamPokemon, { key: index, pokemon: poke });
        });
    }
    renderTopBarItems() {
        const renderItems = [];
        renderItems.push(React.createElement("div", { key: 1, className: cx(this.props.style.editorDarkMode && Classes.DARK, Classes.SELECT) },
            React.createElement("select", { className: cx(this.props.style.editorDarkMode && Classes.DARK), defaultValue: 1, onChange: (e) => { var _a, _b; return this.setState({ zoomLevel: Number.parseFloat((_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '1') }); } }, [
                { key: 0.25, value: '25%' },
                { key: 0.5, value: '50%' },
                { key: 0.75, value: '75%' },
                { key: 1, value: '100%' },
                { key: 1.25, value: '125%' },
                { key: 1.5, value: '150%' },
                { key: 2, value: '200%' },
                { key: 3, value: '300%' },
            ].map(opt => React.createElement("option", { className: cx(this.props.style.editorDarkMode && Classes.DARK), key: opt.key, value: opt.key },
                "Zoom: ",
                opt.value)))));
        return React.createElement(React.Fragment, null, renderItems);
    }
    getPokemonByStatus(status) {
        return this.props.pokemon
            .filter((v) => v.hasOwnProperty('id'))
            .filter((poke) => poke.status === status)
            .filter((poke) => !poke.hidden);
    }
    getCorrectStatusWrapper(pokes, box, paddingForVerticalTrainerSection) {
        return this.renderContainer(pokes, paddingForVerticalTrainerSection, box);
    }
    renderOtherPokemonStatuses(paddingForVerticalTrainerSection) {
        return this.props.box
            .filter((box) => !['Team'].includes(box.name))
            .sort((a, b) => {
            const posA = a.position || 0;
            const posB = b.position || 1;
            return posA - posB;
        })
            .map((box) => {
            const pokes = this.props.pokemon
                .filter((v) => v.hasOwnProperty('id'))
                .filter((poke) => poke.status === box.name)
                .filter((poke) => !poke.hidden);
            return this.getCorrectStatusWrapper(pokes, box, paddingForVerticalTrainerSection);
        });
    }
    toImage() {
        return __awaiter(this, void 0, void 0, function* () {
            const resultNode = this.resultRef.current;
            this.setState({ isDownloading: true });
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            try {
                const domToImage = yield load();
                const dataUrl = yield domToImage.toPng(resultNode, { corsImage: true });
                console.log(dataUrl, resultNode);
                const link = document.createElement('a');
                link.download = `nuzlocke-${uuid()}.png`;
                link.href = dataUrl;
                link.click();
                this.setState({ downloadError: null, isDownloading: false });
            }
            catch (e) {
                console.log(e);
                this.setState({
                    downloadError: 'Failed to download. This is likely due to your image containing an image resource that does not allow Cross-Origin',
                    isDownloading: false,
                });
            }
        });
    }
    getScale(style, editor, coords) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const rw = parseInt(style.resultWidth.toString());
        const ww = window.innerWidth;
        const scale = ww / rw / 1.1;
        const height = ((_c = (_b = (_a = this.resultRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.offsetHeight) !== null && _c !== void 0 ? _c : 0) / this.state.zoomLevel;
        const width = ((_f = (_e = (_d = this.resultRef) === null || _d === void 0 ? void 0 : _d.current) === null || _e === void 0 ? void 0 : _e.offsetWidth) !== null && _f !== void 0 ? _f : 300) / this.state.zoomLevel;
        const translate = `translateX(${clamp(-(width), width, ((_g = coords === null || coords === void 0 ? void 0 : coords[0]) !== null && _g !== void 0 ? _g : 0) / 1)}px) translateY(${clamp(-(height), Infinity, ((_h = coords === null || coords === void 0 ? void 0 : coords[1]) !== null && _h !== void 0 ? _h : 0) / 1)}px)`;
        if (this.state.isDownloading) {
            return { transform: undefined };
        }
        if (!editor.showResultInMobile) {
            return { transform: `scale(${this.state.zoomLevel}) ${translate}` };
        }
        if (!Number.isNaN(rw)) {
            return { transform: `scale(${scale.toFixed(2)})` };
        }
        else {
            return { transform: 'scale(0.3)' };
        }
    }
    render() {
        const { style, box, trainer, pokemon, editor } = this.props;
        const numberOfTeam = getNumberOf('Team', pokemon);
        const bgColor = style ? style.bgColor : '#383840';
        const topHeaderColor = style ? style.topHeaderColor : '#333333';
        const accentColor = style ? style.accentColor : '#111111';
        const trainerSectionOrientation = this.props.style.trainerSectionOrientation;
        const paddingForVerticalTrainerSection = trainerSectionOrientation === 'vertical'
            ? {
                paddingLeft: style.trainerWidth,
            }
            : {};
        const teamContainer = (React.createElement("div", { style: paddingForVerticalTrainerSection, className: "team-container" }, this.renderTeamPokemon()));
        const rulesContainer = (React.createElement("div", { className: "rules-container" },
            React.createElement("h3", { style: { color: getContrastColor(bgColor) } }, "Rules"),
            React.createElement("ol", { style: { color: getContrastColor(bgColor) } }, this.props.rules.map((rule, index) => {
                return React.createElement("li", { key: index }, rule);
            }))));
        const others = pokemon.filter((poke) => !['Team', 'Boxed', 'Dead', 'Champs'].includes(poke.status));
        const enableStats = style.displayStats;
        const enableChampImage = feature.emmaMode;
        const enableBackSpriteMontage = feature.emmaMode;
        const EMMA_MODE = feature.emmaMode;
        return (React.createElement("div", { onWheel: this.onZoom, onMouseMove: this.onPan, onDoubleClick: this.resetPan, className: "hide-scrollbars", style: { width: '100%', overflowY: 'scroll' } },
            isMobile() && editor.showResultInMobile && (React.createElement("div", { className: Classes.OVERLAY_BACKDROP })),
            React.createElement(ErrorBoundary, null,
                React.createElement(TopBar, { isDownloading: this.state.isDownloading, onClickDownload: () => this.toImage() }, this.renderTopBarItems()),
                React.createElement("style", null, `
                            .result {
                                --background-color: ${bgColor};
                                --accent-color: ${accentColor};
                                --header-color: ${topHeaderColor};
                            }
                        `),
                React.createElement("style", null, style.customCSS),
                isMobile() && editor.showResultInMobile && (React.createElement(Button, { className: Styles.result_download, icon: "download", onClick: () => {
                        this.props.toggleMobileResultView();
                        this.toImage();
                    } }, "Download")),
                React.createElement("div", { ref: this.resultRef, className: `result ng-container ${(style.template && style.template.toLowerCase().replace(/\s/g, '-')) ||
                        ''} region-${getGameRegion(this.props.game.name)} team-size-${numberOfTeam} ${trainerSectionOrientation}-trainer
                       ${editor.showResultInMobile ? Styles.result_mobile : ''}
                        `, style: Object.assign({ fontFamily: style.usePokemonGBAFont ? 'pokemon_font' : 'inherit', fontSize: style.usePokemonGBAFont ? '125%' : '100%', margin: this.state.isDownloading ? '0' : '3rem auto', backgroundColor: bgColor, backgroundImage: `url(${style.backgroundImage})`, backgroundRepeat: style.tileBackground ? 'repeat' : 'no-repeat', border: 'none', height: style.useAutoHeight ? 'auto' : `${style.resultHeight}px`, minHeight: style.useAutoHeight ? '600px' : undefined, transition: 'transform 300ms ease-in-out', transformOrigin: '0 0', width: `${style.resultWidth}px`, zIndex: 1 }, this.getScale(style, editor, this.state.panningCoordinates)) },
                    React.createElement("div", { className: "trainer-container", style: trainerSectionOrientation === 'vertical'
                            ? {
                                backgroundColor: topHeaderColor,
                                color: getContrastColor(topHeaderColor),
                                width: style.trainerWidth,
                                position: 'absolute',
                                height: `calc(${style.trainerHeight} + 2%)`,
                                display: 'flex',
                            }
                            : {
                                backgroundColor: topHeaderColor,
                                color: getContrastColor(topHeaderColor),
                                width: style.trainerAuto ? '100%' : style.trainerWidth,
                                height: style.trainerAuto ? 'auto' : style.trainerHeight,
                            } },
                        React.createElement(TrainerResult, { orientation: trainerSectionOrientation })),
                    trainer && trainer.notes ? (React.createElement("div", { style: { color: getContrastColor(bgColor) }, className: "result-notes" }, trainer.notes)) : null,
                    style.displayRules && style.displayRulesLocation === 'top'
                        ? rulesContainer
                        : null,
                    teamContainer,
                    style.template === 'Generations' &&
                        trainerSectionOrientation === 'vertical' ? (React.createElement("div", { className: "statuses-wrapper" }, this.renderOtherPokemonStatuses(paddingForVerticalTrainerSection))) : (React.createElement(React.Fragment, null, this.renderOtherPokemonStatuses(paddingForVerticalTrainerSection))),
                    React.createElement("div", { style: Object.assign(Object.assign({}, paddingForVerticalTrainerSection), { display: 'flex', color: getContrastColor(bgColor) }) }, style.displayRules && style.displayRulesLocation === 'bottom'
                        ? rulesContainer
                        : null),
                    enableStats && !EMMA_MODE && React.createElement(Stats, { color: getContrastColor(bgColor) }),
                    enableBackSpriteMontage && (React.createElement(BackspriteMontage, { pokemon: this.getPokemonByStatus('Team') }))))));
    }
}
export const Result = connect(resultSelector, {
    selectPokemon,
    toggleMobileResultView,
})(ResultBase);
