import { ADD_STAT, EDIT_STAT, DELETE_STAT, REPLACE_STATE } from 'actions';
const uuid = require('uuid');
export function stats(initState = [{ id: 'a-1', key: '', value: '' }], action) {
    switch (action.type) {
        case ADD_STAT:
            return [
                ...initState,
                Object.assign({ id: uuid() }, action.stat),
            ];
        case DELETE_STAT:
            return initState.filter((s) => s.id !== action.id);
        case EDIT_STAT:
            return [
                ...initState.filter((s) => s.id !== action.id),
                Object.assign(Object.assign({}, initState.find((s) => s.id === action.id)), { key: action.key, value: action.value }),
            ];
        case REPLACE_STATE:
            return action.replaceWith.stats || [];
        default:
            return initState;
    }
}
