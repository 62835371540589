import * as React from 'react';
import { Icon } from '@blueprintjs/core';
const uuid = require('uuid');
export const determineNumberOfHearts = (friendship) => {
    if (friendship < 99 && friendship >= 50)
        return 1;
    if (friendship < 149 && friendship >= 100)
        return 2;
    if (friendship < 199 && friendship >= 150)
        return 3;
    if (friendship < 200 && friendship >= 249)
        return 4;
    if (friendship >= 250)
        return 5;
    else
        return 0;
};
export const generateHearts = (friendship) => {
    return Array.from(Array(friendship).keys()).map(k => React.createElement(Icon, { iconSize: 12, "data-testid": 'friendship-icon', key: uuid(), icon: 'heart' }));
};
export function PokemonFriendship({ friendship }) {
    React.useEffect(() => console.log(friendship), [friendship]);
    if (!friendship)
        return null;
    const numberOfHearts = determineNumberOfHearts(friendship);
    if (numberOfHearts === 0)
        return React.createElement(Icon, { iconSize: 12, "data-testid": 'friendship-broken-icon', icon: 'heart-broken' });
    return React.createElement(React.Fragment, null, generateHearts(numberOfHearts));
}
