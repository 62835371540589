var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Dialog, Callout, TextArea, Intent, Toaster, Switch, Classes, Checkbox, Icon, Popover, PopoverInteractionKind, } from '@blueprintjs/core';
import { PokemonIconBase } from 'components/PokemonIcon';
import { ErrorBoundary } from 'components/Shared';
const uuid = require('uuid');
import { persistor } from 'store';
import { newNuzlocke, replaceState, setEditorHistoryDisabled } from 'actions';
import { omit } from 'ramda';
import { BaseEditor } from 'components/BaseEditor';
import { noop } from 'redux-saga/utils';
import { feature } from 'utils';
import { DeleteAlert } from './DeleteAlert';
const isEmpty = require('lodash/isEmpty');
import codegen from 'codegen.macro';
import { cx } from 'emotion';
const getGameNumberOfBoxes = (game) => {
    switch (game) {
        case 'RBY':
            return 12;
        case 'GS':
        case 'Crystal':
            return 14;
        default:
            return 12;
    }
};
const isValidJSON = (data) => {
    try {
        JSON.parse(data);
        return true;
    }
    catch (e) {
        return false;
    }
};
const handleExceptions = (data) => {
    let updated = {};
    if (typeof data.pokemon === 'string') {
        const toaster = Toaster.create();
        toaster.show({
            message: 'Issue with data detected. Attempting to fix...',
            intent: Intent.DANGER,
        });
        for (const prop in data) {
            try {
                updated = Object.assign(Object.assign({}, updated), { [prop]: JSON.parse(data[prop]) });
            }
            catch (e) {
                console.log(`Failed to parse on ${prop}`);
            }
        }
    }
    return (isEmpty(updated)) ? data : updated;
};
const generateArray = (n) => {
    const arr = [];
    for (let i = 1; i < n + 1; i++) {
        if (i === 2) {
            arr.push({ key: i, status: 'Dead' });
        }
        else {
            arr.push({ key: i, status: 'Boxed' });
        }
    }
    return arr;
};
const generateBoxMappingsDefault = (saveFormat) => generateArray(getGameNumberOfBoxes(saveFormat));
export function BoxSelect({ boxes, value, boxKey, setBoxMappings }) {
    return React.createElement("div", { className: Classes.SELECT },
        React.createElement("select", { value: value, onChange: e => setBoxMappings({ key: boxKey, status: e.target.value }) }, boxes.map((box) => (React.createElement("option", { key: box.id, value: box.name }, box.name)))));
}
export function SaveGameSettingsDialog({ onMergeDataChange, mergeDataMode, boxes, selectedGame, boxMappings, setBoxMappings, }) {
    return React.createElement("div", { className: cx(Classes.DIALOG_BODY, 'has-nice-scrollbars') },
        React.createElement(Switch, { labelElement: React.createElement(React.Fragment, null,
                React.createElement("strong", null, "Merge Data?"),
                React.createElement("p", { className: Classes.TEXT_MUTED }, "Merges your current data with that of the save file, using an ID match algorithm. Disabling this will overwrite your current data with that from the save file. NOTE: this method of determining IDs is based off IVs in Gen I & II.")), checked: mergeDataMode, onChange: onMergeDataChange }),
        React.createElement("div", { style: { height: '60vh', overflow: 'auto', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }, className: 'has-nice-scrollbars' }, boxMappings.map((value, index) => {
            return React.createElement("div", { style: { padding: '0.25rem' } },
                React.createElement(BoxSelect, { boxKey: value.key, setBoxMappings: setBoxMappings, value: value.status, boxes: boxes }),
                React.createElement("div", { className: Classes.BUTTON, style: { marginLeft: '0.25rem',
                        cursor: 'default', width: '8rem' } }, `Box ${value.key}`));
        })));
}
export class DataEditorBase extends React.Component {
    constructor(props) {
        super(props);
        this.uploadJSON = (e) => {
            if (isValidJSON(e.target.value)) {
                this.setState({ data: e.target.value });
            }
            else {
                const toaster = Toaster.create();
                toaster.show({
                    message: 'Failed to parse invalid JSON',
                    intent: Intent.DANGER,
                });
            }
        };
        this.uploadNuzlockeJsonFile = (e) => {
            const file = this.nuzlockeJsonFileInput.files[0];
            const reader = new FileReader();
            reader.readAsText(file, 'utf-8');
            reader.addEventListener('load', (event) => {
                var _a;
                const file = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.result;
                const data = file;
                this.setState({ data });
            });
        };
        this.confirmImport = (e) => {
            let cmm = { customMoveMap: [] };
            const override = this.state.overrideImport;
            const data = handleExceptions(JSON.parse(this.state.data));
            const nuz = this.props.state;
            const safeguards = { customTypes: [], customMoveMap: [], stats: [], excludedAreas: [], customAreas: [] };
            if (!Array.isArray(data.customMoveMap)) {
                noop();
            }
            else {
                cmm = { customMoveMap: data.customMoveMap };
            }
            this.props.replaceState(Object.assign(Object.assign(Object.assign({}, safeguards), (override ? data : nuz)), cmm));
            this.props.newNuzlocke(this.state.data, { isCopy: false });
            this.writeAllData();
            this.setState({ isOpen: false });
        };
        this.importState = () => {
            this.setState({ mode: 'import' });
            this.setState({ isOpen: true });
        };
        this.exportState = (state) => {
            this.setState({
                mode: 'export',
            });
            this.setState({ isOpen: true });
            this.setState({
                href: `data:text/plain;charset=utf-8,${encodeURIComponent(JSON.stringify(omit(['router', '._persist', 'editorHistory'], state)))}`,
            });
        };
        this.uploadFile = (replaceState, state) => (e) => {
            const t0 = performance.now();
            const worker = new Worker(new URL('parsers/worker.ts', codegen `module.exports = process.env.NODE_ENV === "test" ? "" : "import.meta.url"`));
            const file = this.fileInput.files[0];
            const reader = new FileReader();
            const componentState = this.state;
            reader.readAsArrayBuffer(file);
            reader.addEventListener('load', function () {
                return __awaiter(this, void 0, void 0, function* () {
                    const save = new Uint8Array(this.result);
                    worker.postMessage({
                        selectedGame: componentState.selectedGame,
                        save,
                        boxMappings: componentState.boxMappings,
                    });
                    worker.onmessage = (e) => {
                        const result = e.data;
                        const mergedPokemon = componentState.mergeDataMode
                            ? DataEditorBase.pokeMerge(state.pokemon, result.pokemon)
                            : result.pokemon;
                        const data = {
                            game: DataEditorBase.determineGame({
                                isYellow: result.isYellow,
                                selectedGame: componentState.selectedGame,
                            }),
                            pokemon: mergedPokemon,
                            trainer: result.trainer,
                        };
                        const newState = Object.assign(Object.assign({}, state), data);
                        replaceState(newState);
                    };
                    worker.onmessageerror = (err) => {
                        const toaster = Toaster.create();
                        toaster.show({
                            message: `Failed to parse save file. ${err}`,
                            intent: Intent.DANGER,
                        });
                        console.error(err);
                    };
                    const t1 = performance.now();
                    console.info(`Call: ${t1 - t0} ms on ${componentState.selectedGame} save file type`);
                });
            });
        };
        this.clearAllData = (e) => {
            persistor.purge();
            window.location.reload();
        };
        this.writeAllData = () => {
            persistor.flush();
        };
        this.toggleClearingData = (e) => this.setState({ isClearAllDataOpen: !this.state.isClearAllDataOpen });
        this.state = {
            isOpen: false,
            isClearAllDataOpen: false,
            mode: 'export',
            data: '',
            href: '',
            selectedGame: 'RBY',
            mergeDataMode: true,
            showSaveFileUI: false,
            overrideImport: true,
            isSettingsOpen: false,
            boxMappings: [],
        };
    }
    componentDidMount() {
        this.setState(state => ({
            boxMappings: generateBoxMappingsDefault(state.selectedGame)
        }));
    }
    renderTeam(data) {
        var _a, _b;
        let d;
        try {
            d = handleExceptions(JSON.parse(data));
        }
        catch (_c) {
            d = { pokemon: false };
        }
        if (d.pokemon) {
            return (React.createElement("div", { className: "team-icons", style: {
                    background: 'rgba(0, 0, 0, 0.1)',
                    borderRadius: '.25rem',
                    margin: '.25rem',
                    marginTop: '.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                } }, (_b = (_a = d === null || d === void 0 ? void 0 : d.pokemon) === null || _a === void 0 ? void 0 : _a.filter((p) => p.status === 'Team')) === null || _b === void 0 ? void 0 : _b.map((p) => {
                return React.createElement(PokemonIconBase, Object.assign({ key: p.id }, p));
            })));
        }
        else {
            return null;
        }
    }
    static determineGame({ isYellow, selectedGame, }) {
        if (isYellow)
            return { name: 'Yellow', customName: '' };
        if (selectedGame === 'GS')
            return { name: 'Gold', customName: '' };
        if (selectedGame === 'Crystal')
            return { name: 'Crystal', customName: '' };
        return { name: 'Red', customName: '' };
    }
    renderSaveFileUI() {
        const allowedGames = ['RBY'];
        if (feature.gen2saves) {
            allowedGames.push('GS');
            allowedGames.push('Crystal');
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: (e) => {
                    this.setState({ showSaveFileUI: !this.state.showSaveFileUI });
                }, style: {
                    transform: 'translateY(-5px)',
                } }, "Import From Save File"),
            React.createElement("div", { className: "data-editor-save-file-form", style: {
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    margin: '0.25rem',
                    display: this.state.showSaveFileUI ? 'flex' : 'none',
                    borderRadius: '0.25rem',
                    padding: '0.25rem',
                } },
                React.createElement("div", { className: cx(Classes.LABEL, Classes.INLINE), style: { padding: '.25rem 0', paddingBottom: '.5rem' } },
                    React.createElement("div", { className: Classes.SELECT },
                        React.createElement("select", { value: this.state.selectedGame, onChange: (e) => {
                                this.setState({
                                    selectedGame: e.target.value,
                                    boxMappings: generateBoxMappingsDefault(e.target.value)
                                });
                            } }, allowedGames.map((game) => (React.createElement("option", { key: game, value: game }, game)))))),
                React.createElement("div", { className: cx(Classes.LABEL, Classes.INLINE), style: {
                        padding: '.25rem 0',
                        paddingBottom: '.5rem',
                        marginLeft: '.25rem',
                    } },
                    React.createElement("input", { style: { padding: '.25rem' }, className: Classes.FILE_INPUT, ref: (ref) => (this.fileInput = ref), onChange: this.uploadFile(this.props.replaceState, this.props.state), type: "file", id: "file", name: "file", accept: ".sav" })),
                React.createElement(Button, { onClick: (e) => this.setState({ isSettingsOpen: true }), minimal: true, intent: Intent.PRIMARY }, "Options"),
                React.createElement(Dialog, { isOpen: this.state.isSettingsOpen, onClose: (e) => this.setState({ isSettingsOpen: false }), title: 'Save Upload Settings', className: this.props.state.style.editorDarkMode ? Classes.DARK : '', icon: "floppy-disk" },
                    React.createElement(SaveGameSettingsDialog, { mergeDataMode: this.state.mergeDataMode, onMergeDataChange: () => this.setState({ mergeDataMode: !this.state.mergeDataMode }), boxes: this.props.state.box, selectedGame: this.state.selectedGame, boxMappings: this.state.boxMappings, setBoxMappings: ({ key, status }) => {
                            console.log('setBoxMappings:', key, status);
                            this.setState(({ boxMappings }) => {
                                const newBoxMappings = boxMappings.map(({ key: boxKey, status: boxStatus }) => {
                                    if (key === boxKey) {
                                        return { key, status };
                                    }
                                    return { key: boxKey, status: boxStatus };
                                });
                                return {
                                    boxMappings: newBoxMappings,
                                };
                            });
                        } })))));
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return (React.createElement(BaseEditor, { icon: 'database', name: "Data" },
            React.createElement(DeleteAlert, { onConfirm: this.clearAllData, isOpen: this.state.isClearAllDataOpen, onCancel: this.toggleClearingData }),
            React.createElement(Dialog, { isOpen: this.state.isOpen, onClose: (e) => this.setState({ isOpen: false }), title: this.state.mode === 'export'
                    ? 'Exported Nuzlocke Save'
                    : 'Import Nuzlocke Save', className: this.props.state.style.editorDarkMode ? Classes.DARK : '', icon: "floppy-disk" }, this.state.mode === 'export' ? (React.createElement(React.Fragment, null,
                React.createElement(Callout, null, "Copy this and paste it somewhere safe!"),
                React.createElement("div", { style: { height: '40vh', overflow: 'auto' }, className: cx(Classes.DIALOG_BODY, 'has-nice-scrollbars') },
                    React.createElement("span", { suppressContentEditableWarning: true, contentEditable: true }, JSON.stringify(this.props.state, null, 2))),
                React.createElement("div", { className: Classes.DIALOG_FOOTER },
                    React.createElement("a", { href: this.state.href, download: `nuzlocke_${((_d = (_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.trainer) === null || _c === void 0 ? void 0 : _c.title) === null || _d === void 0 ? void 0 : _d.toLowerCase().replace(/\s/g, '-')) ||
                            ((_h = (_g = (_f = (_e = this.props) === null || _e === void 0 ? void 0 : _e.state) === null || _f === void 0 ? void 0 : _f.game) === null || _g === void 0 ? void 0 : _g.name) === null || _h === void 0 ? void 0 : _h.toLowerCase().replace(/\s/g, '-')) ||
                            ''}_${uuid().slice(0, 4)}.json` },
                        React.createElement(Button, { icon: 'download', intent: Intent.PRIMARY }, "Download"))))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: cx(Classes.DIALOG_BODY, 'has-nice-scrollbars') },
                    React.createElement(TextArea, { className: cx('custom-css-input', Classes.FILL), onChange: this.uploadJSON, placeholder: "Paste nuzlocke.json contents here, or use the file uploader", value: this.state.data, large: true }),
                    React.createElement(ErrorBoundary, null, this.renderTeam(this.state.data))),
                React.createElement("div", { className: Classes.DIALOG_FOOTER },
                    React.createElement("div", { style: {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        } },
                        React.createElement("input", { style: { padding: '.25rem' }, className: Classes.FILE_INPUT, ref: (ref) => (this.nuzlockeJsonFileInput = ref), onChange: this.uploadNuzlockeJsonFile, type: "file", id: "jsonFile", name: "jsonFile", accept: ".json" }),
                        React.createElement(Button, { icon: "tick", intent: this.state.data === '' ? Intent.NONE : Intent.SUCCESS, onClick: this.confirmImport, disabled: this.state.data === '' ? true : false, text: "Confirm", style: {
                                marginLeft: 'auto',
                            } })))))),
            React.createElement(ButtonGroup, { style: { margin: '.25rem' } },
                React.createElement(Button, { onClick: (e) => this.importState(), icon: "import", intent: Intent.PRIMARY }, "Import Data"),
                React.createElement(Button, { onClick: (e) => this.exportState(this.props.state), icon: "export" }, "Export Data")),
            this.renderSaveFileUI(),
            React.createElement(ButtonGroup, { style: { margin: '.25rem' } },
                React.createElement(Button, { minimal: true, intent: Intent.SUCCESS, onClick: this.writeAllData, icon: "floppy-disk" }, "Force Save"),
                React.createElement(Button, { icon: "trash", onClick: this.toggleClearingData, intent: Intent.DANGER, minimal: true }, "Clear All Data")),
            React.createElement(Checkbox, { checked: this.props.state.editor.editorHistoryDisabled, onChange: e => this.props.setEditorHistoryDisabled(e.currentTarget.checked), labelElement: React.createElement(React.Fragment, null,
                    "Disable Editor History ",
                    React.createElement(Popover, { content: React.createElement("div", { style: { width: '8rem', padding: '.25rem' } }, "Can be used to achieve better editor performance on larger saves"), interactionKind: PopoverInteractionKind.HOVER },
                        React.createElement(Icon, { icon: 'info-sign' }))) })));
    }
}
DataEditorBase.pokeMerge = (pokemonListA, pokemonListB) => {
    return pokemonListB.map((poke) => {
        const id = poke.id;
        const aListPoke = pokemonListA.find((p) => p.id === id);
        if (aListPoke) {
            return Object.assign(Object.assign({}, aListPoke), poke);
        }
        else {
            return poke;
        }
    });
};
export const DataEditor = connect((state) => ({ state: state }), {
    replaceState,
    newNuzlocke,
    setEditorHistoryDisabled,
})(DataEditorBase);
