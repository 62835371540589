var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { injectGlobal } from 'emotion';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import 'normalize.css/normalize.css';
import { isLocal } from 'utils';
import { ErrorBoundary } from 'components';
window.global = window;
window.Buffer = window.Buffer || require('buffer').Buffer;
window.path = window.path || require('path').path;
function getRollbar() {
    return __awaiter(this, void 0, void 0, function* () {
        const { default: Rollbar } = yield import('rollbar');
        const rollbarConfig = new Rollbar({
            accessToken: '357eab6297524e6facb1c48b0403d869',
            captureUncaught: true,
            payload: {
                environment: 'production',
            },
            autoInstrument: {
                network: false,
                log: false,
                dom: true,
                navigation: false,
                connectivity: true,
            },
            maxItems: 20,
            captureIp: false,
            enabled: isLocal() ? false : true,
        });
        Rollbar.init(rollbarConfig);
    });
}
getRollbar().then(res => res);
injectGlobal `
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
    }

    body {
        background: #fff;
        font-family: 'Arial';
    }

    .app {
        display: flex;
        height: 100vh;
        min-width: 100%;
        overflow-y: hidden;
    }

    .opacity-medium {
        opacity: 0.5;
    }

    .flex { display: flex; }
    .p-6 { padding: 6rem; }
    .center-text { text-align: center; }
    .font-bold { font-weight: bold; }
    .items-center { align-items: center; }
    .content-center { align-content: center; }
    .justify-between { justify-content: space-between; }
    .m-1 { margin: 0.25rem; }
    .mb-2 { margin-bottom: 0.5rem; }
    .cursor-pointer { cursor: pointer; }
    .inline-flex { display: inline-flex !important; }
    .full-width { width: 100%; }
`;
const mountNode = document.getElementById('app');
function createRender() {
    return __awaiter(this, void 0, void 0, function* () {
        const { render } = yield import('react-dom');
        const { Provider } = yield import('react-redux');
        const { DndProvider } = yield import('react-dnd');
        const { HTML5Backend } = yield import('react-dnd-html5-backend');
        const { PersistGate } = yield import('redux-persist/es/integration/react');
        const { store, persistor } = yield import('./store');
        const App = React.lazy(() => import('components/App').then((res) => ({ default: res.App })));
        render(React.createElement(Provider, { store: store }, process.env.NODE_ENV !== 'test' ? (React.createElement(PersistGate, { loading: React.createElement("div", null, "Loading..."), onBeforeLift: null, persistor: persistor },
            React.createElement(DndProvider, { backend: HTML5Backend },
                React.createElement(ErrorBoundary, null,
                    React.createElement(React.Suspense, { fallback: 'Loading App...' },
                        React.createElement(App, null)))))) : (React.createElement(DndProvider, { backend: HTML5Backend },
            React.createElement(ErrorBoundary, null,
                React.createElement(React.Suspense, { fallback: 'Loading App...' },
                    React.createElement(App, null)))))), mountNode);
    });
}
createRender().then(res => res);
