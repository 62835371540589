import { ADD_CUSTOM_CHECKPOINT, DELETE_CHECKPOINT, EDIT_CHECKPOINT, REORDER_CHECKPOINTS, RESET_CHECKPOINTS, REPLACE_STATE, } from 'actions';
import { getBadges } from 'utils';
export function checkpoints(state = getBadges('None'), action) {
    switch (action.type) {
        case RESET_CHECKPOINTS:
            return getBadges(action.game);
        case REORDER_CHECKPOINTS:
            return state;
        case ADD_CUSTOM_CHECKPOINT:
            return [...state, action.checkpoint];
        case REPLACE_STATE:
            return action.replaceWith.checkpoints;
        case EDIT_CHECKPOINT:
            const newState = state.slice();
            newState.splice(state.map((n) => n.name).indexOf(action.name), 1, Object.assign(Object.assign({}, state.find((c) => c.name === action.name)), action.edits));
            return newState;
        case DELETE_CHECKPOINT:
            return state.filter((c) => c.name !== action.name);
        default:
            return state;
    }
}
