import * as React from 'react';
import { getListOfTypes, typeToColor, getContrastColor } from 'utils';
import { ColorEdit, rgbaOrHex } from 'components/Shared';
import { Classes, Button, Icon } from '@blueprintjs/core';
export class TypesEditor extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            type: '',
            color: '#ffffff',
        };
    }
    render() {
        const { customTypes, createCustomType, deleteCustomType, editCustomType } = this.props;
        return (React.createElement("div", { className: "types-editor" },
            React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
                React.createElement("input", { onChange: (e) => this.setState({ type: e.target.value }), style: { margin: '4px' }, value: this.state.type, className: Classes.INPUT, type: "text", placeholder: "Type Name" }),
                React.createElement(ColorEdit, { onChange: (e) => {
                        (e === null || e === void 0 ? void 0 : e.target.value) && this.setState({ color: e.target.value });
                    }, value: this.state.color, name: "color", onColorChange: (color) => this.setState({ color: rgbaOrHex(color) }) }),
                React.createElement(Button, { style: { margin: '4px' }, onClick: () => createCustomType(this.state) }, "Add Type")),
            customTypes.map((ct) => (React.createElement("div", { style: { display: 'flex', alignItems: 'center' }, key: ct.id },
                React.createElement(TypeBlock, { color: ct.color, customTypes: customTypes, type: ct.type }),
                React.createElement(Icon, { style: { color: 'red' }, icon: "trash", onClick: (e) => deleteCustomType(ct.id) })))),
            getListOfTypes(customTypes).map((t, i) => (React.createElement(TypeBlock, { key: i, customTypes: customTypes, type: t })))));
    }
}
export function TypeBlock({ type, customTypes, color, }) {
    return (React.createElement("div", { style: {
            background: color || typeToColor(type, customTypes) || 'transparent',
            color: getContrastColor(color || typeToColor(type, customTypes)),
            padding: '0.25rem 0.5rem',
            margin: '0.25rem',
            borderRadius: '0.25rem',
            width: '10rem',
        } }, type));
}
