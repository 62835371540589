import * as React from 'react';
import * as Styles from 'components/StyleEditor/styles';
import { cx } from 'emotion';
import { connect } from 'react-redux';
import { classWithDarkTheme } from 'utils';
import { ChromePicker } from 'react-color';
import { Popover, PopoverInteractionKind, Classes } from '@blueprintjs/core';
export const rgbaOrHex = (o) => (o.rgb && o.rgb.a && o.rgb.a !== 1
    ? `rgba(${o.rgb.r}, ${o.rgb.g}, ${o.rgb.b}, ${o.rgb.a})`
    : o.hex) || o;
export class ColorEditBase extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            showChromePicker: false,
        };
    }
    render() {
        const { value, onChange, name, style, width, onColorChange } = this.props;
        return (React.createElement("div", { className: cx(Styles.colorEditWrapper) },
            React.createElement(Popover, { interactionKind: PopoverInteractionKind.CLICK, content: React.createElement(ChromePicker, { color: value, onChangeComplete: (color) => {
                        onColorChange(color);
                    } }) },
                React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
                    React.createElement("input", { style: { border: 'none' }, onChange: onChange, type: "text", className: cx(Classes.INPUT, classWithDarkTheme(Styles, 'colorTextInput', style.editorDarkMode)), name: name, value: rgbaOrHex(value), onFocus: (e) => this.setState({ showChromePicker: true }), onBlur: (e) => this.setState({ showChromePicker: false }) }),
                    React.createElement("div", { style: {
                            height: '1rem',
                            width: '1rem',
                            marginLeft: '.5rem',
                            background: value,
                            borderRadius: '50%',
                        } })))));
    }
}
export const ColorEdit = connect((state) => ({ style: state.style }), null)(ColorEditBase);
