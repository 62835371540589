import { EDIT_GAME, REPLACE_STATE } from '../actions';
export function game(state = {
    name: 'None',
    customName: '',
}, action) {
    switch (action.type) {
        case EDIT_GAME:
            return Object.assign(Object.assign({}, state), action.edit);
        case REPLACE_STATE:
            return action.replaceWith.game;
        default:
            return state;
    }
}
