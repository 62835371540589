import * as React from 'react';
import { connect } from 'react-redux';
import { cx } from 'emotion';
import * as css from './styles';
import { generateEmptyPokemon, classWithDarkTheme } from 'utils';
import { Button, Tree, Classes, Menu, Icon, Switch, TextArea, Intent, } from '@blueprintjs/core';
import { BoxedPokemon } from '../BoxedPokemon';
import { ThemeSelect } from 'components/Shared';
import { ChampsPokemon, ErrorBoundary } from 'components';
import { DeadPokemon } from 'components/DeadPokemon';
import { CSSUnitInput } from './CSSUnitInput';
import { ChampsPokemonView } from 'components/ChampsPokemon/ChampsPokemonCollection';
import { LayoutDisplay, LayoutDirection, LayoutAlignment, LayoutSpacing } from 'components/Layout';
import { TeamPokemon } from 'components/TeamPokemon/TeamPokemon2';
const modelPokemon = Object.assign(Object.assign({}, generateEmptyPokemon()), { species: 'Pikachu', nickname: 'Pika Pika', gender: 'm', level: 50, metLevel: 5, met: 'Viridian Forest', gameOfOrigin: 'Red', causeOfDeath: 'Earthquake from Giovanni\'s Rhyhorn' });
const modelPokemonB = Object.assign(Object.assign({}, generateEmptyPokemon()), { species: 'Dragonite', nickname: 'Dragini', gender: 'f', level: 78, metLevel: 30, met: 'Safari Zone', gameOfOrigin: 'LeafGreen' });
const modelPokemonC = Object.assign(Object.assign({}, generateEmptyPokemon()), { species: 'Darmanitan', nickname: 'Frosty', gender: 'm', level: 100, met: 'Hatched on Route 8', metLevel: 1, gameOfOrigin: 'Sword', moves: ['Icicle Crash', 'Flare Blitz', 'U-turn', 'Earthquake'] });
const modelPokemonD = Object.assign(Object.assign({}, generateEmptyPokemon()), { species: 'Scizor', nickname: 'Stainless', level: 45, gender: 'm', gameOfOrigin: 'HeartGold' });
const modelPokemonE = Object.assign(Object.assign({}, generateEmptyPokemon()), { species: 'Jellicent', nickname: 'Pringles', level: 50, gender: 'm', gameOfOrigin: 'X' });
const modelPokemonF = Object.assign(Object.assign({}, generateEmptyPokemon()), { species: 'Rayquaza', shiny: true, level: 90, gameOfOrigin: 'Emerald', moves: ['Dragon Ascent', 'Fire Blast', 'Dragon Dance', 'Outrage'] });
const team = [
    modelPokemon,
    modelPokemonB,
    modelPokemonC,
    modelPokemonD,
    modelPokemonE,
    modelPokemonF,
];
const componentTree = [
    {
        id: 8,
        icon: 'style',
        isExpanded: true,
        label: 'Team Pokemon',
        component: TeamPokemon,
        options: {
            props: {
                options: {
                    height: '4rem',
                    width: '4rem',
                },
                customCSS: '',
                customHTML: undefined,
            },
            baseProps: {
                pokemon: modelPokemon,
            },
        },
        childNodes: [],
    },
    {
        id: 91,
        icon: 'style',
        isExpanded: true,
        label: 'Boxed Pokemon',
        component: BoxedPokemon,
        options: {
            props: {},
            baseProps: {
                pokemon: modelPokemon,
            },
        },
    },
    {
        id: 10,
        icon: 'style',
        isExpanded: true,
        label: 'Dead Pokemon',
        component: DeadPokemon,
        options: {
            props: {
                minimal: false,
            },
            baseProps: Object.assign({}, modelPokemon),
        },
        childNodes: [],
    },
    {
        id: 9,
        icon: 'style',
        isExpanded: true,
        label: 'Champs Pokemon Collection',
        component: ChampsPokemonView,
        options: {
            props: {
                display: LayoutDisplay.Block,
                direction: LayoutDirection.Row,
                alignment: LayoutAlignment.Start,
                spacing: LayoutSpacing.Start,
            },
            baseProps: {
                pokemon: team,
            },
        },
        childNodes: [
            {
                id: 12,
                isExpanded: true,
                label: 'Champs Pokemon',
                component: ChampsPokemon,
                options: {
                    props: {
                        showGender: false,
                        showLevel: false,
                        showNickname: false,
                        useSprites: true,
                        padding: '4px',
                        margin: '0px',
                        customCSS: '',
                    },
                    baseProps: Object.assign({}, modelPokemon),
                },
            },
        ],
    },
];
export const NumericValue = ({ name, value, onChange }) => (React.createElement("div", { className: cx(css.componentOption) },
    React.createElement("label", { style: { marginBottom: 0 }, className: Classes.LABEL }, name),
    React.createElement("input", { className: Classes.INPUT, name: name, onChange: onChange, type: "number", value: value })));
export const TextInput = ({ name, value, onChange }) => (React.createElement("div", { className: cx(css.componentOption) },
    React.createElement("label", { style: { marginBottom: 0 }, className: Classes.LABEL }, name),
    React.createElement("input", { className: Classes.INPUT, type: "text", value: value, name: name, onChange: onChange })));
export const WrapWithLabel = ({ name, children }) => (React.createElement("div", { className: cx(css.componentOption) },
    React.createElement("label", { style: { marginBottom: 0 }, className: Classes.LABEL }, name),
    children));
export class ThemeEditorBase extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { componentTree: [] };
        this.onNodeClick = (node, _nodePath, e) => {
            const originallySelected = node.isSelected;
            if (!e.shiftKey) {
                this.forEachNode(this.state.componentTree, (n) => (n.isSelected = false));
            }
            node.isSelected = originallySelected == null ? true : !originallySelected;
            this.setState(this.state);
        };
        this.onNodeCollapse = (node) => {
            node.isExpanded = false;
            this.setState(this.state);
        };
        this.onNodeExpand = (node) => {
            node.isExpanded = true;
            this.setState(this.state);
        };
        this.getComponent = (id, currentNode) => {
            console.log(currentNode);
            return (React.createElement(currentNode.component, Object.assign({}, currentNode.options.baseProps, currentNode.options.props)));
        };
    }
    UNSAFE_componentWillMount() {
        this.setState({ componentTree: componentTree });
    }
    getCurrentNode() {
        let currentNode;
        const selectedNodes = this.forEachNode(this.state.componentTree, (node) => {
            if (node.isSelected)
                currentNode = node;
        });
        return currentNode;
    }
    forEachNode(nodes, callback) {
        if (nodes == null) {
            return;
        }
        for (const node of nodes) {
            callback(node);
            this.forEachNode(node.childNodes, callback);
        }
    }
    renderOptions(props) {
        const currentNode = this.getCurrentNode() == null ? null : this.getCurrentNode();
        const modify = (key, value) => (props[key] = value);
        return Object.keys(props).map((propKey, idx) => {
            if (!currentNode.options) {
                return null;
            }
            if (typeof props[propKey] === 'object') {
                return this.renderOptions(props[propKey]);
            }
            const type = typeof props[propKey];
            const value = props[propKey];
            if (propKey === 'customCSS' || propKey === 'customHTML') {
                return (React.createElement(WrapWithLabel, { name: propKey },
                    React.createElement(TextArea, { value: value, name: propKey, onChange: (e) => {
                            modify(propKey, e.target.value);
                            this.setState(this.state);
                        } })));
            }
            if (propKey === 'padding' || propKey === 'margin') {
                return (React.createElement(CSSUnitInput, { name: propKey, value: value, onChange: (e) => {
                        modify(propKey, e.target.value);
                        this.setState(this.state);
                    } }));
            }
            if (type === 'boolean') {
                return (React.createElement(WrapWithLabel, { name: propKey },
                    React.createElement(Switch, { onChange: (e) => {
                            modify(propKey, e.target.checked);
                            this.setState(this.state);
                        }, key: idx, checked: value })));
            }
            if (type === 'number') {
                return (React.createElement(NumericValue, { name: propKey, value: value, onChange: (e) => {
                        modify(propKey, e.target.value);
                        this.setState(this.state);
                    } }));
            }
            if (type === 'string') {
                return (React.createElement(TextInput, { value: value, name: propKey, onChange: (e) => {
                        modify(propKey, e.target.value);
                        this.setState(this.state);
                    } }));
            }
            return JSON.stringify({ type, value });
        });
    }
    render() {
        var _a;
        const currentNode = this.getCurrentNode() == null ? null : this.getCurrentNode();
        if (currentNode) {
            const { label } = currentNode;
        }
        const modify = (key, value) => (currentNode.options.props[key] = value);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cx(classWithDarkTheme(css, 'header', this.props.style.editorDarkMode)) },
                React.createElement("div", null,
                    React.createElement("strong", null, "Current Theme:"),
                    ' ',
                    React.createElement(ThemeSelect, { theme: this.props.style.template })),
                React.createElement("div", null,
                    React.createElement(Button, { style: { margin: '4px' }, icon: "download", intent: Intent.SUCCESS }, "Export To theme.json"),
                    React.createElement(Button, { style: { margin: '4px' }, icon: "upload" }, "Import from theme.json"))),
            React.createElement("div", { className: cx(css.main) },
                React.createElement("div", { className: cx(css.sidebar) },
                    React.createElement("label", { style: { display: 'flex' }, className: Classes.LABEL },
                        React.createElement("input", { style: { margin: '4px', width: 'calc(80% - 8px)' }, className: Classes.INPUT, type: "text", placeholder: "Filter..." }),
                        React.createElement(Button, { style: { width: '20%' }, icon: "search", className: Classes.MINIMAL })),
                    React.createElement(Tree, { contents: componentTree, onNodeClick: this.onNodeClick, onNodeCollapse: this.onNodeCollapse, onNodeExpand: this.onNodeExpand })),
                React.createElement("div", { className: cx(css.componentView) },
                    React.createElement(ErrorBoundary, { errorMessage: JSON.stringify(currentNode) },
                        React.createElement("div", { className: cx(this.props.style.template.toLowerCase().replace(/\s/g, '-'), classWithDarkTheme(css, 'componentResult', this.props.style.editorDarkMode)) },
                            currentNode &&
                                currentNode.options &&
                                this.getComponent(currentNode.id, currentNode),
                            !currentNode && React.createElement(Icon, { icon: "grid" }))),
                    React.createElement("div", { className: cx(css.componentOptions) },
                        React.createElement("strong", { className: css.componentOptionsLabel },
                            this.getCurrentNode() == null ? '' : this.getCurrentNode().label,
                            ' ',
                            "Options"),
                        React.createElement(Menu, null, currentNode &&
                            currentNode.options &&
                            this.renderOptions((_a = currentNode === null || currentNode === void 0 ? void 0 : currentNode.options) === null || _a === void 0 ? void 0 : _a.props)))))));
    }
}
export const ThemeEditor = connect((state) => ({
    style: state.style,
}), null)(ThemeEditorBase);
