import * as React from 'react';
import { connect } from 'react-redux';
import { updateEditorHistory } from 'actions';
import { feature, isLocal } from 'utils';
import { ErrorBoundary } from 'components';
import { Button } from '@blueprintjs/core';
import { updaterSelector, appSelector } from 'selectors';
const isEqual = require('lodash/isEqual');
import './app.css';
import { Skeleton } from 'components/Shared';
const Editor = React.lazy(() => import('components/Editor').then((res) => ({ default: res.Editor })));
const Result = React.lazy(() => import('components/Result/Result').then((res) => ({ default: res.Result })));
const Result2 = React.lazy(() => import('components/Result/Result2').then((res) => ({ default: res.Result })));
const ImagesDrawer = React.lazy(() => import('components/Shared/ImagesDrawer').then((res) => ({ default: res.ImagesDrawer })));
const BugReporter = React.lazy(() => import('components/BugReporter').then((res) => ({ default: res.BugReporter })));
const Hotkeys = React.lazy(() => import('components/Hotkeys').then((res) => ({ default: res.Hotkeys })));
export class UpdaterBase extends React.Component {
    componentDidMount() {
        this.props.updateEditorHistory(this.props.present);
    }
    UNSAFE_componentWillReceiveProps(prev) {
        if ((prev.lrt === 'update') &&
            this.props.present != null &&
            this.props.present != null &&
            !isEqual(this.props.present, prev.present)) {
            const t0 = performance.now();
            this.props.updateEditorHistory(prev.present);
            const t1 = performance.now();
            console.log(`Updated history in ${t1 - t0}ms`);
        }
    }
    render() {
        return React.createElement("div", null);
    }
}
export const Updater = connect(updaterSelector, { updateEditorHistory }, null, { pure: false })(UpdaterBase);
export class AppBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = { result2: false };
    }
    componentDidMount() {
        if (feature.resultv2) {
            if (this.props.style.customCSS.includes('resultv2')) {
                this.setState({ result2: true });
            }
            else {
                this.setState({ result2: false });
            }
        }
    }
    render() {
        const { style, view, editor } = this.props;
        const { result2 } = this.state;
        const isDarkMode = style.editorDarkMode;
        console.log('features', feature);
        const UpdaterComponent = !editor.editorHistoryDisabled && React.createElement(Updater, null);
        return (React.createElement(ErrorBoundary, { errorMessage: React.createElement("div", { className: 'p-6 center-text' },
                React.createElement("h2", null, "There was a problem retrieving your nuzlocke data."),
                React.createElement("p", null, "Please consider submitting a bug report."),
                React.createElement(React.Suspense, { fallback: 'Loading Bug Reporter...' },
                    React.createElement(BugReporter, { defaultOpen: true }))) },
            React.createElement("div", { "data-testid": "app", className: "app", role: "main", style: {
                    background: this.props.style.editorDarkMode ? '#111' : '#fff',
                } },
                UpdaterComponent,
                React.createElement(ErrorBoundary, { key: 1 },
                    React.createElement(React.Suspense, { fallback: Skeleton },
                        React.createElement(Hotkeys, null))),
                React.createElement(ErrorBoundary, { key: 2 },
                    React.createElement(React.Suspense, { fallback: Skeleton },
                        React.createElement(Editor, null))),
                result2 ? React.createElement(ErrorBoundary, { key: 3 },
                    React.createElement(React.Suspense, { fallback: Skeleton },
                        React.createElement(Result2, null))) : React.createElement(ErrorBoundary, { key: 3 },
                    React.createElement(React.Suspense, { fallback: Skeleton },
                        React.createElement(Result, null))),
                isLocal() && feature.resultv2 && React.createElement(Button, { style: {
                        position: 'absolute',
                        top: '0.5rem',
                        right: '0.5rem',
                        zIndex: 1000,
                    }, onClick: e => this.setState({ result2: !result2 }) }, "Use Result v2"),
                React.createElement(ErrorBoundary, { key: 4 },
                    React.createElement(React.Suspense, { fallback: Skeleton },
                        React.createElement(ImagesDrawer, null))))));
    }
}
export const App = connect(appSelector)(AppBase);
