import { EDIT_CUSTOM_MOVE_MAP, DELETE_CUSTOM_MOVE, REPLACE_STATE, VERSION_1116, } from 'actions';
const uuid = require('uuid');
const initialState = [];
export function customMoveMap(state = initialState, action) {
    switch (action.type) {
        case EDIT_CUSTOM_MOVE_MAP:
            return [
                ...state,
                {
                    id: uuid(),
                    type: action.moveType,
                    move: action.moveName,
                },
            ];
        case DELETE_CUSTOM_MOVE:
            return state.filter((move) => move.id !== action.id);
        case REPLACE_STATE:
            return action.replaceWith.customMoveMap || [];
        case VERSION_1116:
            return [];
        default:
            return state;
    }
}
