import * as React from 'react';
import { connect } from 'react-redux';
import { cx } from 'emotion';
import { selectPokemon } from 'actions';
import { getContrastColor, gameOfOriginToColor } from 'utils';
import { PokemonIcon } from 'components/PokemonIcon';
import { GenderElement } from 'components/Shared';
const getAccentColor = (prop) => (prop === null || prop === void 0 ? void 0 : prop.style) ? prop === null || prop === void 0 ? void 0 : prop.style.accentColor : '#111111';
const determineWidth = (isMinimal, numerator) => {
    return isMinimal ? 'auto' : `calc(94% / ${numerator})`;
};
export const BoxedPokemonBase = (poke) => {
    var _a, _b, _c, _d, _e;
    const isMinimal = (_a = poke === null || poke === void 0 ? void 0 : poke.style) === null || _a === void 0 ? void 0 : _a.minimalBoxedLayout;
    const useGameOfOriginColor = (poke === null || poke === void 0 ? void 0 : poke.gameOfOrigin) &&
        ((_b = poke === null || poke === void 0 ? void 0 : poke.style) === null || _b === void 0 ? void 0 : _b.displayGameOriginForBoxedAndDead) &&
        ((_c = poke === null || poke === void 0 ? void 0 : poke.style) === null || _c === void 0 ? void 0 : _c.displayBackgroundInsteadOfBadge);
    return (React.createElement("div", { className: cx('boxed-pokemon-container'), style: {
            background: useGameOfOriginColor
                ? gameOfOriginToColor(poke === null || poke === void 0 ? void 0 : poke.gameOfOrigin)
                : getAccentColor(poke),
            color: useGameOfOriginColor
                ? getContrastColor(gameOfOriginToColor(poke === null || poke === void 0 ? void 0 : poke.gameOfOrigin))
                : getContrastColor(getAccentColor(poke)),
            width: determineWidth(isMinimal, poke === null || poke === void 0 ? void 0 : poke.style.boxedPokemonPerLine),
        } },
        process.env.NODE_ENV !== 'test' && (React.createElement(PokemonIcon, { position: poke === null || poke === void 0 ? void 0 : poke.position, species: poke === null || poke === void 0 ? void 0 : poke.species, id: poke === null || poke === void 0 ? void 0 : poke.id, style: { cursor: 'pointer' }, forme: poke === null || poke === void 0 ? void 0 : poke.forme, shiny: poke === null || poke === void 0 ? void 0 : poke.shiny, gender: poke === null || poke === void 0 ? void 0 : poke.gender, egg: poke === null || poke === void 0 ? void 0 : poke.egg, customIcon: poke === null || poke === void 0 ? void 0 : poke.customIcon, className: 'boxed-pokemon-icon' })),
        isMinimal ? null : (React.createElement("div", { className: "boxed-pokemon-info", style: {
                borderLeftColor: useGameOfOriginColor
                    ? getContrastColor(gameOfOriginToColor(poke === null || poke === void 0 ? void 0 : poke.gameOfOrigin))
                    : getAccentColor(poke),
            } },
            React.createElement("span", { "data-testid": "boxed-pokemon-name", className: "boxed-pokemon-name" }, poke === null || poke === void 0 ? void 0 :
                poke.nickname,
                " ",
                GenderElement(poke === null || poke === void 0 ? void 0 : poke.gender),
                ' ',
                (poke === null || poke === void 0 ? void 0 : poke.level) ? React.createElement("span", null,
                    "lv. ", poke === null || poke === void 0 ? void 0 :
                    poke.level) : null,
                ((_d = poke === null || poke === void 0 ? void 0 : poke.style) === null || _d === void 0 ? void 0 : _d.displayGameOriginForBoxedAndDead) &&
                    !((_e = poke === null || poke === void 0 ? void 0 : poke.style) === null || _e === void 0 ? void 0 : _e.displayBackgroundInsteadOfBadge) &&
                    (poke === null || poke === void 0 ? void 0 : poke.gameOfOrigin) && (React.createElement("span", { className: "boxed-pokemon-gameoforigin", style: {
                        background: gameOfOriginToColor(poke === null || poke === void 0 ? void 0 : poke.gameOfOrigin),
                        color: getContrastColor(gameOfOriginToColor(poke === null || poke === void 0 ? void 0 : poke.gameOfOrigin)),
                        fontSize: '80%',
                        borderRadius: '.25rem',
                        margin: '.25rem',
                        padding: '.25rem',
                    } }, poke === null || poke === void 0 ? void 0 : poke.gameOfOrigin)))))));
};
export const BoxedPokemon = connect((state) => ({ style: state.style }), {
    selectPokemon,
})(BoxedPokemonBase);
