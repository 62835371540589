import * as React from 'react';
const debounce = require('lodash.debounce');
export function TrainerInfoEditField({ label, name, placeholder, onEdit, value, element, }) {
    const [innerValue, setValue] = React.useState('');
    const delayedValue = React.useCallback(debounce((e) => onEdit(e), 300), [value]);
    React.useEffect(() => {
        setValue(value);
    }, [value]);
    const onChange = (e) => {
        e.persist();
        setValue(e.target.value);
        delayedValue(e);
    };
    return React.createElement("div", { className: "trainer-info-field" },
        React.createElement("label", null, label),
        element ? (element({ label, name, placeholder, onEdit, value: innerValue })) : (React.createElement("input", { type: "text", value: innerValue, onChange: onChange, placeholder: placeholder, name: name })));
}
