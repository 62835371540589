var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var BoxBase_1;
import * as React from 'react';
import { editPokemon, clearBox, editBox, deleteBox, deletePokemon, updateBoxes } from 'actions';
import { PokemonByFilter } from 'components/Shared';
import { DropTarget, DragSource } from 'react-dnd';
import { store } from 'store';
import { Icon, Popover, PopoverInteractionKind, MenuItem, Position, Intent, Alert, Classes, Toaster, } from '@blueprintjs/core';
import { connect } from 'react-redux';
const boxSource = {
    drop(props, monitor, component) {
        const newStatus = props.name;
        store.dispatch(editPokemon({ status: newStatus }, monitor.getItem().id));
        return {};
    },
    hover(props, monitor) {
        return { isHovering: monitor.isOver({ shallow: true }) };
    },
};
const boxSourceDrop = {
    drop(props, monitor, component) {
        const item = monitor.getItem();
        if (props.id == null || item.id == null) {
            const toaster = Toaster.create();
            toaster.show({
                message: 'Failed to move Boxes',
                intent: Intent.DANGER,
            });
            return;
        }
        store.dispatch(editBox(props.id, {
            position: item.position,
        }));
        store.dispatch(editBox(item.id, {
            position: props.position,
        }));
        return {};
    },
    hover(props, monitor) {
        return { isHovering: monitor.isOver({ shallow: true }) };
    }
};
const boxSourceDrag = {
    beginDrag(props) {
        return props;
    },
    isDragging(props, monitor) {
        return props;
    },
};
export const wallpapers = [
    {
        name: 'Route 1',
        background: 'route-1',
    },
    {
        name: 'Grass Meadow',
        background: 'grass-meadow',
    },
    {
        name: 'Stars',
        background: 'stars',
    },
    {
        name: 'Sky',
        background: 'sky',
    },
    {
        name: 'Bubbles',
        background: 'bubbles',
    },
    {
        name: 'Beach',
        background: 'beach',
    },
    {
        name: 'Seafloor',
        background: 'seafloor',
    },
    {
        name: 'Croagunk',
        background: 'croagunk',
    },
    {
        name: 'Simple',
        background: 'simple',
    },
    {
        name: 'Snow',
        background: 'snow',
    },
];
let BoxBase = BoxBase_1 = class BoxBase extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            deleteConfirmationOpen: false,
        };
        this.clearBox = (name) => () => {
            this.props.clearBox(name);
        };
        this.deleteBox = (id) => () => {
            this.setState({ deleteConfirmationOpen: true });
        };
        this.editBox = (id, edits) => () => {
            this.props.editBox(id, edits);
        };
        this.toggleCollapse = (isCollapsed, id) => () => {
            this.props.editBox(id, { collapsed: !isCollapsed });
        };
        this.toggleDialog = () => this.setState({ deleteConfirmationOpen: !this.state.deleteConfirmationOpen });
    }
    componentDidMount() {
        this.props.updateBoxes();
    }
    static getDefault(name) {
        if (name === 'Team')
            return 'route-1';
        if (name === 'Boxed')
            return 'grass-meadow';
        if (name === 'Dead')
            return 'stars';
        if (name === 'Champs')
            return 'sky';
        return undefined;
    }
    static getBoxBackground(background, name) {
        const bg = background || BoxBase_1.getDefault(name);
        return bg && bg.startsWith('http') ? `url(${bg})` : `url(./assets/img/box/${bg}.png)`;
    }
    render() {
        const { pokemon, inheritFrom, name, id, connectDropTarget, connectDragSource, connectDropTargetBox, canDrop, background, collapsed: isCollapsed, } = this.props;
        const collapsedStyle = isCollapsed
            ? {
                height: '54px',
                overflow: 'hidden',
                WebkitMaskImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.33) 25%, black 75%)',
                marginBottom: '-18px',
            }
            : {};
        return connectDropTargetBox(connectDragSource(connectDropTarget(React.createElement("div", { style: Object.assign({ backgroundImage: BoxBase_1.getBoxBackground(background, name) }, collapsedStyle), className: `box ${name.replace(/\s/g, '-')}-box` },
            React.createElement(Alert, { icon: "trash", isOpen: this.state.deleteConfirmationOpen, onCancel: this.toggleDialog, onConfirm: (e) => {
                    this.props.deleteBox(id);
                    pokemon
                        .filter((pokemon) => pokemon.status === name)
                        .forEach((element) => {
                        this.props.deletePokemon(element.id);
                    });
                    this.setState({ deleteConfirmationOpen: true });
                }, confirmButtonText: "Delete Box", cancelButtonText: "Cancel", intent: Intent.DANGER },
                React.createElement("p", null, "This will delete the currently selected Box and all Pok\u00E9mon stored inside the box. Are you sure you want to do that?")),
            React.createElement(Popover, { position: Position.BOTTOM_LEFT, minimal: true, interactionKind: PopoverInteractionKind.CLICK_TARGET_ONLY, popoverClassName: 'no-list-item-types', content: React.createElement(React.Fragment, null,
                    React.createElement(MenuItem, { text: "Change Wallpaper" }, wallpapers.map((wall) => (React.createElement(MenuItem, { key: wall.name, onClick: this.editBox(id, { background: wall.background }), text: wall.name })))),
                    !['Team', 'Boxed', 'Dead', 'Champs'].includes(name) && (React.createElement(MenuItem, { text: "Change Type" }, ['Team', 'Boxed', 'Dead', 'Champs'].map((b) => (React.createElement(MenuItem, { key: b, onClick: this.editBox(id, { inheritFrom: b }), text: b === inheritFrom ? (React.createElement(React.Fragment, null,
                            React.createElement(Icon, { icon: "small-tick" }),
                            " ",
                            b)) : (b) }))))),
                    React.createElement(MenuItem, { onClick: this.toggleCollapse(isCollapsed, id), text: isCollapsed ? 'Expand Box' : 'Collapse Box' }),
                    React.createElement(MenuItem, { onClick: this.clearBox(name), className: Classes.FILL, text: 'Clear Box' }),
                    !['Team', 'Boxed', 'Dead', 'Champs'].includes(name) && (React.createElement(MenuItem, { onClick: this.deleteBox(id), className: Classes.FILL, text: 'Delete Box' }))) },
                React.createElement("span", { style: {
                        alignItems: 'center',
                        background: canDrop ? 'black' : 'rgba(33, 33, 33, 0.33)',
                        borderRadius: '.25rem',
                        color: '#eee',
                        display: 'inline-flex',
                        minHeight: '2rem',
                        justifyContent: 'space-around',
                        margin: '.25rem',
                        padding: '.25rem',
                        textAlign: 'center',
                        minWidth: '5rem',
                        cursor: 'pointer',
                        userSelect: 'none',
                    } },
                    React.createElement(Icon, { style: { transform: 'rotate(90deg)' }, icon: "more" }),
                    name)),
            React.createElement(PokemonByFilter, { searchTerm: this.props.searchTerm, team: pokemon, status: name })))));
    }
};
BoxBase = BoxBase_1 = __decorate([
    DropTarget('ICON', boxSource, (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        canDrop: monitor.canDrop(),
    })),
    DragSource('BOX', boxSourceDrag, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    })),
    DropTarget('BOX', boxSourceDrop, (connect, monitor) => ({
        connectDropTargetBox: connect.dropTarget(),
        isDragging: monitor.canDrop(),
    }))
], BoxBase);
export { BoxBase };
export const Box = connect(null, {
    clearBox,
    editBox,
    deleteBox,
    deletePokemon,
    updateBoxes,
})(BoxBase);
