import * as React from 'react';
import { PokemonIcon } from 'components/PokemonIcon';
import { gameOfOriginToColor, getContrastColor } from 'utils';
import { GenderElement } from '../Shared';
import { css, cx } from 'emotion';
import { PokemonImage } from 'components/Shared/PokemonImage';
export const champsPokemon = (options) => css `
    height: ${options.height};
    width: ${options.width};
    margin: ${options.margin};
    display: inline-flex;
    align-items: center;
    justify-content: ${options.minimal ? 'center' : 'start'};
    background: ${options.background};
    color: ${options.color};
    padding: ${options.padding};
    cursor: pointer;
    /* flex-grow: 4; */
`;
export class ChampsPokemon extends React.Component {
    constructor() {
        super(...arguments);
        this.getWidth = () => {
            const base = 48;
            return (base +
                (this.props.showGender ? 24 : 0) +
                (this.props.showNickname ? 128 : 0) +
                (this.props.showLevel ? 24 : 0));
        };
    }
    getPokemonImage() {
        const { customImage, forme, species, gameOfOrigin, shiny, gender, nickname, } = this.props;
        return (React.createElement(PokemonImage, { customImage: customImage, name: gameOfOrigin, species: species, shiny: shiny, gender: gender, forme: forme, style: {
                spritesMode: true,
                useSpritesForChampsPokemon: true,
            } }, (backgroundImage) => React.createElement("img", { className: 'champs-pokemon-image', alt: '', style: {
                backgroundImage,
                backgroundPosition: 'center center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '48px',
                width: '48px',
            } })));
    }
    render() {
        var _a;
        const { margin, padding, customCSS } = this.props;
        const color = gameOfOriginToColor(this.props.gameOfOrigin);
        const minimal = !(this.props.showNickname || this.props.showGender || this.props.showLevel);
        const className = champsPokemon({
            background: color,
            color: getContrastColor(color),
            height: '48px',
            width: minimal ? `${this.getWidth()}px` : 'auto',
            margin,
            padding: minimal ? padding : '0 4px 0 0',
            minimal,
        });
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cx(className, 'champs-pokemon') },
                this.props.useSprites ? (this.getPokemonImage()) : (React.createElement(PokemonIcon, Object.assign({ className: 'champs-pokemon-image' }, this.props))),
                React.createElement("span", { className: 'champs-pokemon-nickname', style: { margin: '0 4px' } }, this.props.showNickname && this.props.nickname),
                this.props.showGender && GenderElement(this.props.gender),
                React.createElement("span", { className: 'champs-pokemon-level', style: { marginLeft: '2px' } }, this.props.showLevel && ` Lv ${(_a = this.props.level) !== null && _a !== void 0 ? _a : 0}`)),
            customCSS && React.createElement("style", null, `.${className} {
                        ${customCSS}
                    }`)));
    }
}
ChampsPokemon.defaultProps = {
    showNickname: false,
    showGender: false,
    showLevel: false,
};
