import * as React from 'react';
import { connect } from 'react-redux';
import { editGame, changeEditorSize, editStyle, resetCheckpoints, toggleTemtemMode } from 'actions';
import { gameOfOriginToColor, listOfGames, feature } from 'utils';
import { Button, Intent, Switch, Classes } from '@blueprintjs/core';
import { RulesEditorDialog } from 'components/RulesEditor';
import { BaseEditor } from 'components/BaseEditor';
const gameSubEditorStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '.25rem',
};
export class GameEditorBase extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isOpen: false,
        };
        this.onInput = (e) => {
            this.props.editGame({ name: e.target.value });
            this.props.editStyle({
                bgColor: gameOfOriginToColor(e.target.value),
            });
            this.props.resetCheckpoints(e.target.value);
        };
        this.onInputName = (e) => {
            this.props.editGame({ customName: e.target.value });
        };
        this.toggleDialog = () => this.setState({ isOpen: !this.state.isOpen });
    }
    render() {
        const { game } = this.props;
        const RED = RulesEditorDialog;
        return (React.createElement(React.Fragment, null,
            React.createElement(RED, { isOpen: this.state.isOpen, onClose: this.toggleDialog }),
            React.createElement(BaseEditor, { icon: 'ninja', name: "Game" },
                React.createElement("div", { style: gameSubEditorStyle },
                    React.createElement("div", null,
                        React.createElement("label", { className: Classes.INLINE, style: { fontSize: '80%', marginRight: '.5rem' } }, "Version"),
                        React.createElement("div", { className: Classes.SELECT },
                            React.createElement("select", { onChange: this.onInput, value: game.name }, listOfGames.map((game) => (React.createElement("option", { key: game }, game)))))),
                    React.createElement(Button, { onClick: this.toggleDialog, icon: "list", intent: Intent.PRIMARY }, "Modify Rules")),
                React.createElement("div", { style: gameSubEditorStyle },
                    React.createElement("div", { style: { fontSize: '80%' } },
                        React.createElement("label", { className: Classes.INLINE, style: { marginRight: 'calc(.75rem + 2px)' } }, "Name"),
                        React.createElement("input", { onChange: this.onInputName, value: game.customName, autoComplete: 'false', size: 20, className: Classes.INPUT, type: "text", placeholder: game.name })),
                    feature.temTemMode && (React.createElement(Button, { style: {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }, className: Classes.MINIMAL },
                        React.createElement(Switch, { label: "TemTem Mode", checked: this.props.editor.temtemMode, onChange: (e) => this.props.toggleTemtemMode() })))))));
    }
}
export const GameEditor = connect((state) => ({
    game: state.game,
    editor: state.editor,
    style: state.style,
}), {
    editGame,
    editStyle,
    changeEditorSize,
    resetCheckpoints,
    toggleTemtemMode,
})(GameEditorBase);
