import * as React from 'react';
import { useSelector } from 'react-redux';
import { cx } from 'emotion';
import { Classes } from '@blueprintjs/core';
import { ErrorBoundary, Skeleton } from 'components/Shared';
import { editorStyles } from './styles';
import './editor.css';
import { editorModeSelector, minimizedSelector } from 'selectors';
const PokemonEditor = React.lazy(() => import('components/PokemonEditor').then((res) => ({ default: res.PokemonEditor })));
const NuzlockeSaveControls = React.lazy(() => import('components/SavesEditor/NuzlockeSaveControls').then((res) => ({
    default: res.NuzlockeSaveControls,
})));
const GameEditor = React.lazy(() => import('components/GameEditor').then((res) => ({ default: res.GameEditor })));
const TrainerEditor = React.lazy(() => import('components/TrainerEditor').then((res) => ({ default: res.TrainerEditor })));
const HotkeysEditor = React.lazy(() => import('components/HotkeysEditor').then((res) => ({ default: res.HotkeysEditor })));
const BugReporter = React.lazy(() => import('components/BugReporter').then((res) => ({ default: res.BugReporter })));
const StatsEditor = React.lazy(() => import('components/StatsEditor').then((res) => ({ default: res.StatsEditor })));
const StyleEditor = React.lazy(() => import('components/StyleEditor').then((res) => ({ default: res.StyleEditor })));
const DataEditor = React.lazy(() => import('components/DataEditor').then((res) => ({ default: res.DataEditor })));
const EditorControls = React.lazy(() => import('components/Editor/EditorControls').then((res => ({ default: res.EditorControls }))));
const Credits = React.lazy(() => import('components/Credits').then((res) => ({ default: res.Credits })));
export function Editor() {
    const minimized = useSelector(minimizedSelector);
    const editorDarkMode = useSelector(editorModeSelector);
    return (React.createElement("div", { className: cx('editor', editorStyles.base, editorDarkMode ? Classes.DARK : ''), style: {
            width: minimized ? '0%' : '33%',
            marginLeft: minimized ? '-30rem' : '0',
            background: editorDarkMode ? '#222' : '#fff',
        } },
        React.createElement(ErrorBoundary, { key: 1 },
            React.createElement(React.Suspense, { fallback: Skeleton },
                React.createElement(EditorControls, { editorDarkMode: editorDarkMode, minimized: minimized }))),
        React.createElement(ErrorBoundary, { key: 2 },
            React.createElement(React.Suspense, { fallback: Skeleton },
                React.createElement(NuzlockeSaveControls, null))),
        React.createElement(ErrorBoundary, { key: 3 },
            React.createElement(React.Suspense, { fallback: Skeleton },
                React.createElement(GameEditor, null))),
        React.createElement(ErrorBoundary, { key: 4 },
            React.createElement(React.Suspense, { fallback: Skeleton },
                React.createElement(DataEditor, null))),
        React.createElement(ErrorBoundary, { key: 5 },
            React.createElement(React.Suspense, { fallback: Skeleton },
                React.createElement(TrainerEditor, null))),
        React.createElement(ErrorBoundary, { key: 6 },
            React.createElement(React.Suspense, { fallback: Skeleton },
                React.createElement(PokemonEditor, null))),
        React.createElement(ErrorBoundary, { key: 7 },
            React.createElement(React.Suspense, { fallback: Skeleton },
                React.createElement(StyleEditor, null))),
        React.createElement(ErrorBoundary, { key: 8 },
            React.createElement(React.Suspense, { fallback: Skeleton },
                React.createElement(StatsEditor, null))),
        React.createElement(ErrorBoundary, { key: 9 },
            React.createElement(React.Suspense, { fallback: Skeleton },
                React.createElement(HotkeysEditor, null))),
        React.createElement(ErrorBoundary, { key: 10 },
            React.createElement(React.Suspense, { fallback: Skeleton },
                React.createElement(BugReporter, null))),
        React.createElement(ErrorBoundary, { key: 11 },
            React.createElement(React.Suspense, { fallback: Skeleton },
                React.createElement(Credits, null)))));
}
