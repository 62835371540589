import * as React from 'react';
import { connect } from 'react-redux';
import { Toaster, Intent } from '@blueprintjs/core';
import { selectPokemon, deletePokemon, addPokemon, newNuzlocke, changeEditorSize, toggleDialog } from 'actions';
import { sortPokes, sortPokesReverse, noop, generateEmptyPokemon } from 'utils';
import { listOfHotkeys } from 'utils';
import { persistor } from 'store';
import { createStore } from 'redux';
import { appReducers } from 'reducers';
export class HotkeysBase extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleKeyDown = (e) => {
            return;
        };
        this.handleKeyUp = (e) => {
            listOfHotkeys.map((hotkey) => {
                if (e.key === hotkey.key) {
                    if (this.isTextInput(e)) {
                        noop();
                    }
                    else {
                        if (hotkey === null || hotkey === void 0 ? void 0 : hotkey.onKeyUp)
                            this[hotkey.onKeyUp]();
                    }
                }
            });
        };
        this.globalHotkeysEvents = {
            handleKeyDown: this.handleKeyDown,
            handleKeyUp: this.handleKeyUp,
        };
    }
    componentDidMount() {
        document && document.addEventListener('keydown', this.globalHotkeysEvents.handleKeyDown);
        document && document.addEventListener('keyup', this.globalHotkeysEvents.handleKeyUp);
    }
    UNSAFE_componentWillMount() {
        document && document.removeEventListener('keydown', this.globalHotkeysEvents.handleKeyDown);
        document && document.removeEventListener('keyup', this.globalHotkeysEvents.handleKeyUp);
    }
    isTextInput(e) {
        const elem = e.target;
        if (elem == null || elem.closest == null) {
            return false;
        }
        const editable = elem.closest('input, textarea, [contenteditable=true], select');
        if (editable == null) {
            return false;
        }
        if (editable.tagName.toLowerCase() === 'input') {
            const inputType = editable.type;
            if (inputType === 'checkbox' || inputType === 'radio') {
                return false;
            }
        }
        if (editable.readOnly) {
            return false;
        }
        return true;
    }
    getFirstPokemonId() {
        return this.props.pokemon.sort(sortPokes)[0].id;
    }
    getLastPokemonId() {
        return this.props.pokemon.sort(sortPokesReverse)[0].id;
    }
    manualSave() {
        persistor
            .flush()
            .then((res) => {
            const toaster = Toaster.create();
            toaster.show({
                message: 'Save successful!',
                intent: Intent.SUCCESS,
            });
        })
            .catch((err) => {
            const toaster = Toaster.create();
            toaster.show({
                message: 'Saved failed. Please try again.',
                intent: Intent.DANGER,
            });
        });
    }
    previousPokemon() {
        var _a, _b;
        if (!((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.pokemon) === null || _b === void 0 ? void 0 : _b.length))
            return;
        const poke = this.props.pokemon.find((p) => p.id === this.props.selectedId);
        const position = poke.position;
        const prevPoke = this.props.pokemon.find((p) => p.position === position - 1);
        const id = prevPoke ? prevPoke.id : this.getLastPokemonId();
        this.props.selectPokemon(id);
    }
    nextPokemon() {
        var _a, _b;
        if (!((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.pokemon) === null || _b === void 0 ? void 0 : _b.length))
            return;
        const poke = this.props.pokemon.find((p) => p.id === this.props.selectedId);
        const position = poke.position;
        const nextPoke = this.props.pokemon.find((p) => p.position === position + 1);
        const id = nextPoke ? nextPoke.id : this.getFirstPokemonId();
        this.props.selectPokemon(id);
    }
    addPokemon() {
        this.props.addPokemon(generateEmptyPokemon(this.props.pokemon));
    }
    deletePokemon() {
        if (!this.props.selectedId)
            return;
        this.props.deletePokemon(this.props.selectedId);
    }
    newNuzlocke() {
        var _a;
        const data = (_a = createStore(appReducers)) === null || _a === void 0 ? void 0 : _a.getState();
        this.props.newNuzlocke(JSON.stringify(data), { isCopy: false });
    }
    toggleEditor() {
        console.log('pressed m');
        this.props.changeEditorSize(!this.props.editor.minimized);
    }
    toggleImageUploader() {
        this.props.toggleDialog('imageUploader');
    }
    render() {
        return React.createElement("div", null);
    }
}
export const Hotkeys = connect((state) => ({
    pokemon: state.pokemon,
    selectedId: state.selectedId,
    editor: state.editor,
}), {
    selectPokemon,
    deletePokemon,
    addPokemon,
    newNuzlocke,
    changeEditorSize,
    toggleDialog,
})(HotkeysBase);
