import * as React from 'react';
import { useDispatch } from 'react-redux';
import { addPokemon, selectPokemon } from 'actions';
import { Button, Intent } from '@blueprintjs/core';
export function AddPokemonButton({ pokemon }) {
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(addPokemon(pokemon));
        dispatch(selectPokemon(pokemon.id));
    };
    return React.createElement(Button, { icon: "add", intent: Intent.SUCCESS, className: "add-new-pokemon", onClick: onClick }, "Add New Pok\u00E9mon");
}
