import * as React from 'react';
import { Classes } from '@blueprintjs/core';
import * as css from './styles';
import { cx } from 'emotion';
import { head, last } from 'ramda';
export const splitValue = (value) => {
    const value1 = value.split(/\D/g);
    const value2 = value.split(/\d/g);
    return {
        num: head(value1),
        unit: last(value2),
    };
};
export class CSSUnitInput extends React.Component {
    constructor() {
        super(...arguments);
        this.defaultProps = {
            allowedUnits: ['px', 'cm', 'mm', 'in', 'pt', 'pc', 'em', 'rem', 'vw', 'vh', '%'],
        };
        this.setUnit = (e) => {
            this.setState({ chosenUnit: e.target.value });
            this.onChange();
        };
        this.setNumber = (e) => {
            this.setState({ chosenNumber: Number.parseInt(e.target.value) });
        };
        this.onChange = () => {
            const { chosenUnit, chosenNumber } = this.state;
            this.props.onChange({
                target: {
                    value: `${chosenNumber}${chosenUnit}`,
                },
            });
        };
    }
    static getDerivedStateFromProps(props) {
        const { name, value, allowedUnits } = props;
        const { unit, num } = splitValue(value);
        return {
            chosenNumber: num,
            chosenUnit: unit,
        };
    }
    render() {
        const { name, value, allowedUnits } = this.props;
        const { chosenUnit, chosenNumber } = this.state;
        const { unit, num } = splitValue(value);
        const units = ['px', 'cm', 'mm', 'in', 'pt', 'pc', 'em', 'rem', 'vw', 'vh', '%'];
        console.log(unit, num);
        return (React.createElement("div", { className: cx(css.componentOption, css.cssUnit) },
            React.createElement("label", { className: Classes.LABEL }, name),
            React.createElement("input", { className: cx(Classes.INPUT, css.unitInput), name: name, onChange: this.setNumber, type: "number", value: chosenNumber }),
            React.createElement("div", { className: cx(Classes.SELECT, css.unitSelect) },
                React.createElement("select", { onChange: this.setUnit, value: chosenUnit }, units === null || units === void 0 ? void 0 : units.map((u) => (React.createElement("option", { key: u, value: u }, u)))))));
    }
}
