import * as React from 'react';
import { handleMovesGenerationsExceptions, getMoveType, typeToColor, getContrastColor, } from 'utils';
import { connect } from 'react-redux';
import { noop } from 'redux-saga/utils';
import * as ReactDOMServer from 'react-dom/server';
export const Move = ({ index, style, type, move, customTypes, stripClasses = false }) => {
    var _a;
    return move && (React.createElement("div", { key: index, style: style.usePokemonGBAFont
            ? {
                fontSize: '1rem',
                background: typeToColor(type, customTypes) || 'transparent',
                color: getContrastColor(typeToColor(type, customTypes)),
            }
            : {
                background: typeToColor(type, customTypes) || 'transparent',
                color: getContrastColor(typeToColor(type, customTypes)),
            }, className: stripClasses
            ? ''
            : `move move-${(_a = move.replace(/\s/g, '-')) === null || _a === void 0 ? void 0 : _a.toLowerCase()} move-${type.toLowerCase()} ${move.length >= 10 ? 'long-text-move' : ''}` }, move));
};
export const getMapMove = (moveMap, move) => moveMap === null || moveMap === void 0 ? void 0 : moveMap.find((m) => m.move === move);
export class MovesBase extends React.Component {
    generateMoves(moves) {
        const { style, customMoveMap, customTypes, stripClasses = false } = this.props;
        return (moves &&
            moves.map((move, index) => {
                let customMove;
                try {
                    customMove = getMapMove(customMoveMap, move);
                }
                catch (_a) {
                    noop();
                }
                move = move.trim();
                const type = handleMovesGenerationsExceptions({
                    move: move,
                    generation: this.props.generation,
                    originalType: (customMove === null || customMove === void 0 ? void 0 : customMove.type) || getMoveType(move),
                });
                return (React.createElement(Move, { key: index, index: index, style: style, type: type, move: move, stripClasses: stripClasses, customTypes: customTypes }));
            }));
    }
    renderToString() {
        var _a;
        const { moves } = this.props;
        return (_a = this.generateMoves(moves)) === null || _a === void 0 ? void 0 : _a.map((m) => ReactDOMServer.renderToString(m));
    }
    render() {
        const { moves, movesPosition, stripClasses = false } = this.props;
        if (moves == null)
            return null;
        return stripClasses ? (this.generateMoves(moves)) : (React.createElement("div", { className: `pokemon-moves ${movesPosition}` }, this.generateMoves(moves)));
    }
}
export const Moves = connect((state) => ({
    style: state.style,
    customMoveMap: state.customMoveMap,
    customTypes: state.customTypes,
}))(MovesBase);
