import * as React from 'react';
import { BaseEditor } from 'components/BaseEditor';
import { listOfHotkeys } from 'utils';
import { Classes } from '@blueprintjs/core';
export class HotkeysEditor extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(BaseEditor, { icon: 'key-command', name: "Hotkeys" },
            React.createElement("ul", { style: { listStyleType: 'none', paddingLeft: '1rem', columnCount: 2, breakInside: 'avoid' }, className: "hotkey-list" }, listOfHotkeys.map((item) => {
                var _a;
                return (React.createElement("li", { key: item.key, style: { display: 'flex' } },
                    React.createElement("kbd", { style: { margin: '4px' }, className: Classes.CODE }, (_a = item === null || item === void 0 ? void 0 : item.label) !== null && _a !== void 0 ? _a : item.key),
                    React.createElement("div", { style: { margin: '4px' } }, item.comment)));
            }))));
    }
}
