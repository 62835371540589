import * as React from 'react';
import { connect } from 'react-redux';
import { movesByType, getListOfTypes } from 'utils';
import { Dialog, Intent, Button, Icon, Classes } from '@blueprintjs/core';
import { Move } from 'components/TeamPokemon/Moves';
import { editCustomMoveMap, deleteCustomMove, deleteCustomType, createCustomType, editCustomType, } from 'actions';
import { ErrorBoundary } from 'components';
import { TypesEditor } from './TypesEditor';
import { cx } from 'emotion';
export class MoveEditorBase extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            searchTerm: '',
            moveType: '',
            moveName: '',
        };
        this.moveFilter = (move, type, searchTerm) => {
            return (move.toLowerCase().includes(searchTerm.toLowerCase()) ||
                type.toLowerCase().includes(searchTerm.toLowerCase()));
        };
        this.onSearch = (e) => {
            this.setState({ searchTerm: e.target.value });
        };
    }
    getTypes() {
        const { customTypes } = this.props;
        return getListOfTypes(customTypes);
    }
    renderMoves(moves, isCustom = false) {
        const { searchTerm } = this.state;
        const { style, customMoveMap, customTypes } = this.props;
        const types = this.getTypes();
        const movesToDisplay = customMoveMap
            .filter((m) => this.moveFilter(m.move, m.type, searchTerm));
        const onChange = (move) => (e) => {
            this.props.editCustomMoveMap(e.target.value, move);
        };
        if (isCustom) {
            if (!Array.isArray(customMoveMap)) {
                return null;
            }
            return movesToDisplay
                .map(({ move, type, id }, index) => {
                return (React.createElement("div", { style: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '.25rem',
                        position: 'relative',
                    } },
                    React.createElement("div", { style: { width: '12rem', marginRight: '.5rem' } },
                        React.createElement(Move, { index: index, move: move, type: type, style: style, customTypes: customTypes })),
                    React.createElement("div", { className: Classes.SELECT, style: { width: '8rem' } },
                        React.createElement("select", { onChange: onChange(move), value: type }, types.map((opt) => (React.createElement("option", { key: opt, value: opt }, opt))))),
                    React.createElement(Icon, { onClick: (e) => this.props.deleteCustomMove(id), style: { color: 'red', position: 'absolute', cursor: 'pointer' }, icon: "trash" })));
            });
        }
        const prepared = Object.keys(moves).map((type) => {
            return moves[type]
                .sort()
                .filter((m) => this.moveFilter(m, type, searchTerm))
                .map((move, index) => (React.createElement("div", { style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '.25rem',
                } },
                React.createElement("div", { style: { width: '12rem', marginRight: '.5rem' } },
                    React.createElement(Move, { index: index, move: move, type: type, style: style, customTypes: customTypes })),
                React.createElement("div", { className: Classes.SELECT, style: { width: '8rem' } },
                    React.createElement("select", { onChange: onChange(move), value: type }, types.map((opt) => (React.createElement("option", { key: opt, value: opt }, opt))))))));
        });
        return prepared;
    }
    render() {
        const { isOpen, toggleDialog, style, customMoveMap, customTypes, createCustomType, deleteCustomType, } = this.props;
        const { moveType, moveName } = this.state;
        const types = this.getTypes();
        return (React.createElement(ErrorBoundary, null,
            React.createElement(Dialog, { icon: "edit", canOutsideClickClose: false, isOpen: isOpen, onClose: toggleDialog, className: `wide-dialog ${style.editorDarkMode ? Classes.DARK : ''}`, title: "Move Editor" },
                React.createElement("div", { className: cx(Classes.DIALOG_BODY, 'move-editor'), style: {
                        height: '800px',
                    } },
                    React.createElement("div", { style: {
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            border: '1px solid #ccc',
                            borderRadius: '.25rem',
                            padding: '0.5rem',
                            margin: '.5rem',
                        }, className: "add-move-wrapper" },
                        React.createElement("label", { style: { padding: '0.5rem' }, className: cx(Classes.INLINE) }, "Add A Move"),
                        React.createElement("input", { placeholder: "Move Name", style: { width: '10rem', margin: '0 .25rem' }, onChange: (e) => this.setState({ moveName: e.target.value }), value: moveName, className: Classes.INPUT, type: "text" }),
                        React.createElement("div", { className: Classes.SELECT, style: { width: '8rem', margin: '0 .25rem' } },
                            React.createElement("select", { onChange: (e) => this.setState({ moveType: e.target.value }), value: moveType }, types.map((opt) => (React.createElement("option", { key: opt, value: opt }, opt))))),
                        React.createElement(Button, { style: { margin: '0 .25rem' }, onClick: (e) => {
                                this.props.editCustomMoveMap(moveType, moveName);
                            }, intent: Intent.PRIMARY, disabled: !(moveType && moveName) }, "Add Move")),
                    React.createElement("div", { className: "moves-wrapper has-nice-scrollbars", style: {
                            borderRadius: '.25rem',
                            height: '88%',
                            padding: '0.5rem',
                            overflowY: 'scroll',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                        } },
                        React.createElement("div", { style: { width: '60%', borderRadius: '.25rem', margin: '4px' } },
                            React.createElement("div", { className: Classes.INPUT_GROUP, style: { width: '50%', margin: '0 auto', position: 'sticky' } },
                                React.createElement(Icon, { icon: "search" }),
                                React.createElement("input", { value: this.state.searchTerm, onInput: this.onSearch, className: Classes.INPUT, type: "search", dir: "auto" })),
                            this.renderMoves(customMoveMap, true),
                            this.renderMoves(movesByType)),
                        React.createElement("div", { style: {
                                width: '39%',
                                padding: '1rem',
                                borderRadius: '.25rem',
                                margin: '4px',
                            } },
                            React.createElement(TypesEditor, { editCustomType: editCustomType, customTypes: customTypes, createCustomType: createCustomType, deleteCustomType: deleteCustomType })))))));
    }
}
export const MoveEditor = connect((state) => ({
    game: state.game,
    style: state.style,
    customMoveMap: state.customMoveMap,
    customTypes: state.customTypes,
}), {
    editCustomMoveMap,
    deleteCustomMove,
    deleteCustomType,
    createCustomType,
    editCustomType,
}, null, { pure: false })(MoveEditorBase);
