var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import { formatBallText, getGameGeneration, getPokemonImage, stripURLCSS, typeToColor } from 'utils';
import { css } from 'emotion';
import * as Mustache from 'mustache';
import { ErrorBoundary, PokemonIconPlain } from 'components';
import { uniq } from 'ramda';
import { MovesBase } from './Moves';
import { GenderElementReact } from 'components/Shared';
import { CheckpointsDisplay } from 'components/Result';
import { linkedPokemonSelector } from 'selectors';
import { PokemonItem } from './PokemonItem';
import { PokemonPokeball } from './PokemonPokeball';
export const teamPokemon = (options) => css ``;
export const teamPokemonImage = (options) => {
    var _a, _b;
    return css `
    height: ${(_a = options.height) !== null && _a !== void 0 ? _a : '8rem'};
    width: ${(_b = options.width) !== null && _b !== void 0 ? _b : '8rem'};
`;
};
const LinkedPokemon = ({ linkedPokemon, style, }) => {
    return linkedPokemon ? (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: "pokemon-linked" },
            style.linkedPokemonText,
            ' ',
            linkedPokemon.nickname || linkedPokemon.species,
            React.createElement(PokemonIconPlain, Object.assign({}, linkedPokemon))))) : null;
};
const TeamCheckpointsDisplay = ({ game, pokemon, style }) => {
    return React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: 'flex flex-wrap', style: { maxWidth: '14rem' } },
            React.createElement(CheckpointsDisplay, { className: 'pokemon-checkpoint', game: game, clearedCheckpoints: pokemon.checkpoints, style: style })));
};
export function TeamPokemon({ pokemon, options, customCSS, customHTML }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const style = useSelector((state) => state.style);
    const game = useSelector((state) => state.game);
    const linkedPokemon = useSelector(linkedPokemonSelector(pokemon));
    const name = game.name;
    const editor = useSelector((state) => state.editor);
    const [image, setImage] = React.useState('');
    const customMoveMap = useSelector((state) => state.customMoveMap);
    const customTypes = useSelector((state) => state.customTypes);
    const teamHTML = style.customTeamHTML;
    React.useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            const newImage = yield getPokemonImage({
                customImage: pokemon.customImage,
                forme: pokemon.forme,
                species: pokemon.species,
                shiny: pokemon.shiny,
                style: style,
                name: name,
                editor: editor,
                gender: pokemon.gender,
                egg: pokemon.egg,
            });
            setImage(newImage);
        }))();
    }, [pokemon.species, pokemon.customImage, pokemon.forme, pokemon.shiny, style, name, editor, pokemon.gender, pokemon.egg]);
    const classes = {
        teamPokemon: teamPokemon(options),
        teamPokemonImage: teamPokemonImage(options),
    };
    const pokemonImage = stripURLCSS(image);
    const pokemonIcon = (React.createElement(PokemonIconPlain, { selectedId: '', onClick: () => { }, imageStyle: {}, species: pokemon === null || pokemon === void 0 ? void 0 : pokemon.species, id: pokemon === null || pokemon === void 0 ? void 0 : pokemon.id, style: { cursor: 'pointer' }, forme: pokemon === null || pokemon === void 0 ? void 0 : pokemon.forme, shiny: pokemon === null || pokemon === void 0 ? void 0 : pokemon.shiny, gender: pokemon === null || pokemon === void 0 ? void 0 : pokemon.gender, egg: pokemon === null || pokemon === void 0 ? void 0 : pokemon.egg, customIcon: pokemon === null || pokemon === void 0 ? void 0 : pokemon.customIcon }));
    console.log(linkedPokemon, pokemon === null || pokemon === void 0 ? void 0 : pokemon.linkedTo);
    const moves = (_a = pokemon === null || pokemon === void 0 ? void 0 : pokemon.moves) === null || _a === void 0 ? void 0 : _a.map((move, index) => {
        return { [`move${index + 1}`]: move };
    });
    const view = Object.assign(Object.assign(Object.assign({}, pokemon), (moves !== null && moves !== void 0 ? moves : {})), { typesFiltered: uniq((_b = pokemon.types) !== null && _b !== void 0 ? _b : []), image: pokemonImage, type1: (_c = pokemon === null || pokemon === void 0 ? void 0 : pokemon.types) === null || _c === void 0 ? void 0 : _c[0], type2: (_d = pokemon === null || pokemon === void 0 ? void 0 : pokemon.types) === null || _d === void 0 ? void 0 : _d[1], type1Color: typeToColor((_f = (_e = pokemon === null || pokemon === void 0 ? void 0 : pokemon.types) === null || _e === void 0 ? void 0 : _e[0]) !== null && _f !== void 0 ? _f : 'Normal'), type2Color: typeToColor((_h = (_g = pokemon === null || pokemon === void 0 ? void 0 : pokemon.types) === null || _g === void 0 ? void 0 : _g[1]) !== null && _h !== void 0 ? _h : 'Normal'), pokeball: pokemon.pokeball ?
            `icons/pokeball/${formatBallText((pokemon === null || pokemon === void 0 ? void 0 : pokemon.pokeball) || 'None')}.png` : undefined, pokeballComponent: ReactDOMServer.renderToString(React.createElement(PokemonPokeball, { pokemon: pokemon, style: style, customTypes: customTypes })), item: pokemon.item ? `icons/hold-item/${(pokemon.item || '')
            .toLowerCase()
            .replace(/\'/g, '')
            .replace(/\s/g, '-')}.png` : undefined, itemComponent: ReactDOMServer.renderToString(React.createElement(PokemonItem, { pokemon: pokemon, style: style, customTypes: customTypes })), icon: ReactDOMServer.renderToString(pokemonIcon), checkpoints: ReactDOMServer.renderToString(React.createElement("div", null)), genderSymbol: ReactDOMServer.renderToString(React.createElement(GenderElementReact, { gender: pokemon === null || pokemon === void 0 ? void 0 : pokemon.gender })), notes: (_j = pokemon.notes) !== null && _j !== void 0 ? _j : '', linkedPokemon: ReactDOMServer.renderToString(React.createElement(LinkedPokemon, { style: style, linkedPokemon: linkedPokemon })), linkedPokemonData: linkedPokemon !== null && linkedPokemon !== void 0 ? linkedPokemon : null, extraData: '', movesColored: ReactDOMServer.renderToString(React.createElement(MovesBase, { style: style, customMoveMap: customMoveMap, customTypes: customTypes, generation: getGameGeneration(name), moves: pokemon.moves, movesPosition: style.movesPosition, stripClasses: true })), movesColoredWithClasses: ReactDOMServer.renderToString(React.createElement(MovesBase, { style: style, customMoveMap: customMoveMap, customTypes: customTypes, generation: getGameGeneration(name), moves: pokemon.moves, movesPosition: style.movesPosition })) });
    console.log(view.pokeball, view.item);
    const CSS = (React.createElement("style", null, `
            ${customCSS}
        `));
    const teamViewHTML = customHTML !== null && customHTML !== void 0 ? customHTML : teamHTML;
    const html = teamViewHTML
        .replace(/\{{genderSymbol}}/g, view.genderSymbol)
        .replace(/\{{icon}}/g, view.icon)
        .replace(/\{{linkedPokemon}}/g, view.linkedPokemon)
        .replace(/\{{notes}}/g, view.notes)
        .replace(/\{{itemComponent}}/g, view.itemComponent)
        .replace(/\{{pokeballComponent}}/g, view.pokeballComponent)
        .replace(/\{{movesColoredWithClasses}}/g, view.movesColoredWithClasses)
        .replace(/\{{checkpoints}}/g, view.checkpoints)
        .replace(/\{{movesColored}}/g, view.movesColored);
    if (teamViewHTML) {
        return (React.createElement(ErrorBoundary, null,
            CSS,
            React.createElement("div", { dangerouslySetInnerHTML: { __html: Mustache.render(html, view) } })));
    }
    return (React.createElement(React.Fragment, null,
        CSS,
        React.createElement("div", { "data-testid": "team-pokemon", className: classes.teamPokemon },
            React.createElement("div", { className: classes.teamPokemonImage, style: {
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: image,
                } }))));
}
