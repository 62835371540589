import { REDO_EDITOR_HISTORY, UNDO_EDITOR_HISTORY, UPDATE_EDITOR_HISTORY, REPLACE_STATE, } from 'actions';
import { take } from 'ramda';
const initial = {
    past: [],
    present: undefined,
    future: [],
    lastRevisionType: 'update',
};
export function editorHistory(state = initial, action) {
    switch (action.type) {
        case UPDATE_EDITOR_HISTORY:
            const { present, future, past } = state;
            if (action.present == null) {
                return state;
            }
            return {
                past: present == null ? past : [...take(50, past), present],
                present: action.present,
                future: [],
                lastRevisionType: 'update',
            };
        case UNDO_EDITOR_HISTORY:
            const undo = () => {
                const { past, present, future } = state;
                const newPast = past.slice(0, past.length - 1);
                return {
                    past: newPast,
                    present: action.present,
                    future: [present, ...future],
                    lastRevisionType: 'undo',
                };
            };
            return undo();
        case REDO_EDITOR_HISTORY:
            const redo = () => {
                const { past, present, future } = state;
                const next = future[0];
                const newFuture = future.slice(1);
                return {
                    past: [...past, present],
                    present: action.present,
                    future: newFuture,
                    lastRevisionType: 'redo',
                };
            };
            return redo();
        case REPLACE_STATE:
            return initial;
        default:
            return state;
    }
}
