import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import createHistory from 'history/createBrowserHistory';
import { persistCombineReducers, persistStore, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { version } from 'package';
import { reducers } from '../reducers';
const migrations = {
    '0.0.6-beta': (state) => {
        return Object.assign(Object.assign({}, state), { box: undefined });
    },
    '0.0.11-beta': (state) => {
        return Object.assign(Object.assign({}, state), { trainer: Object.assign(Object.assign({}, state.trainer), { badges: [] }) });
    },
    '1.1.0': (state) => (Object.assign(Object.assign({}, state), { customMoveMap: [] })),
    '1.1.1': (state) => (Object.assign(Object.assign({}, state), { customMoveMap: [] })),
    '1.1.2': (state) => (Object.assign(Object.assign({}, state), { customMoveMap: [] })),
    '1.1.3': (state) => (Object.assign(Object.assign({}, state), { customMoveMap: [] })),
    '1.1.4': (state) => (Object.assign(Object.assign({}, state), { customMoveMap: [] })),
    '1.6.0': (state) => (Object.assign(Object.assign({}, state), { box: state.box.map((box, index) => {
            return Object.assign(Object.assign({}, box), { position: index, id: index });
        }) })),
    '1.7.1': (state) => (Object.assign(Object.assign({}, state), { style: Object.assign(Object.assign({}, state.style), { statsOptions: Object.assign(Object.assign({}, state.style.statsOptions), { averageLevelDetailed: false }) }) })),
    '1.15.1': (state) => (Object.assign(Object.assign({}, state), { excludedAreas: [] })),
    '1.16.0': (state) => (Object.assign(Object.assign({}, state), { customAreas: [] })),
};
const config = {
    key: 'root',
    blacklist: ['router', 'editorHistory'],
    storage,
    version,
    migrations: createMigrate(migrations, { debug: true }),
};
export const history = createHistory();
export const persistReducers = persistCombineReducers(config, reducers);
export const middlewares = [];
if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'production') {
}
else {
    const loggerMiddleware = createLogger();
    middlewares.push(loggerMiddleware);
}
export const store = createStore(persistReducers, applyMiddleware(...middlewares));
export const persistor = persistStore(store, null);
