import { NEW_NUZLOCKE, DELETE_NUZLOCKE, SWITCH_NUZLOCKE, UPDATE_NUZLOCKE, UPDATE_SWITCH_NUZLOCKE, } from 'actions';
const uuid = require('uuid');
export function nuzlockes(state = {
    currentId: '',
    saves: [],
}, action) {
    switch (action.type) {
        case NEW_NUZLOCKE:
            const id = uuid();
            return Object.assign(Object.assign({}, state), { currentId: id, saves: [
                    ...state.saves,
                    {
                        id,
                        data: (action === null || action === void 0 ? void 0 : action.data) || null,
                        isCopy: (action === null || action === void 0 ? void 0 : action.isCopy) || false,
                    },
                ] });
        case DELETE_NUZLOCKE:
            return Object.assign(Object.assign({}, state), { saves: state.saves.filter((s) => s.id !== action.id) });
        case SWITCH_NUZLOCKE:
            return Object.assign(Object.assign({}, state), { currentId: action.id });
        case UPDATE_SWITCH_NUZLOCKE:
            return Object.assign(Object.assign({}, state), { currentId: action.newId, saves: [
                    ...state.saves.filter((s) => s.id !== action.id),
                    {
                        id: action.id,
                        data: action.data,
                    },
                ] });
        case UPDATE_NUZLOCKE:
            return Object.assign(Object.assign({}, state), { saves: [
                    ...state.saves.filter((s) => s.id !== action.id),
                    {
                        id: action.id,
                        data: action.data,
                    },
                ] });
        default:
            return state;
    }
}
