import * as React from 'react';
import { BaseEditor } from 'components/BaseEditor';
import { connect } from 'react-redux';
import { Checkbox, Switch, Button, Intent, Classes } from '@blueprintjs/core';
import { editStyle, addStat, deleteStat, editStat } from 'actions';
import { cx } from 'emotion';
import { ErrorBoundary } from 'components';
const sortById = (a, b) => {
    if (a.id > b.id)
        return 1;
    if (b.id > a.id)
        return -1;
    return 0;
};
export class StatsEditorBase extends React.Component {
    constructor() {
        super(...arguments);
        this.onChange = (stat, use) => (e) => {
            var _a, _b;
            const { editStat } = this.props;
            use === 'key'
                ? editStat(stat === null || stat === void 0 ? void 0 : stat.id, e.target.value, (_a = stat.value) !== null && _a !== void 0 ? _a : '')
                : editStat(stat === null || stat === void 0 ? void 0 : stat.id, (_b = stat.key) !== null && _b !== void 0 ? _b : '', e.target.value);
        };
    }
    render() {
        var _a;
        const { style, pokemon, editStyle } = this.props;
        const { addStat, editStat, deleteStat } = this.props;
        const stats = style === null || style === void 0 ? void 0 : style.statsOptions;
        return (React.createElement(BaseEditor, { icon: 'chart', name: "Stats" },
            React.createElement(ErrorBoundary, null,
                React.createElement("div", null,
                    React.createElement(Checkbox, { checked: style.displayStats, name: "displayStats", label: "Display Stats", onChange: (e) => editStyle({ [e.target.name]: e.target.checked }) })),
                React.createElement("ul", { style: {
                        listStyleType: 'none',
                        padding: 0,
                        marginLeft: '1rem',
                        opacity: style.displayStats ? 1 : 0.3,
                    } },
                    React.createElement("li", null,
                        React.createElement(Switch, { checked: stats === null || stats === void 0 ? void 0 : stats.averageLevel, name: "averageLevel", label: "Average Level", onChange: (e) => editStyle({
                                statsOptions: Object.assign(Object.assign({}, stats), { [e.target.name]: e.target.checked }),
                            }) })),
                    React.createElement("li", null,
                        React.createElement(Switch, { checked: stats === null || stats === void 0 ? void 0 : stats.averageLevelDetailed, name: "averageLevelDetailed", label: "Average Level (Detailed)", onChange: (e) => editStyle({
                                statsOptions: Object.assign(Object.assign({}, stats), { [e.target.name]: e.target.checked }),
                            }) })),
                    React.createElement("li", null,
                        React.createElement(Switch, { checked: stats === null || stats === void 0 ? void 0 : stats.mostCommonKillers, name: "mostCommonKillers", label: "Most Common Killers", onChange: (e) => editStyle({
                                statsOptions: Object.assign(Object.assign({}, stats), { [e.target.name]: e.target.checked }),
                            }) })),
                    React.createElement("li", null,
                        React.createElement(Switch, { checked: stats === null || stats === void 0 ? void 0 : stats.mostCommonTypes, name: "mostCommonTypes", label: "Most Common Types", onChange: (e) => editStyle({
                                statsOptions: Object.assign(Object.assign({}, stats), { [e.target.name]: e.target.checked }),
                            }) })),
                    React.createElement("li", null,
                        React.createElement(Switch, { checked: stats === null || stats === void 0 ? void 0 : stats.shiniesCaught, name: "shiniesCaught", label: "Shinies Caught", onChange: (e) => editStyle({
                                statsOptions: Object.assign(Object.assign({}, stats), { [e.target.name]: e.target.checked }),
                            }) })), (_a = this.props.stats) === null || _a === void 0 ? void 0 :
                    _a.sort(sortById).map((stat) => (React.createElement("li", { style: { display: 'flex', alignItems: 'center' }, className: Classes.INPUT_GROUP, key: stat.id },
                        React.createElement("input", { onChange: this.onChange(stat, 'key'), style: { margin: '4px' }, className: Classes.INPUT, type: "text", placeholder: "custom label", value: stat.key }),
                        React.createElement("input", { onChange: this.onChange(stat, 'value'), style: { margin: '4px' }, className: Classes.INPUT, type: "text", placeholder: "custom value", value: stat.value }),
                        React.createElement(Button, { icon: "trash", intent: Intent.DANGER, style: { margin: '6px', fontSize: '80%' }, className: cx(Classes.MINIMAL, Classes.BUTTON), onClick: () => (stat === null || stat === void 0 ? void 0 : stat.id) && deleteStat(stat === null || stat === void 0 ? void 0 : stat.id) })))),
                    React.createElement("li", null,
                        React.createElement(Button, { icon: "plus", style: { margin: '4px', fontSize: '80%' }, onClick: () => addStat({ key: '', value: '' }), intent: Intent.SUCCESS }, "Add Custom Stat"))))));
    }
}
export const StatsEditor = connect((state) => ({
    pokemon: state.pokemon,
    style: state.style,
    stats: state.stats,
}), {
    editStyle,
    addStat,
    deleteStat,
    editStat,
})(StatsEditorBase);
