import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrainerInfoEditField } from 'components/TrainerEditor/TrainerInfoEditField';
import { editTrainer } from 'actions';
import { Popover, Menu, Button, Position, Checkbox, Dialog, Classes } from '@blueprintjs/core';
import { CheckpointsEditor } from './CheckpointsEditor';
import { cx } from 'emotion';
import * as Styles from './style';
export function CheckpointsInputList({ onChange, checkpointsObtained, toggle, buttonText, }) {
    const checkpoints = useSelector(state => state === null || state === void 0 ? void 0 : state.checkpoints);
    return React.createElement(Popover, { minimal: true, content: React.createElement(Menu, null,
            checkpoints.length === 0 && React.createElement("div", { style: { width: '200px' } }, "Select a game or configure custom checkpoints to see them here!"),
            Array.isArray(checkpoints) && (checkpoints === null || checkpoints === void 0 ? void 0 : checkpoints.map((badge) => (React.createElement(Checkbox, { onChange: (e) => {
                    if (!e.target.checked ||
                        checkpointsObtained.some((b) => b.name === badge.name)) {
                        const badges = checkpointsObtained.filter((b) => b.name !== badge.name);
                        onChange(badges);
                    }
                    else {
                        const badges = [...checkpointsObtained, badge];
                        onChange(badges);
                    }
                }, checked: checkpointsObtained.some((b) => b.name === badge.name), key: badge.name, label: badge.name })))),
            toggle && React.createElement(Button, { onClick: toggle, minimal: true }, "Customize Checkpoints")), position: Position.BOTTOM },
        React.createElement(Button, { fill: true, style: {
                borderRadius: 0,
            } }, buttonText !== null && buttonText !== void 0 ? buttonText : 'Select Checkpoints'));
}
export function BadgeInput({ onChange, checkpointsCleared, }) {
    var _a;
    const [isOpen, setIsOpen] = React.useState(false);
    const checkpoints = useSelector(state => state.checkpoints);
    const trainer = useSelector(state => state.trainer);
    const style = useSelector(state => state.style);
    const onChangeInternal = (badges) => dispatch(editTrainer({ badges }));
    const onChangeUseable = onChange !== null && onChange !== void 0 ? onChange : onChangeInternal;
    const dispatch = useDispatch();
    const toggle = () => setIsOpen(!isOpen);
    const checkpointsObtained = (_a = checkpointsCleared !== null && checkpointsCleared !== void 0 ? checkpointsCleared : trainer.badges) !== null && _a !== void 0 ? _a : [];
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { isOpen: isOpen, onClose: toggle, className: cx(Classes.DIALOG, {
                [Classes.DARK]: style.editorDarkMode,
            }), canOutsideClickClose: false, title: "Checkpoints Editor", icon: "badge", style: { width: '60rem' } },
            React.createElement("div", { className: cx(Classes.DIALOG_BODY, Styles.checkpointsEditor, 'has-nice-scrollbars') },
                React.createElement(CheckpointsEditor, { checkpoints: checkpoints }))),
        React.createElement(TrainerInfoEditField, { "data-testid": 'badge-input', label: "Checkpoints", name: "badges", placeholder: "...", value: '', element: (inputProps) => (React.createElement("div", null,
                React.createElement(CheckpointsInputList, { onChange: onChangeUseable, toggle: toggle, checkpointsObtained: checkpointsObtained }))) })));
}
