import { CHANGE_EDITOR_SIZE, TOGGLE_TEMTEM_MODE, TOGGLE_MOBILE_RESULT_VIEW, SET_EDITOR_HISTORY_ENABLED, } from 'actions';
export function editor(state = {
    minimized: false,
    temtemMode: false,
    showResultInMobile: false,
    monsterType: 'Pokémon',
    editorHistoryDisabled: true,
}, action) {
    switch (action.type) {
        case CHANGE_EDITOR_SIZE:
            return {
                minimized: action.mode,
            };
        case TOGGLE_TEMTEM_MODE:
            return Object.assign(Object.assign({}, state), { temtemMode: !state.temtemMode, monsterType: !state.temtemMode ? 'TemTem' : 'Pokémon' });
        case TOGGLE_MOBILE_RESULT_VIEW:
            return Object.assign(Object.assign({}, state), { showResultInMobile: !state.showResultInMobile });
        case SET_EDITOR_HISTORY_ENABLED:
            return Object.assign(Object.assign({}, state), { editorHistoryDisabled: action.enabled });
        default:
            return state;
    }
}
