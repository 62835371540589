var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Alert, Intent, Classes } from '@blueprintjs/core';
const trash = require('assets/img/trash.png').default;
export function DeleteAlert(_a) {
    var { warningText = 'This will permanently delete all your local storage data, with no way to retrieve it. Are you sure you want to do this?' } = _a, props = __rest(_a, ["warningText"]);
    const style = useSelector((state) => state.style);
    return (React.createElement(Alert, Object.assign({ cancelButtonText: "Nevermind", confirmButtonText: "Delete Anyway", className: style.editorDarkMode ? Classes.DARK : '', style: { maxWidth: '600px' }, intent: Intent.DANGER }, props),
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement("img", { style: { height: '10rem' }, src: trash, alt: "Sad Trubbish" }),
            React.createElement("p", { style: { fontSize: '1.2rem', padding: '1rem' } }, warningText))));
}
