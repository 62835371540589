import * as React from 'react';
import { Button, Dialog, Intent, TextArea, Icon, Classes } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { editRule, addRule, deleteRule, resetRules } from 'actions';
import './RulesEditor.css';
export class RulesEditor extends React.Component {
    renderRules() {
        return this.props.rules.map((rule, index) => (React.createElement("li", { className: "rules-list-item", key: index },
            React.createElement("div", { className: "rule-no" }, index + 1),
            React.createElement(TextArea, { defaultValue: rule, className: Classes.FILL, onChange: (e) => this.props.editRule(index, e.target.value), dir: "auto" }),
            React.createElement("div", { onClick: () => this.props.deleteRule(index), onKeyPress: () => this.props.deleteRule(index), role: 'none', className: "rule-delete", title: "Delete Rule" },
                React.createElement(Icon, { intent: Intent.DANGER, style: { cursor: 'pointer' }, icon: 'trash' })))));
    }
    renderButtons() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: (_) => this.props.addRule(), intent: Intent.PRIMARY }, "Add Rule"),
            React.createElement(Button, { style: { marginLeft: '1rem' }, onClick: () => {
                    this.props.resetRules();
                    this.forceUpdate();
                }, intent: Intent.WARNING }, "Reset Rules")));
    }
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement("ul", { style: {
                    listStyleType: 'none',
                    margin: '.5rem',
                    padding: '0',
                } }, this.renderRules()),
            this.renderButtons()));
    }
}
export const RulesEditorDialogBase = (props) => {
    return (React.createElement(Dialog, { isOpen: props.isOpen, onClose: props.onClose, className: `rules-editor-dialog ${props.style.editorDarkMode ? Classes.DARK : ''}`, title: "Rules Editor", icon: "edit" },
        React.createElement("div", { className: Classes.DIALOG_BODY },
            React.createElement(RulesEditor, { rules: props.rules, editRule: props.editRule, addRule: props.addRule, deleteRule: props.deleteRule, resetRules: props.resetRules }))));
};
export const RulesEditorDialog = connect((state) => ({
    rules: state.rules,
    style: state.style,
}), { editRule, addRule, deleteRule, resetRules })(RulesEditorDialogBase);
