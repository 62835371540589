import * as React from 'react';
export class Gender {
    static isMale(gender) {
        if (gender === 'm' || gender === 'Male')
            return true;
        return false;
    }
    static isFemale(gender) {
        if (gender === 'f' || gender === 'Female')
            return true;
        return false;
    }
    static isGenderless(gender) {
        if (gender === 'genderless' || gender == null)
            return true;
        return false;
    }
}
export const GenderElement = (gender) => {
    if (gender === 'Male' || gender === 'm') {
        return React.createElement("span", { "data-testid": "gender-text", className: "pokemon-gender gender-color-male" }, "\u2642");
    }
    else if (gender === 'Female' || gender === 'f') {
        return React.createElement("span", { "data-testid": "gender-text", className: "pokemon-gender gender-color-female" }, "\u2640");
    }
    else {
        return null;
    }
};
export function GenderElementReact({ gender }) {
    return GenderElement(gender);
}
