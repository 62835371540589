var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { connect } from 'react-redux';
import { getBackgroundGradient, typeToColor, getPokemonImage, Generation, getGameGeneration, getContrastColor, TemplateName, } from 'utils';
import { GenderElement, ErrorBoundary } from 'components/Shared';
import { selectPokemon } from 'actions';
import { Moves } from './Moves';
import { css, cx } from 'emotion';
import { PokemonIcon } from 'components/PokemonIcon';
import { getMetLocationString } from './getMetLocationString';
import { CheckpointsDisplay } from 'components/Result';
import { PokemonImage } from 'components/Shared/PokemonImage';
import { PokemonItem } from './PokemonItem';
import { PokemonPokeball } from './PokemonPokeball';
import { PokemonFriendship } from './PokemonFriendship';
function getSpriteStyle({ spritesMode, scaleSprites, teamImages }) {
    if (spritesMode) {
        if (scaleSprites) {
            return {
                backgroundSize: 'auto',
                backgroundRepeat: 'no-repeat',
            };
        }
        else {
            return {
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            };
        }
    }
    if (teamImages === 'dream world') {
        return {
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        };
    }
    else {
        return {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };
    }
}
export class TeamPokemonInfo extends React.PureComponent {
    render() {
        var _a, _b;
        const { pokemon, style, customTypes, linkedPokemon, game } = this.props;
        const generation = getGameGeneration(game.name);
        if (!pokemon) {
            return null;
        }
        const accentColor = style ? style.accentColor : '#111111';
        const isCardsTheme = style.template === TemplateName.Cards;
        const isCompactTheme = style.template === TemplateName.Compact ||
            style.template === TemplateName.CompactWithIcons;
        const getTypeOrNone = () => {
            if (pokemon) {
                if (pokemon.types) {
                    if (pokemon.types.length) {
                        return pokemon === null || pokemon === void 0 ? void 0 : pokemon.types[1];
                    }
                }
            }
            return 'None';
        };
        const stat = (stat, statName) => (React.createElement("div", { className: `pokemon-stat-${statName}`, style: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin: '0 2px',
            } },
            React.createElement("div", null, statName),
            React.createElement("div", null, stat)));
        return (React.createElement(ErrorBoundary, null,
            React.createElement("div", { className: "pokemon-info", style: {
                    backgroundColor: isCardsTheme ? undefined : accentColor,
                    backgroundImage: isCompactTheme
                        ? getBackgroundGradient(pokemon.types != null && !pokemon.egg ? pokemon === null || pokemon === void 0 ? void 0 : pokemon.types[1] : 'Normal', pokemon.types != null && !pokemon.egg ? pokemon === null || pokemon === void 0 ? void 0 : pokemon.types[0] : 'Normal', customTypes)
                        : undefined,
                    color: isCompactTheme
                        ? getContrastColor(typeToColor(getTypeOrNone(), customTypes))
                        : getContrastColor(accentColor),
                } },
                React.createElement("div", { className: "pokemon-info-inner" },
                    React.createElement("div", { className: "pokemon-main-info" },
                        React.createElement("span", { style: { margin: '0.25rem 0 0' }, className: "pokemon-nickname" }, pokemon.nickname),
                        React.createElement("span", { className: "pokemon-name" },
                            !pokemon.egg ? pokemon.species : '???',
                            pokemon.item && style.itemStyle === 'text'
                                ? ` @ ${pokemon.item}`
                                : null),
                        Boolean(pokemon.alpha) && React.createElement("span", { className: "pokemon-alpha" },
                            React.createElement("img", { alt: 'alpha', style: { height: '1rem' }, src: 'icons/alpha-icon.png' })),
                        Boolean(pokemon.teraType && pokemon.teraType !== 'None') && React.createElement("span", { className: "pokemon-teratype" },
                            React.createElement("img", { alt: `Tera: ${pokemon.teraType}`, style: { height: '1rem' }, src: `icons/tera/${(_a = pokemon.teraType) === null || _a === void 0 ? void 0 : _a.toLowerCase()}.png` })),
                        GenderElement(pokemon.gender),
                        pokemon.level ? (React.createElement("span", { className: "pokemon-level" },
                            "lv. ",
                            pokemon.level)) : null),
                    React.createElement("div", { className: "pokemon-met" },
                        getMetLocationString({
                            poke: pokemon,
                            oldMetLocationFormat: style.oldMetLocationFormat,
                        }),
                        pokemon.pokeball && style.pokeballStyle === 'text'
                            ? ` (in ${pokemon.pokeball})`
                            : null),
                    pokemon.nature && pokemon.nature !== 'None' ? (React.createElement("div", { className: "pokemon-nature" },
                        React.createElement("strong", null, pokemon.nature),
                        " nature")) : null,
                    pokemon.ability ? (React.createElement("div", { className: "pokemon-ability" }, pokemon.ability)) : null,
                    pokemon.notes && (React.createElement("div", { className: "pokemon-notes", dangerouslySetInnerHTML: { __html: pokemon.notes } })),
                    pokemon.checkpoints && (React.createElement("div", { className: 'flex flex-wrap pokemon-checkpoints', style: { maxWidth: '14rem' } },
                        React.createElement(CheckpointsDisplay, { className: 'pokemon-checkpoint', game: game, clearedCheckpoints: pokemon.checkpoints, style: style }))),
                    style.displayExtraData && pokemon.extraData ? (React.createElement("div", { className: 'pokemon-extra-data', style: {
                            display: 'flex',
                            fontSize: '12px',
                            width: '255px',
                        } }, generation === Generation.Gen1 ?
                        React.createElement(React.Fragment, null,
                            stat(pokemon.extraData['currentHp'], 'HP'),
                            stat(pokemon.extraData['attack'], 'ATK'),
                            stat(pokemon.extraData['defense'], 'DEF'),
                            stat(pokemon.extraData['special'], 'SPC'),
                            stat(pokemon.extraData['speed'], 'SPE'))
                        :
                            React.createElement(React.Fragment, null,
                                stat(pokemon.extraData['currentHp'], 'HP'),
                                stat(pokemon.extraData['attack'], 'ATK'),
                                stat(pokemon.extraData['defense'], 'DEF'),
                                stat(pokemon.extraData['specialAttack'], 'SPATK'),
                                stat(pokemon.extraData['specialDefense'], 'SPDEF'),
                                stat(pokemon.extraData['speed'], 'SPE')))) : null,
                    style.displayExtraData && pokemon.extraData ? (React.createElement(PokemonFriendship, { friendship: (_b = pokemon.extraData) === null || _b === void 0 ? void 0 : _b['friendship'] })) : null,
                    linkedPokemon && (React.createElement("div", { className: "pokemon-linked" },
                        style.linkedPokemonText,
                        ' ',
                        linkedPokemon.nickname || linkedPokemon.species,
                        React.createElement(PokemonIcon, Object.assign({}, linkedPokemon))))),
                style.showPokemonMoves && !pokemon.egg ? (React.createElement(Moves, { generation: this.props.generation, moves: pokemon.moves, movesPosition: style.movesPosition })) : null)));
    }
}
export function TeamPokemonBaseMinimal(props) {
    var _a;
    const { pokemon, style, game, editor } = props;
    const poke = pokemon;
    if (!pokemon) {
        return React.createElement("div", null, "A Pok\u00E9mon could not be rendered.");
    }
    return (React.createElement("div", { className: "pokemon-container minimal", style: { color: getContrastColor((_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.bgColor) } },
        React.createElement(PokemonImage, { species: poke === null || poke === void 0 ? void 0 : poke.species, gender: poke === null || poke === void 0 ? void 0 : poke.gender, forme: poke === null || poke === void 0 ? void 0 : poke.forme, customImage: poke === null || poke === void 0 ? void 0 : poke.customImage, style: style, editor: editor, shiny: poke === null || poke === void 0 ? void 0 : poke.shiny, egg: poke === null || poke === void 0 ? void 0 : poke.egg, name: game.name }, (backgroundImage) => {
            return React.createElement("div", { style: Object.assign({ backgroundImage }, props.spriteStyle), className: `pokemon-image ${((poke === null || poke === void 0 ? void 0 : poke.species) || 'missingno').toLowerCase()} ${props.style.imageStyle === 'round' ? 'round' : 'square'}` });
        }),
        React.createElement("div", { className: "pokemon-info" },
            React.createElement("div", { className: "pokemon-info-inner" },
                React.createElement("span", { className: "pokemon-nickname" }, pokemon.nickname),
                React.createElement("span", { className: "pokemon-name" }, !pokemon.egg ? pokemon.species : '???'),
                pokemon.level ? (React.createElement("span", { className: "pokemon-level" },
                    "lv. ",
                    pokemon.level)) : null))));
}
export class TeamPokemonBase extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            image: '',
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            const { pokemon, style, game, editor, } = this.props;
            const poke = pokemon;
            const image = yield getPokemonImage({
                customImage: poke === null || poke === void 0 ? void 0 : poke.customImage,
                forme: poke === null || poke === void 0 ? void 0 : poke.forme,
                species: poke === null || poke === void 0 ? void 0 : poke.species,
                shiny: poke === null || poke === void 0 ? void 0 : poke.shiny,
                style: style,
                name: game.name,
                editor: editor,
                gender: poke === null || poke === void 0 ? void 0 : poke.gender,
                egg: poke === null || poke === void 0 ? void 0 : poke.egg,
            });
            this.setState({ image });
        });
    }
    render() {
        const { pokemon, style, game, selectPokemon, editor, customTypes, linkedPokemon, } = this.props;
        const poke = pokemon;
        const showdown = false;
        if (!poke) {
            return React.createElement("div", null, "A Pok\u00E9mon could not be rendered.");
        }
        const getFirstType = poke.types ? poke.types[0] : 'Normal';
        const getSecondType = poke.types ? poke.types[1] : 'Normal';
        const { spritesMode, scaleSprites, teamImages } = style;
        const spriteStyle = getSpriteStyle({ spritesMode, scaleSprites, teamImages });
        const addProp = (item) => {
            const propName = `data-${item.toLowerCase()}`;
            if (item === 'type')
                return { [propName]: poke[item].join(' ') };
            if (poke[item] == null || poke[item] === '')
                return {};
            return { [propName]: poke[item].toString() };
        };
        const dataKeys = [
            'id',
            'position',
            'species',
            'nickname',
            'status',
            'gender',
            'level',
            'metLevel',
            'nature',
            'ability',
            'item',
            'types',
            'forme',
            'moves',
            'causeOfDeath',
            'shiny',
            'champion',
            'num',
            'wonderTradedFor',
            'mvp',
            'customImage',
        ].sort();
        const data = dataKeys.reduce((prev, curr) => {
            return Object.assign(Object.assign({}, prev), addProp(curr));
        }, {});
        if (this.props.style.minimalTeamLayout) {
            return (React.createElement(TeamPokemonBaseMinimal, { selectPokemon: selectPokemon, style: style, game: game, spriteStyle: spriteStyle, pokemon: poke, editor: editor, customTypes: customTypes, linkedPokemon: linkedPokemon }));
        }
        const mvpLabelStyle = {
            base: css `
                position: absolute;
                top: 0;
                left: calc(50% - 3rem);
                width: 6rem;
                background: #e2d5a9;
                border: 1px solid #eee;
                padding: 2px 0.5rem;
                text-align: center;
                border-radius: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #111;
                font-size: 90%;
                z-index: 10;
            `,
            Cards: css `
                top: 2.25rem;
            `,
            Hexagons: css `
                top: 18px;
                transform: scale(0.8);
            `,
            Generations: css `
                top: -4px;
            `,
        };
        const itemLabelStyle = {
            base: css `
                background: #111;
                border: 5px solid white;
                bottom: 0;
                height: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 12px;
                padding: 0.5rem;
                position: absolute;
                width: 3rem;
                z-index: 10;
            `,
            ['round']: css `
                border-radius: 50%;
            `,
            ['square']: css `
                border-radius: 0;
            `,
            ['outer glow']: css `
                background: transparent !important;
                border: none !important;
                filter: drop-shadow(0 0 2px white);
                padding: 0;
                margin: 0;
                bottom: 0.5rem;
            `,
            ['text']: css `
                display: none !important;
            `,
        };
        const EMMA_MODE = false;
        return (React.createElement("div", Object.assign({ className: "pokemon-container" }, data),
            style.template === 'Compact with Icons' && (React.createElement(PokemonIcon, Object.assign({ className: "pokemon-icon-main" }, poke))),
            EMMA_MODE ? React.createElement(React.Fragment, null,
                React.createElement("div", { role: "presentation", onClick: (e) => this.props.selectPokemon(poke.id), className: `${this.props.style.imageStyle} pokemon-image-wrapper`, style: {
                        cursor: 'pointer',
                        background: this.props.style.teamPokemonBorder
                            ? getBackgroundGradient(poke.types != null && !poke.egg ? poke.types[0] : 'Normal', poke.types != null && !poke.egg ? poke.types[1] : 'Normal', customTypes)
                            : 'transparent',
                    } }),
                React.createElement(PokemonImage, { species: poke.species, gender: poke.gender, forme: poke.forme, customImage: poke.customImage, style: style, editor: editor, name: game.name, egg: poke.egg, shiny: poke.shiny }, (backgroundImage) => {
                    return React.createElement("div", { style: Object.assign(Object.assign({ backgroundImage }, spriteStyle), { imageRendering: style.iconRendering }), className: `pokemon-image ${(poke.species || 'missingno').toLowerCase()} ${this.props.style.imageStyle === 'round' ? 'round' : 'square'}` });
                })) : React.createElement("div", { role: "presentation", onClick: (e) => this.props.selectPokemon(poke.id), className: `${this.props.style.imageStyle} pokemon-image-wrapper`, style: {
                    cursor: 'pointer',
                    background: this.props.style.teamPokemonBorder
                        ? getBackgroundGradient(poke.types != null && !poke.egg ? poke.types[0] : 'Normal', poke.types != null && !poke.egg ? poke.types[1] : 'Normal', customTypes)
                        : 'transparent',
                } },
                React.createElement(PokemonImage, { species: poke.species, gender: poke.gender, forme: poke.forme, customImage: poke.customImage, style: style, editor: editor, name: game.name, egg: poke.egg, shiny: poke.shiny }, (backgroundImage) => {
                    return React.createElement("div", { style: Object.assign(Object.assign({ backgroundImage }, spriteStyle), { imageRendering: style.iconRendering }), className: `pokemon-image ${(poke.species || 'missingno').toLowerCase()} ${this.props.style.imageStyle === 'round' ? 'round' : 'square'}` });
                })),
            poke.mvp && (React.createElement("div", { className: cx(mvpLabelStyle.base, mvpLabelStyle[style.template], 'pokemon-mvp-label') },
                React.createElement("span", { style: { marginRight: '0.5rem', fontWeight: 'bold' } }, "MVP"),
                React.createElement("img", { style: { height: '1rem' }, alt: "", role: "presentation", src: "./assets/mvp-crown.png" }))),
            React.createElement(PokemonPokeball, { pokemon: poke, style: style, customTypes: customTypes }),
            React.createElement(PokemonItem, { pokemon: poke, style: style, customTypes: customTypes }),
            pokemon && (React.createElement(TeamPokemonInfo, { generation: getGameGeneration(this.props.game.name), style: style, pokemon: pokemon, customTypes: customTypes, linkedPokemon: linkedPokemon, game: game }))));
    }
}
export const TeamPokemon = connect((state, ownProps) => ({
    style: state.style,
    game: state.game,
    editor: state.editor,
    customTypes: state.customTypes,
    linkedPokemon: state.pokemon.find((p) => { var _a; return p.id === ((_a = ownProps === null || ownProps === void 0 ? void 0 : ownProps.pokemon) === null || _a === void 0 ? void 0 : _a.linkedTo); }),
}), {
    selectPokemon,
}, null, {
    pure: true,
})(TeamPokemonBase);
