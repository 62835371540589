import * as React from 'react';
import { connect } from 'react-redux';
import { Dialog, Classes } from '@blueprintjs/core';
import { ErrorBoundary } from 'components';
import { MassEditorTable } from './MassEditorTable';
export class MassEditorBase extends React.Component {
    render() {
        return (React.createElement(Dialog, { icon: "edit", isOpen: this.props.isOpen, onClose: this.props.toggleDialog, className: `wide-dialog ${this.props.style.editorDarkMode ? Classes.DARK : ''}`, title: "Mass Editor" },
            React.createElement("div", { className: Classes.DIALOG_BODY },
                React.createElement(ErrorBoundary, null,
                    React.createElement(MassEditorTable, null)))));
    }
}
export const MassEditor = connect((state) => ({
    style: state.style,
}))(MassEditorBase);
export { MassEditor as default };
