var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { getPokemonImage, wrapImageInCORSPlain } from 'utils';
export function PokemonImage({ children, customImage, forme, species, shiny, style, editor, name, gender, url, egg, }) {
    const [image, setImage] = React.useState('');
    React.useEffect(() => {
        try {
            url ? (() => __awaiter(this, void 0, void 0, function* () {
                setImage(yield wrapImageInCORSPlain(url));
            }))() : (() => __awaiter(this, void 0, void 0, function* () {
                setImage(yield getPokemonImage({
                    customImage: customImage,
                    forme: forme,
                    species: species,
                    shiny: shiny,
                    style: style,
                    name: name,
                    editor: editor,
                    gender: gender,
                    egg: egg,
                }));
            }))();
        }
        catch (e) {
            console.error(e);
        }
    }, [
        customImage,
        forme,
        species,
        shiny,
        style,
        name,
        editor,
        gender,
        url,
        egg,
    ]);
    return (children === null || children === void 0 ? void 0 : children(image)) || React.createElement("img", { alt: name, src: image });
}
