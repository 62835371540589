import { TOGGLE_DIALOG } from 'actions';
export function view(state = {
    dialogs: {
        imageUploader: false,
    },
}, action) {
    var _a, _b;
    switch (action.type) {
        case TOGGLE_DIALOG:
            return Object.assign(Object.assign({}, state), { dialogs: Object.assign(Object.assign({}, state.dialogs), { [action.dialog]: (_b = !((_a = state === null || state === void 0 ? void 0 : state.dialogs) === null || _a === void 0 ? void 0 : _a[action.dialog])) !== null && _b !== void 0 ? _b : true }) });
        default:
            return state;
    }
}
