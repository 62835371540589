import { UPDATE_EXCLUDED_AREAS, REPLACE_STATE, UPDATE_CUSTOM_AREAS } from '../actions';
export function excludedAreas(state = [], action) {
    var _a;
    switch (action.type) {
        case UPDATE_EXCLUDED_AREAS:
            return action.excludedAreas;
        case REPLACE_STATE:
            return (_a = action.replaceWith.excludedAreas) !== null && _a !== void 0 ? _a : [];
        default:
            return state;
    }
}
export function customAreas(state = [], action) {
    var _a;
    switch (action.type) {
        case UPDATE_CUSTOM_AREAS:
            return action.areas;
        case REPLACE_STATE:
            return (_a = action.replaceWith.areas) !== null && _a !== void 0 ? _a : [];
        default:
            return state;
    }
}
