import { ADD_HISTORY_ENTRY, REPLACE_STATE, REMOVE_HISTORY_ENTRY } from '../actions';
export function history(state = [], action) {
    switch (action.type) {
        case ADD_HISTORY_ENTRY:
            return [...state, action.history];
        case REMOVE_HISTORY_ENTRY:
            return state.filter((h) => h.id !== action.id);
        case REPLACE_STATE:
            return action.replaceWith.history;
        default:
            return state;
    }
}
