import * as React from 'react';
import { Button, Classes, Spinner } from '@blueprintjs/core';
import { connect } from 'react-redux';
import * as styles from 'components/Result/styles';
import { classWithDarkTheme, getPatchlessVersion } from 'utils';
import { changeEditorSize, editStyle, seeRelease, toggleTemtemMode, toggleMobileResultView, } from 'actions';
import { version } from 'package';
import { cx } from 'emotion';
import { ReleaseDialog } from 'components/Shared';
import { isMobile } from 'is-mobile';
const darkModeStyle = (mode) => (mode ? { color: '#fff' } : {});
export class TopBarBase extends React.Component {
    constructor() {
        var _a, _b;
        super(...arguments);
        this.state = {
            isOpen: !((_a = this.props.sawRelease) === null || _a === void 0 ? void 0 : _a[(_b = getPatchlessVersion(version)) !== null && _b !== void 0 ? _b : 0]),
            isMenuOpen: false,
        };
        this.closeDialog = (e) => {
            this.props.seeRelease(getPatchlessVersion(version));
            this.toggleDialog();
        };
        this.toggleDialog = () => this.setState({ isOpen: !this.state.isOpen });
    }
    UNSAFE_componentWillMount() {
        if (version.split('.')[2] !== 0) {
            this.props.seeRelease(getPatchlessVersion(version));
        }
    }
    render() {
        const { isDownloading, editor: { showResultInMobile }, } = this.props;
        const { isMenuOpen } = this.state;
        const shouldShow = isMobile() ? isMenuOpen : true;
        return (React.createElement("div", { className: cx(classWithDarkTheme(styles, 'topBar', this.props.style.editorDarkMode), isMobile() && styles.topBar_mobile, isMobile() && isMenuOpen && styles.topBar_mobile_open) },
            isMobile() && (React.createElement(React.Fragment, null,
                React.createElement(Button, { style: darkModeStyle(this.props.style.editorDarkMode), onClick: () => this.setState((state) => ({ isMenuOpen: !state.isMenuOpen })), className: Classes.MINIMAL, icon: 'menu' }, "Nuzlocke Generator"),
                React.createElement(Button, { style: Object.assign(Object.assign({}, darkModeStyle(this.props.style.editorDarkMode)), { zIndex: 22, position: 'relative' }), onClick: () => this.props.toggleMobileResultView(), className: cx(Classes.MINIMAL, styles.close_result_button), icon: showResultInMobile ? 'cross' : 'eye-open' }, showResultInMobile ? 'Close' : 'View Result'))),
            shouldShow && (React.createElement(React.Fragment, null,
                React.createElement(Button, { style: darkModeStyle(this.props.style.editorDarkMode), onClick: () => this.props.changeEditorSize(!this.props.editor.minimized), className: Classes.MINIMAL, icon: this.props.editor.minimized ? 'minimize' : 'maximize' },
                    this.props.editor.minimized ? 'Maximize' : 'Minimize',
                    " Editor"),
                isDownloading ? (React.createElement(Button, { className: Classes.MINIMAL, style: Object.assign(Object.assign({}, darkModeStyle(this.props.style.editorDarkMode)), { height: '30px' }) },
                    React.createElement(Spinner, { className: 'inline-flex', size: 20 }),
                    " Downloading")) : (React.createElement(Button, { style: darkModeStyle(this.props.style.editorDarkMode), onClick: this.props.onClickDownload, className: Classes.MINIMAL, icon: "download" }, "Download Image")),
                React.createElement(Button, { style: darkModeStyle(this.props.style.editorDarkMode), onClick: () => {
                        this.props.editStyle({
                            editorDarkMode: !this.props.style.editorDarkMode,
                        });
                    }, className: Classes.MINIMAL, icon: this.props.style.editorDarkMode ? 'flash' : 'moon' },
                    this.props.style.editorDarkMode ? 'Light' : 'Dark',
                    " Mode"),
                this.props.children,
                React.createElement(Button, { style: darkModeStyle(this.props.style.editorDarkMode), onClick: this.toggleDialog, className: Classes.MINIMAL, icon: "star" }, version))),
            React.createElement(ReleaseDialog, { style: this.props.style, isOpen: this.state.isOpen, onClose: this.closeDialog })));
    }
}
export const TopBar = connect((state) => ({
    editor: state.editor,
    style: state.style,
    sawRelease: state.sawRelease,
    pokemon: state.pokemon,
}), {
    changeEditorSize,
    editStyle,
    seeRelease,
    toggleTemtemMode,
    toggleMobileResultView,
})(TopBarBase);
