import * as React from 'react';
import { connect } from 'react-redux';
import { PokemonIcon } from 'components/PokemonIcon';
import { Layout, LayoutDisplay } from 'components/Layout';
import { range } from 'ramda';
export class StatsBase extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            pokemon: [],
        };
        this.numberOfShinies = this.props.pokemon.filter((s) => s.shiny).length;
    }
    componentDidMount() {
        const { status } = this.props;
        const pokemon = this.props.pokemon.filter((p) => p.status === status);
        this.setState({ pokemon });
    }
    typeMap() {
        const typesFreq = {};
        this.props.pokemon.forEach((poke) => {
            if (poke && Array.isArray(poke.types)) {
                const type1 = poke.types[0];
                const type2 = poke.types[1];
                if (typesFreq.hasOwnProperty(type1)) {
                    typesFreq[type1]++;
                }
                else {
                    typesFreq[type1] = 1;
                }
                if (typesFreq.hasOwnProperty(type2)) {
                    if (type1 !== type2) {
                        typesFreq[type2]++;
                    }
                }
                else {
                    typesFreq[type2] = 1;
                }
            }
        });
        return typesFreq;
    }
    getMostCommonType() {
        const t = this.typeMap();
        const sorted = Object.keys(t)
            .map((key) => ({ name: key, total: t[key] }))
            .sort((a, b) => b.total - a.total);
        return [0, 1, 2, 3, 4, 5].map((n) => { var _a, _b; return ({ name: (_a = sorted[n]) === null || _a === void 0 ? void 0 : _a.name, total: (_b = sorted[n]) === null || _b === void 0 ? void 0 : _b.total }); });
    }
    displayMostCommonType(data) {
        return data
            .filter((d) => d.name != null)
            .map((d) => `${d.name} (${d.total} Pokémon)`)
            .join(', ');
    }
    wordMap(arr) {
        const wm = {};
        arr.forEach((key) => {
            if (key === 'from' ||
                key === 'the' ||
                key === 'a' ||
                key === 'on' ||
                key === 'up' ||
                key === 'with' ||
                key === 'to') {
                return;
            }
            if (wm.hasOwnProperty(key)) {
                wm[key]++;
            }
            else {
                wm[key] = 1;
            }
        });
        const sorted = Object.keys(wm)
            .map((key) => ({ name: key, total: wm[key] }))
            .sort((a, b) => b.total - a.total);
        return sorted;
    }
    getMostCommonDeath() {
        const words = this.props.pokemon
            .filter((s) => s.status === 'Dead')
            .map((p) => p.causeOfDeath || '')
            .join('\n');
        const res = this.wordMap(words.split(/\n/));
        return range(0, 5).map((i) => { var _a, _b; return ({ name: (_a = res[i]) === null || _a === void 0 ? void 0 : _a.name, total: (_b = res[i]) === null || _b === void 0 ? void 0 : _b.total }); });
    }
    displayMostCommonDeath(data) {
        const numOfDead = this.props.pokemon
            .filter((s) => s.status === 'Dead')
            .length;
        if (numOfDead === 0) {
            return 'None';
        }
        return data
            .filter((d) => d.name != null)
            .map((d) => `${d.name} (${d.total} deaths)`)
            .join(', ');
    }
    getAverageLevel() {
        const pokes = this.props.pokemon.filter((p) => !p.hidden);
        return pokes.reduce((prev, poke, arr) => {
            if (Number.isNaN(poke.level) || !poke.level) {
                return prev;
            }
            return prev + Number.parseInt(poke.level);
        }, 0) / pokes.length;
    }
    getAverageLevelByStatus(status) {
        const pokes = this.props.pokemon.filter((p) => !p.hidden && p.status === status);
        if (!pokes.length)
            return 0;
        return (pokes.reduce((prev, poke, arr) => {
            if (Number.isNaN(poke.level) || !poke.level) {
                return prev;
            }
            return prev + Number.parseInt(poke.level);
        }, 0) / pokes.length);
    }
    getShinies() {
        if (!this.numberOfShinies) {
            return 'None';
        }
        return this.props.pokemon
            .filter((s) => s.shiny)
            .map((p) => React.createElement(PokemonIcon, { key: p.id, shiny: p.shiny, species: p.species, id: p.id }));
    }
    getAverageLevelComponent() {
        var _a;
        return React.createElement("div", null,
            "Average Level: ", (_a = this.getAverageLevel()) === null || _a === void 0 ? void 0 :
            _a.toFixed(0));
    }
    getAverageLevelDetailedComponent() {
        return React.createElement("div", null,
            "Average Level: ",
            this.props.box.map((b, idx, arr) => {
                var _a;
                return React.createElement(React.Fragment, null,
                    " ",
                    b.name,
                    " (", (_a = this.getAverageLevelByStatus(b.name)) === null || _a === void 0 ? void 0 :
                    _a.toFixed(0),
                    ")",
                    idx < arr.length - 1 ? ',' : '');
            }));
    }
    render() {
        const { stats, style, color } = this.props;
        return (React.createElement("div", { className: "stats stats-container", style: { color } },
            React.createElement("h3", { style: { color } }, "Stats"),
            React.createElement("div", { style: { marginTop: '10px', margin: '0 10px' } },
                this.props.pokemon.length && style.statsOptions.averageLevel ?
                    this.getAverageLevelComponent() : null,
                this.props.pokemon.length && style.statsOptions.averageLevelDetailed ?
                    this.getAverageLevelDetailedComponent() : null,
                style.statsOptions.mostCommonKillers ? (React.createElement("div", null,
                    "Most Common Killers:",
                    ' ',
                    this.displayMostCommonDeath(this.getMostCommonDeath()))) : null,
                style.statsOptions.mostCommonTypes ? (React.createElement("div", null,
                    "Most Common Types:",
                    ' ',
                    this.displayMostCommonType(this.getMostCommonType()))) : null,
                style.statsOptions.shiniesCaught ? (React.createElement("div", null,
                    "Shinies:",
                    ' ',
                    React.createElement(Layout, { display: LayoutDisplay.Inline }, this.getShinies()))) : null,
                (stats === null || stats === void 0 ? void 0 : stats.length)
                    ? stats === null || stats === void 0 ? void 0 : stats.map((stat, idx) => {
                        var _a, _b;
                        return ((_a = stat.key) === null || _a === void 0 ? void 0 : _a.length) && ((_b = stat.value) === null || _b === void 0 ? void 0 : _b.length) ? (React.createElement("div", { key: stat.id },
                            stat.key,
                            ": ",
                            stat.value)) : null;
                    })
                    : null)));
    }
}
StatsBase.defaultProps = {
    status: 'Champs',
};
export const Stats = connect((state) => ({
    pokemon: state.pokemon,
    stats: state.stats,
    style: state.style,
    box: state.box,
}), undefined, undefined, { pure: false })(StatsBase);
