import * as React from 'react';
import { cx } from 'emotion';
import './Autocomplete.css';
import { useDebounceCallback } from '@react-hook/debounce';
import { css } from 'emotion';
const debounce = require('lodash.debounce');
const renderItems = (visibleItems, selectItem, innerValue, selectedValue) => visibleItems.map((v, i) => {
    return (React.createElement("li", { key: i, onClick: (e) => selectItem(e)(v), className: v === selectedValue ? 'autocomplete-selected' : '' }, v));
});
const filter = (items, str) => items === null || items === void 0 ? void 0 : items.filter(i => i === null || i === void 0 ? void 0 : i.toLowerCase().startsWith(str.toLowerCase()));
const invisibleText = css `
    color: transparent !important;
`;
export function Autocomplete({ label, name, placeholder, onChange, className, disabled, makeInvisibleText, items, value, }) {
    const [innerValue, setValue] = React.useState('');
    const [selectedValue, setSelectedValue] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);
    const [visibleItems, setVisibleItems] = React.useState([]);
    const delayedValue = useDebounceCallback((e) => onChange(e), 300);
    React.useEffect(() => {
        setValue(value);
        setVisibleItems(filter(items, value));
    }, [value, items]);
    const changeEvent = (innerEvent = true) => (e) => {
        innerEvent && e.persist();
        setValue(e.target.value);
        setVisibleItems(filter(items, e.target.value));
        delayedValue({ target: { value: e.target.value } });
    };
    const handleMovement = (e) => {
        const currentIndex = visibleItems === null || visibleItems === void 0 ? void 0 : visibleItems.indexOf(selectedValue);
        if (e.which === 38) {
            setSelectedValue(visibleItems[currentIndex - 1]);
        }
        else {
            setSelectedValue(visibleItems[currentIndex + 1]);
        }
    };
    const openList = (e) => {
        setIsOpen(true);
    };
    const closeList = (e) => {
        setTimeout(() => {
            setIsOpen(false);
            setVisibleItems(items);
        }, 250);
        setValue(e.target.value);
    };
    const handleKeyDown = (e) => {
        e.persist();
        setVisibleItems(filter(items, e.currentTarget.value));
        switch (e.which) {
            case 13:
                e.preventDefault();
                if (selectedValue) {
                    setValue(selectedValue);
                }
                closeList(e);
                changeEvent(false)(Object.assign(Object.assign({}, e), { target: { value: selectedValue !== '' ? selectedValue : innerValue } }));
                break;
            case 8:
                break;
            case 27:
            case 9:
                closeList(e);
                break;
            case 38:
            case 40:
                handleMovement(e);
                setIsOpen(true);
                break;
            default:
                setSelectedValue('');
                break;
        }
    };
    const selectItem = (e) => (value) => {
        changeEvent(false)(Object.assign(Object.assign({}, e), { target: { value } }));
    };
    return React.createElement("div", { className: cx('current-pokemon-input-wrapper', 'autocomplete') },
        React.createElement("label", null, label),
        React.createElement("input", { autoComplete: "off", className: cx(className, makeInvisibleText && invisibleText), onKeyDown: handleKeyDown, onFocus: openList, onBlur: closeList, placeholder: placeholder, name: name, type: "text", onChange: changeEvent(), value: innerValue, disabled: disabled, "data-testid": "autocomplete" }),
        isOpen ? (React.createElement("ul", { className: "autocomplete-items has-nice-scrollbars" }, renderItems(visibleItems, selectItem, innerValue, selectedValue))) : null);
}
