import * as React from 'react';
import { mapTrainerImage, getContrastColor, isEmpty, feature, } from 'utils';
import { connect, useSelector } from 'react-redux';
import { Stats } from './Stats';
import { cx } from 'emotion';
export const TrainerColumnItem = ({ trainer, prop, orientation }) => {
    const isVertical = orientation === 'vertical';
    const bottomTextStyle = {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        padding: '2px',
    };
    const baseDivStyle = isVertical ? { padding: '2px' } : { padding: '.25rem' };
    return !isEmpty(trainer[prop]) ? (React.createElement("div", { style: baseDivStyle, className: `trainer-${prop} column` },
        React.createElement("div", { style: baseDivStyle }, prop),
        React.createElement("div", { style: bottomTextStyle }, trainer[prop]))) : null;
};
export function CheckpointsDisplay({ style, trainer, game, clearedCheckpoints, className, }) {
    var _a, _b;
    const { name } = game;
    const checkpoints = useSelector(state => state.checkpoints);
    const cleared = (_b = (_a = trainer === null || trainer === void 0 ? void 0 : trainer.badges) !== null && _a !== void 0 ? _a : clearedCheckpoints) !== null && _b !== void 0 ? _b : [];
    if (!style.displayBadges) {
        return null;
    }
    const swshPositions = [
        { bottom: 0, right: 0 },
        { right: '-9px', top: '2px', height: '24px' },
        { bottom: '11px', left: '20px' },
        { bottom: '2px', left: '9px' },
        { bottom: '3px', left: '28.5px', height: '33px' },
        { top: '-3px', left: '23px' },
        { left: '14px', top: '5px', height: '25px' },
        { left: '3px', top: '2px', height: '32px' },
    ];
    return React.createElement(React.Fragment, null, checkpoints.map((badge, index) => {
        const obtained = cleared.some((b) => b.name === badge.name);
        return (React.createElement(React.Fragment, { key: badge.name },
            React.createElement("img", { className: cx(className !== null && className !== void 0 ? className : '', obtained ? 'obtained' : 'not-obtained'), style: isSWSH(name) && !(trainer === null || trainer === void 0 ? void 0 : trainer.hasEditedCheckpoints)
                    ? Object.assign({ position: 'absolute' }, swshPositions[index]) : {}, key: badge.name, alt: badge.name, "data-badge": badge.name, src: (badge.image.startsWith('http') || badge.image.startsWith('data'))
                    ? badge.image
                    : `./img/checkpoints/${badge.image}.png` }),
            badge.name === 'Rising Badge' ? React.createElement("br", null) : null));
    }));
}
export const isSWSH = (name) => name === 'Sword' || name === 'Shield';
export class TrainerResultBase extends React.Component {
    getBadgeWrapperStyles(orientation) {
        const { trainer, game: { name } } = this.props;
        let style = {};
        if (isSWSH(name) && !trainer.hasEditedCheckpoints) {
            style = { height: '3rem', width: '3rem', position: 'relative', padding: '.25rem' };
        }
        if (orientation === 'vertical') {
            style = Object.assign(Object.assign({}, style), { margin: '0', padding: '.25rem' });
        }
        if (!isSWSH(name) && orientation === 'vertical') {
            style = Object.assign(Object.assign({}, style), { width: '100%' });
        }
        return style;
    }
    render() {
        const { trainer, game, style, orientation, checkpoints } = this.props;
        const isVertical = orientation === 'vertical';
        const baseDivStyle = isVertical ? { padding: '2px' } : { padding: '.25rem' };
        const tciProps = { trainer, orientation };
        const enableStats = style.displayStats;
        const emmaMode = feature.emmaMode;
        return (React.createElement("div", { className: "trainer-wrapper", style: orientation === 'vertical'
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }
                : {} },
            React.createElement("div", { className: "trainer-game-badge", style: {
                    color: getContrastColor(style.bgColor),
                    background: style.bgColor,
                    margin: isVertical ? '4px' : '0',
                    marginRight: isVertical ? '0' : '.5rem',
                    marginLeft: isVertical ? '0' : '.5rem',
                    minWidth: '100px',
                    borderRadius: '.25rem',
                    textAlign: 'center',
                    padding: '2px',
                } }, game.customName || game.name),
            trainer.image ? (React.createElement("img", { className: "trainer-image", src: mapTrainerImage(trainer.image), alt: "Trainer" })) : null,
            trainer.title ? (React.createElement("div", { style: baseDivStyle, className: "nuzlocke-title" }, this.props.trainer.title)) : (React.createElement("div", { style: baseDivStyle, className: "nuzlocke-title" },
                this.props.game.name,
                " Nuzlocke")),
            feature.resultv2 ? (React.createElement("div", { className: "flex trainer-info-columns" }, ['name', 'money', 'time', 'id', 'totalTime'].map((item) => (React.createElement(TrainerColumnItem, Object.assign({ key: item, prop: item }, tciProps)))))) : (React.createElement(React.Fragment, null, ['name', 'money', 'time', 'id', 'totalTime'].map((item) => (React.createElement(TrainerColumnItem, Object.assign({ key: item, prop: item }, tciProps)))))),
            React.createElement("div", { className: "badge-wrapper flex", style: this.getBadgeWrapperStyles(orientation) },
                React.createElement(CheckpointsDisplay, { className: 'trainer-checkpoint', trainer: trainer, style: style, game: game })),
            style.displayRules && style.displayRulesLocation === 'inside trainer section' ? (React.createElement("div", { style: { marginTop: '1rem' }, className: "rules-container" },
                React.createElement("h3", null, "Rules"),
                React.createElement("ol", { style: { paddingLeft: '20px', textAlign: 'left' } }, this.props.rules.map((rule, index) => {
                    return React.createElement("li", { key: index }, rule);
                })))) : null,
            emmaMode && React.createElement(Stats, null)));
    }
}
export const TrainerResult = connect((state) => ({
    checkpoints: state.checkpoints,
    style: state.style,
    trainer: state.trainer,
    game: state.game,
    rules: state.rules,
}))(TrainerResultBase);
