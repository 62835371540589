import * as React from 'react';
import { connect } from 'react-redux';
import { GenderElement } from 'components/Shared';
import { getContrastColor, Forme, gameOfOriginToColor, TemplateName, feature, } from 'utils';
import { selectPokemon } from 'actions';
import { PokemonIconBase } from 'components/PokemonIcon';
import { PokemonImage } from 'components/Shared/PokemonImage';
const spriteStyle = (style) => {
    if (style.spritesMode) {
        if (style.scaleSprites) {
            return {
                backgroundSize: 'auto',
                backgroundRepeat: 'no-repeat',
            };
        }
        else {
            return {
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            };
        }
    }
    if (style.teamImages === 'dream world') {
        return {
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        };
    }
    else {
        return {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };
    }
};
export const DeadPokemonBase = (poke) => {
    const style = poke.style;
    const isMinimal = poke.minimal || poke.style.minimalDeadLayout;
    const isCompactWithIcons = style.template === TemplateName.CompactWithIcons;
    const addForme = (species) => {
        if (poke.forme) {
            if (poke.forme === Forme.Alolan) {
                return `alolan-${species}`;
            }
            return species;
        }
        else {
            return species;
        }
    };
    const getAccentColor = (prop) => (prop.style ? prop.style.accentColor : '#111111');
    const useGameOfOriginColor = poke.gameOfOrigin &&
        poke.style.displayGameOriginForBoxedAndDead &&
        poke.style.displayBackgroundInsteadOfBadge;
    const EMMA_MODE = feature.emmaMode;
    if (isMinimal && isCompactWithIcons) {
        return (React.createElement("div", { className: 'dead-pokemon-container', "data-league": poke.champion, "data-game": poke.gameOfOrigin, style: {
                background: useGameOfOriginColor
                    ? gameOfOriginToColor(poke.gameOfOrigin)
                    : getAccentColor(poke),
                color: useGameOfOriginColor
                    ? getContrastColor(gameOfOriginToColor(poke.gameOfOrigin))
                    : getContrastColor(getAccentColor(poke)),
                height: '50px',
                fontSize: '90%',
                outline: EMMA_MODE ? '' : '1px solid #222',
                width: poke.gameOfOrigin === 'SoulSilver' && EMMA_MODE ? '14rem' : '50px',
            } },
            React.createElement("div", { className: "goc-circle", style: {
                    background: EMMA_MODE
                        ? gameOfOriginToColor(poke.gameOfOrigin)
                        : `linear-gradient(45deg, ${gameOfOriginToColor(poke.gameOfOrigin)}, transparent)`,
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    opacity: 0.7,
                } }),
            React.createElement("span", { style: { filter: 'grayscale(100%)', margin: '0 auto' } },
                React.createElement(PokemonIconBase, Object.assign({ onClick: (e) => poke.selectPokemon(poke.id) }, poke))),
            poke.gameOfOrigin === 'SoulSilver' && EMMA_MODE && (React.createElement("div", { style: {
                    margin: 0,
                    padding: 0,
                    paddingTop: '12px',
                    lineHeight: '14px',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    zIndex: 1,
                } },
                React.createElement("div", null,
                    poke.nickname,
                    " ",
                    GenderElement(poke.gender),
                    " Levels ",
                    poke.metLevel,
                    "\u2014",
                    poke.level),
                React.createElement("div", { "data-testid": "cause-of-death" }, poke.causeOfDeath),
                style.displayGameOriginForBoxedAndDead &&
                    !poke.style.displayBackgroundInsteadOfBadge &&
                    poke.gameOfOrigin && (React.createElement("span", { className: "pokemon-gameoforigin", style: {
                        fontSize: '80%',
                        borderRadius: '.25rem',
                        margin: '0',
                        marginTop: '.25rem',
                        marginLeft: '.25rem',
                        padding: '2px',
                        display: 'inline-block',
                        background: gameOfOriginToColor(poke.gameOfOrigin),
                        color: getContrastColor(gameOfOriginToColor(poke.gameOfOrigin)),
                    } }, poke.gameOfOrigin))))));
    }
    if (isMinimal) {
        return (React.createElement("div", { className: 'dead-pokemon-container', "data-league": poke.champion, style: {
                background: useGameOfOriginColor
                    ? gameOfOriginToColor(poke.gameOfOrigin)
                    : getAccentColor(poke),
                color: useGameOfOriginColor
                    ? getContrastColor(gameOfOriginToColor(poke.gameOfOrigin))
                    : getContrastColor(getAccentColor(poke)),
                height: '50px',
                fontSize: '90%',
            } },
            React.createElement("span", { style: { filter: 'grayscale(100%)' } },
                React.createElement(PokemonIconBase, Object.assign({ onClick: (e) => poke.selectPokemon(poke.id) }, poke))),
            React.createElement("div", { style: {
                    margin: 0,
                    padding: 0,
                    lineHeight: '14px',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                } },
                React.createElement("div", null,
                    poke.nickname,
                    " ",
                    GenderElement(poke.gender),
                    " Levels ",
                    poke.metLevel,
                    "\u2014",
                    poke.level),
                React.createElement("div", { "data-testid": "cause-of-death" }, poke.causeOfDeath),
                style.displayGameOriginForBoxedAndDead &&
                    !poke.style.displayBackgroundInsteadOfBadge &&
                    poke.gameOfOrigin && (React.createElement("span", { className: "pokemon-gameoforigin", style: {
                        fontSize: '80%',
                        borderRadius: '.25rem',
                        margin: '0',
                        marginTop: '.25rem',
                        marginLeft: '.25rem',
                        padding: '2px',
                        display: 'inline-block',
                        background: gameOfOriginToColor(poke.gameOfOrigin),
                        color: getContrastColor(gameOfOriginToColor(poke.gameOfOrigin)),
                    } }, poke.gameOfOrigin)))));
    }
    return (React.createElement("div", { className: 'dead-pokemon-container', "data-league": poke.champion, style: {
            background: useGameOfOriginColor
                ? gameOfOriginToColor(poke.gameOfOrigin)
                : getAccentColor(poke),
            color: useGameOfOriginColor
                ? getContrastColor(gameOfOriginToColor(poke.gameOfOrigin))
                : getContrastColor(getAccentColor(poke)),
        } },
        style.template !== 'Generations' ? (React.createElement(PokemonImage, { customImage: poke.customImage, forme: poke.forme, shiny: poke.shiny, species: poke.species, style: poke.style, name: poke.game.name, gender: poke.gender }, (backgroundImage) => React.createElement("div", { role: "presentation", onClick: (e) => poke.selectPokemon(poke.id), className: `dead-pokemon-picture ${poke.style.spritesMode ? 'sprites-mode' : ''}`, style: Object.assign(Object.assign({ backgroundImage }, spriteStyle(style)), { filter: style.grayScaleDeadPokemon ? 'grayscale(100%)' : 'none' }) }))) : (React.createElement("span", { style: { filter: 'grayscale(100%)' } },
            React.createElement(PokemonIconBase, Object.assign({ onClick: (e) => poke.selectPokemon(poke.id) }, poke)))),
        React.createElement("div", { className: "dead-pokemon-info" },
            React.createElement("div", { className: "pokemon-d-nickname" },
                poke.nickname,
                " ",
                GenderElement(poke.gender)),
            React.createElement("div", { className: "pokemon-levels" },
                "Levels ",
                poke.metLevel,
                "\u2014",
                poke.level),
            React.createElement("br", null),
            React.createElement("div", { "data-testid": "cause-of-death", className: "pokemon-causeofdeath" }, poke.causeOfDeath),
            style.displayGameOriginForBoxedAndDead &&
                !poke.style.displayBackgroundInsteadOfBadge &&
                poke.gameOfOrigin && (React.createElement("span", { className: "pokemon-gameoforigin", style: {
                    fontSize: '80%',
                    borderRadius: '.25rem',
                    margin: '0',
                    marginTop: '.25rem',
                    marginLeft: '.25rem',
                    padding: '2px',
                    background: gameOfOriginToColor(poke.gameOfOrigin),
                    color: getContrastColor(gameOfOriginToColor(poke.gameOfOrigin)),
                } }, poke.gameOfOrigin)))));
};
export const DeadPokemon = connect((state) => ({ style: state.style, game: state.game }), {
    selectPokemon,
})(DeadPokemonBase);
