import * as React from 'react';
import { cx } from 'react-emotion';
import { Classes, Button, Icon, Intent, Popover, PopoverInteractionKind, } from '@blueprintjs/core';
import { classWithDarkTheme, feature } from 'utils';
import * as styles from './style';
import { connect } from 'react-redux';
import { getAllBadges } from 'utils';
import { addCustomCheckpoint, editCheckpoint, deleteCheckpoint, reorderCheckpoints } from 'actions';
import { ImageUpload } from 'components/Shared/ImageUpload';
const checkpointImageURL = (name) => ((name.startsWith('http') || name.startsWith('data'))
    ? name
    : `./img/checkpoints/${name}.png`);
export class CheckpointsSelect extends React.Component {
    renderOptions(checkpoint) {
        const { name, image } = checkpoint;
        const isImageUnique = getAllBadges()
            .map((badge) => badge.image)
            .includes(image);
        return (React.createElement("div", { className: 'has-nice-scrollbars', style: { padding: '1rem', height: '400px', overflowY: 'auto' } }, getAllBadges().map((badge, key) => {
            return (React.createElement(Button, { onClick: (e) => this.props.onEdit({ image: badge.image }, name), key: key, name: badge.name, style: { display: 'block' }, className: Classes.MINIMAL },
                React.createElement("img", { className: cx(styles.checkpointImage(1)), alt: badge.name, src: checkpointImageURL(badge === null || badge === void 0 ? void 0 : badge.image) }),
                ' ',
                badge.name));
        })));
    }
    render() {
        const { checkpoint } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Popover, { minimal: true, interactionKind: PopoverInteractionKind.CLICK, content: this.renderOptions(checkpoint) },
                React.createElement("div", { className: cx(styles.checkpointSelect, Classes.SELECT, Classes.BUTTON, 'has-nice-scrollbars') },
                    React.createElement("div", null,
                        React.createElement("img", { className: cx(styles.checkpointImage(1)), alt: checkpoint.name, src: checkpointImageURL(checkpoint === null || checkpoint === void 0 ? void 0 : checkpoint.image) }),
                        ' ',
                        checkpoint.name)))));
    }
}
export class CheckpointsEditorBase extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { badgeNumber: 0 };
        this.addCheckpoint = (e) => {
            this.setState({
                badgeNumber: this.state.badgeNumber + 1,
            }, () => {
                this.props.addCheckpoint({
                    name: `Custom Badge ${this.state.badgeNumber}`,
                    image: 'unknown',
                });
            });
        };
    }
    renderCheckpoints(checkpoints) {
        return (checkpoints &&
            checkpoints.map((checkpoint, key) => {
                return (React.createElement("li", { key: key, className: cx(classWithDarkTheme(styles, 'checkpointsItem', this.props.style.editorDarkMode)) },
                    React.createElement("div", { className: cx(styles.checkpointName) },
                        React.createElement("img", { className: cx(styles.checkpointImage()), alt: checkpoint.name, src: checkpointImageURL(checkpoint === null || checkpoint === void 0 ? void 0 : checkpoint.image) }),
                        React.createElement("input", { onChange: (e) => this.props.editCheckpoint({ name: e.target.value }, checkpoint.name), className: Classes.INPUT, type: "text", value: checkpoint.name })),
                    React.createElement(CheckpointsSelect, { onEdit: (i, n) => this.props.editCheckpoint(i, n), checkpoint: checkpoint }),
                    React.createElement("div", { className: Classes.INPUT_GROUP },
                        React.createElement(Icon, { icon: 'link' }),
                        React.createElement("input", { className: Classes.INPUT, placeholder: "https://...", value: checkpoint.image, type: "text", onChange: (e) => this.props.editCheckpoint({ image: e.target.value }, checkpoint.name) })),
                    React.createElement("div", { className: cx(styles.checkpointImageUploadWrapper) }, feature.imageUploads && React.createElement(ImageUpload, { onSuccess: image => {
                            const request = window.indexedDB.open('NuzlockeGenerator', 3);
                            this.props.editCheckpoint({ image }, checkpoint.name);
                        } })),
                    React.createElement(Icon, { style: { cursor: 'pointer' }, onClick: (e) => this.props.deleteCheckpoint(checkpoint.name), className: cx(styles.checkpointDelete), icon: "trash" })));
            }));
    }
    render() {
        return (React.createElement("div", { className: cx(styles.checkpointsEditor, 'has-nice-scrollbars') },
            React.createElement("ul", { className: cx(styles.checkpointsList, 'has-nice-scrollbars') }, this.renderCheckpoints(this.props.checkpoints)),
            React.createElement("div", { className: cx(styles.checkpointButtons) },
                React.createElement(Button, { onClick: this.addCheckpoint, icon: "plus", intent: Intent.SUCCESS },
                    ' ',
                    "Add Checkpoint"))));
    }
}
export const CheckpointsEditor = connect((state) => ({
    style: state.style,
}), {
    addCheckpoint: addCustomCheckpoint,
    editCheckpoint,
    deleteCheckpoint,
    reorderCheckpoints,
})(CheckpointsEditorBase);
