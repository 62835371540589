import * as React from 'react';
import { BadgeInput } from 'components/TrainerEditor/BadgeInput';
import { Popover, PopoverInteractionKind, Position, Menu, Icon } from '@blueprintjs/core';
import { listOfTrainers } from 'utils';
import { TrainerInfoEditField } from './TrainerInfoEditField';
import { useDispatch, useSelector } from 'react-redux';
import { editTrainer } from 'actions';
const capitalize = (s) => s[0].toUpperCase() + s.slice(1);
const SpanBlock = ({ text }) => (React.createElement("span", { style: {
        background: 'rgba(0, 0, 0, 0.1)',
        display: 'inline-block',
        padding: '0 3px',
        margin: '2px',
        borderRadius: '.25rem',
    } }, text));
const trainerInfoEdit = (name, func) => e => func({ [name]: e.target.value });
export function TrainerInfoEditor() {
    var _a;
    const trainer = useSelector(state => state.trainer);
    const dispatch = useDispatch();
    return React.createElement("div", { className: "trainer-info-editor" },
        React.createElement(TrainerInfoEditField, { onEdit: e => dispatch(editTrainer({ name: e.target.value })), value: trainer.name, label: "Trainer Name", name: "name", placeholder: "Trainer Name" }),
        React.createElement(TrainerInfoEditField, { onEdit: e => dispatch(editTrainer({ id: e.target.value })), value: (_a = trainer.id) === null || _a === void 0 ? void 0 : _a.toString(), label: "ID", name: "id", placeholder: "Trainer ID" }),
        React.createElement(TrainerInfoEditField, { onEdit: e => dispatch(editTrainer({ time: e.target.value })), value: trainer.time, label: "Time", name: "time", placeholder: "0:00" }),
        React.createElement(TrainerInfoEditField, { onEdit: e => dispatch(editTrainer({ money: e.target.value })), value: trainer.money, label: "Money", name: "money", placeholder: "$0" }),
        React.createElement(TrainerInfoEditField, { onEdit: e => dispatch(editTrainer({ title: e.target.value })), value: trainer.title, label: "Title", name: "title", placeholder: "" }),
        React.createElement(BadgeInput, null),
        React.createElement(TrainerInfoEditField, { onEdit: e => dispatch(editTrainer({ image: e.target.value })), value: trainer.image, label: React.createElement(Popover, { minimal: true, interactionKind: PopoverInteractionKind.HOVER, position: Position.BOTTOM, content: React.createElement(Menu, { style: { width: '10rem' } },
                    "Type: image url. You can also use these keywords",
                    ' ',
                    listOfTrainers.map((t) => (React.createElement(SpanBlock, { key: t, text: capitalize(t) })))) },
                React.createElement("span", null,
                    "Trainer Image ",
                    React.createElement(Icon, { icon: "info-sign" }))), name: "image", placeholder: "http://..." }),
        React.createElement(TrainerInfoEditField, { onEdit: e => dispatch(editTrainer({ notes: e.target.value })), value: trainer.notes, label: "Notes", name: "notes", placeholder: "" }));
}
