import * as React from 'react';
import { EvolutionTree, feature, getAdditionalFormes, getGameGeneration, getListOfTypes, listOfAbilities, listOfItems, listOfLocations, listOfNatures, listOfPokeballs, listOfPokemon, matchSpeciesToTypes, } from 'utils';
import { CurrentPokemonInput } from 'components/PokemonEditor';
import { DeletePokemonButton } from 'components/DeletePokemonButton';
import { Autocomplete, ErrorBoundary } from 'components/Shared';
import { selectPokemon, editPokemon } from 'actions';
import { connect } from 'react-redux';
import { listOfGames, accentedE } from 'utils';
import { PokemonIconPlain } from 'components/PokemonIcon';
import { cx } from 'emotion';
import * as Styles from './styles';
const uuid = require('uuid');
import { Classes, Icon, Popover, Position, PopoverInteractionKind, Button, Intent, ButtonGroup, Tooltip, } from '@blueprintjs/core';
import { addPokemon } from 'actions';
import { CurrentPokemonLayoutItem } from './CurrentPokemonLayoutItem';
import { MoveEditor } from 'components/MoveEditor';
import { CheckpointsInputList } from 'components/TrainerEditor';
import { getImages } from 'components/Shared/ImagesDrawer';
const pokeball = require('assets/pokeball.png').default;
export const CopyPokemonButton = ({ onClick, }) => {
    return (React.createElement(Popover, { interactionKind: PopoverInteractionKind.HOVER, position: Position.TOP, content: React.createElement("div", { style: { padding: '1rem' } }, `Copy Pok${accentedE}mon`) },
        React.createElement(Icon, { title: "Copy Pokemon", icon: "duplicate", className: cx(Styles.copyButton), onClick: onClick })));
};
const getEvos = (species) => {
    return EvolutionTree === null || EvolutionTree === void 0 ? void 0 : EvolutionTree[species];
};
export function EvolutionSelection({ currentPokemon, onEvolve }) {
    const evos = getEvos(currentPokemon === null || currentPokemon === void 0 ? void 0 : currentPokemon.species);
    if (!(evos === null || evos === void 0 ? void 0 : evos.length)) {
        return null;
    }
    if ((evos === null || evos === void 0 ? void 0 : evos.length) === 1) {
        const species = evos === null || evos === void 0 ? void 0 : evos[0];
        return (React.createElement(Button, { onClick: onEvolve(species), className: Classes.MINIMAL, intent: Intent.PRIMARY }, "Evolve"));
    }
    else {
        return (React.createElement(Popover, { popoverClassName: 'no-list-item-types', minimal: true, position: Position.BOTTOM_LEFT, interactionKind: PopoverInteractionKind.CLICK_TARGET_ONLY, content: React.createElement(React.Fragment, null, evos.map((evo) => (React.createElement("div", { role: "button", tabIndex: -2, className: Styles.evoMenuItem, key: evo, onClick: onEvolve(evo), onKeyPress: onEvolve(evo) }, evo)))) },
            React.createElement(Button, { className: Classes.MINIMAL, intent: Intent.PRIMARY }, "Evolve")));
    }
}
export class CurrentPokemonEditBase extends React.Component {
    constructor(props) {
        super(props);
        this.copyPokemon = (e) => {
            const currentPokemon = this.getCurrentPokemon();
            if (currentPokemon) {
                const newPokemon = Object.assign(Object.assign({}, currentPokemon), { id: uuid(), position: currentPokemon.position + 1 });
                this.props.addPokemon(newPokemon);
            }
        };
        this.expandView = (_) => {
            this.setState({
                expandedView: !this.state.expandedView,
            });
        };
        this.evolvePokemon = (species) => (e) => {
            const pokemon = this.getCurrentPokemon();
            const edit = {
                species,
                types: matchSpeciesToTypes(species, ((pokemon === null || pokemon === void 0 ? void 0 : pokemon.forme) || 'Normal')),
            };
            this.props.editPokemon(edit, this.state.selectedId);
        };
        this.levelPokemon = (levelUp) => () => {
            var _a;
            const pokemon = this.getCurrentPokemon();
            const level = Number.parseInt((_a = pokemon === null || pokemon === void 0 ? void 0 : pokemon.level) !== null && _a !== void 0 ? _a : '0');
            const edit = {
                level: (level !== null && level !== void 0 ? level : 0) + (levelUp ? 1 : -1)
            };
            this.props.editPokemon(edit, this.state.selectedId);
        };
        this.toggleDialog = () => this.setState({ isMoveEditorOpen: !this.state.isMoveEditorOpen });
        this.state = {
            selectedId: '5',
            box: [],
            isMoveEditorOpen: false,
            expandedView: false,
            images: [],
        };
    }
    UNSAFE_componentWillMount() {
        this.setState({
            selectedId: this.props.selectedId,
            box: this.props.box,
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
        if (nextProps.selectedId !== prevProps.selectedId) {
            this.setState({
                selectedId: nextProps.selectedId,
            });
        }
    }
    componentDidMount() {
        getImages().then((res) => this.setState({ images: res }));
    }
    getCurrentPokemon() {
        return this.props.pokemon.find((v) => v.id === this.state.selectedId);
    }
    getTypes(includeShadow = true) {
        const { customTypes, editor } = this.props;
        return getListOfTypes(customTypes, editor.temtemMode).filter((type) => includeShadow ? true : type !== 'Shadow');
    }
    moreInputs(currentPokemon) {
        var _a, _b, _c;
        const { editPokemon, selectPokemon } = this.props;
        const imageNames = (_b = (_a = this.state.images) === null || _a === void 0 ? void 0 : _a.map((img) => { var _a; return (_a = img.name) !== null && _a !== void 0 ? _a : ''; })) !== null && _b !== void 0 ? _b : [];
        const pokemonForLink = this.props.pokemon.map((p) => ({
            key: `${p.nickname} (${p.species})`,
            value: p.id,
        }));
        return (React.createElement("div", { className: "expanded-edit" },
            React.createElement(CurrentPokemonInput, { labelName: "Forme", inputName: "forme", placeholder: "", value: currentPokemon.forme, type: "select", options: ['Normal', ...getAdditionalFormes(currentPokemon.species)], pokemon: currentPokemon, key: this.state.selectedId + 'forme' }),
            React.createElement(CurrentPokemonInput, { labelName: "Types", inputName: "types", value: currentPokemon.types, type: "double-select", options: this.getTypes(), key: this.state.selectedId + 'types' }),
            React.createElement("span", { className: 'current-pokemon-input-wrapper current-pokemon-checklist current-pokemon-checkpoints' },
                React.createElement("label", { htmlFor: "checkpointsInputList" }, "Checkpoints"),
                React.createElement(CheckpointsInputList, { checkpointsObtained: (_c = currentPokemon.checkpoints) !== null && _c !== void 0 ? _c : [], onChange: (checkpoints) => editPokemon({ checkpoints }, currentPokemon.id), buttonText: "Award Checkpoints" })),
            React.createElement(CurrentPokemonLayoutItem, { checkboxes: true },
                React.createElement(CurrentPokemonInput, { labelName: "Shiny", inputName: "shiny", value: currentPokemon.shiny, type: "checkbox", key: this.state.selectedId + 'shiny' }),
                React.createElement(CurrentPokemonInput, { labelName: "Egg", inputName: "egg", value: currentPokemon.egg, type: "checkbox", key: this.state.selectedId + 'egg' }),
                React.createElement(CurrentPokemonInput, { labelName: "Hidden", inputName: "hidden", value: currentPokemon.hidden, type: "checkbox", key: this.state.selectedId + 'hidden' }),
                React.createElement(CurrentPokemonInput, { labelName: "MVP", inputName: "mvp", value: currentPokemon.mvp, type: "checkbox", key: this.state.selectedId + 'mvp' }),
                React.createElement(CurrentPokemonInput, { labelName: "Gift", inputName: "gift", value: currentPokemon === null || currentPokemon === void 0 ? void 0 : currentPokemon.gift, type: "checkbox", key: this.state.selectedId + 'gift' }),
                React.createElement(CurrentPokemonInput, { labelName: "Alpha", inputName: "alpha", value: currentPokemon === null || currentPokemon === void 0 ? void 0 : currentPokemon.alpha, type: "checkbox", key: this.state.selectedId + 'alpha' })),
            feature.imageUploads ? (React.createElement(Autocomplete, { items: imageNames, name: "customImage", label: "Custom Image", placeholder: "http://...", value: currentPokemon.customImage || '', onChange: (e) => {
                    const edit = {
                        customImage: e.target.value,
                    };
                    editPokemon(edit, this.state.selectedId);
                }, key: this.state.selectedId + 'customimage' })) : (React.createElement(CurrentPokemonInput, { labelName: "Custom Image", inputName: "customImage", placeholder: "http://...", value: currentPokemon.customImage, type: "text", key: this.state.selectedId + 'customImage' })),
            React.createElement(CurrentPokemonInput, { labelName: "Custom Icon", inputName: "customIcon", placeholder: "http://...", value: currentPokemon.customIcon, type: "text", key: this.state.selectedId + 'customIcon' }),
            React.createElement(CurrentPokemonInput, { labelName: "Cause of Death", inputName: "causeOfDeath", value: currentPokemon.causeOfDeath, type: "text", key: this.state.selectedId + 'cod' }),
            React.createElement(Autocomplete, { items: listOfItems, name: "item", label: "Item", placeholder: "Item", value: currentPokemon.item || '', onChange: (e) => {
                    const edit = {
                        item: e.target.value,
                    };
                    editPokemon(edit, this.state.selectedId);
                    selectPokemon(this.state.selectedId);
                }, key: this.state.selectedId + 'item' }),
            React.createElement(CurrentPokemonInput, { labelName: "Custom Item Image", inputName: "customItemImage", placeholder: "http://..", value: currentPokemon.customItemImage, type: "text", key: this.state.selectedId + 'customItemImage' }),
            React.createElement(CurrentPokemonInput, { labelName: "Pokeball", inputName: "pokeball", value: currentPokemon.pokeball, type: "select", options: [
                    'None',
                    ...listOfPokeballs.map((ball) => `${ball.charAt(0).toUpperCase() + ball.slice(1, ball.length)} Ball`),
                ], key: this.state.selectedId + 'ball' }),
            React.createElement(CurrentPokemonLayoutItem, null,
                React.createElement(CurrentPokemonInput, { labelName: "Wonder Traded", inputName: "wonderTradedFor", value: currentPokemon.wonderTradedFor, type: "text", key: this.state.selectedId + 'wt' }),
                React.createElement(CurrentPokemonInput, { labelName: "Position", inputName: "position", value: currentPokemon.position, type: "number", key: this.state.selectedId + 'position' }),
                React.createElement(CurrentPokemonInput, { labelName: "Game of Origin", inputName: "gameOfOrigin", value: currentPokemon.gameOfOrigin, type: "select", options: listOfGames, key: this.state.selectedId + 'goo' }),
                React.createElement(CurrentPokemonInput, { labelName: "Tera Type", inputName: "teraType", value: currentPokemon.teraType, type: "select", options: this.getTypes(false), key: this.state.selectedId + 'teraType' })),
            React.createElement(CurrentPokemonLayoutItem, null,
                React.createElement(CurrentPokemonInput, { labelName: "Link To...", inputName: "linkedTo", value: currentPokemon === null || currentPokemon === void 0 ? void 0 : currentPokemon.linkedTo, type: "select", options: [
                        {
                            key: 'None',
                            value: null,
                        },
                        ...pokemonForLink,
                    ], usesKeyValue: true, key: this.state.selectedId + 'linked' })),
            React.createElement(CurrentPokemonLayoutItem, null,
                React.createElement(CurrentPokemonInput, { labelName: "Notes", inputName: "notes", value: currentPokemon.notes, type: "textArea", key: this.state.selectedId + 'notes' })),
            React.createElement(CurrentPokemonLayoutItem, { fullWidth: true }, currentPokemon.extraData && (React.createElement(CurrentPokemonInput, { labelName: "Extra Data", inputName: "extraData", type: "textArea", disabled: true, className: "full-width", value: JSON.stringify(currentPokemon.extraData), key: this.state.selectedId + 'extradata' })))));
    }
    render() {
        const currentPokemon = this.getCurrentPokemon();
        const { customAreas } = this.props;
        if (currentPokemon == null) {
            return (React.createElement("div", { className: "current-pokemon no-pokemon-selected" },
                React.createElement("img", { alt: "pokeball", src: pokeball }),
                " ",
                React.createElement("p", null, "Select a Pok\u00E9mon to edit")));
        }
        return (React.createElement("div", { className: "current-pokemon" },
            React.createElement("span", { className: "current-pokemon-header" },
                React.createElement(PokemonIconPlain, { className: "current-pokemon-image", id: currentPokemon.id, species: currentPokemon.species, forme: currentPokemon.forme, shiny: currentPokemon.shiny, gender: currentPokemon.gender, customIcon: currentPokemon.customIcon, egg: currentPokemon.egg, selectedId: null, onClick: () => { }, imageStyle: {
                        maxHeight: '100%',
                        height: '32px',
                    } }),
                React.createElement(CurrentPokemonInput, { labelName: "Status", inputName: "status", value: currentPokemon.status, type: "select", options: this.state.box.map((n) => n.name), key: this.state.selectedId + 'status' }),
                React.createElement("div", { className: cx(Styles.iconBar) },
                    React.createElement(Tooltip, { content: "Level Up/Down" },
                        React.createElement(ButtonGroup, null,
                            React.createElement(Button, { onClick: this.levelPokemon(false), small: true }, "-1"),
                            React.createElement(Button, { onClick: this.levelPokemon(true), small: true }, "+1"))),
                    React.createElement(EvolutionSelection, { currentPokemon: currentPokemon, onEvolve: this.evolvePokemon }),
                    React.createElement(CopyPokemonButton, { onClick: this.copyPokemon }),
                    React.createElement(DeletePokemonButton, { id: this.state.selectedId }))),
            React.createElement(CurrentPokemonLayoutItem, null,
                React.createElement(ErrorBoundary, null,
                    React.createElement(Autocomplete, { items: listOfPokemon, name: "species", label: "Species", disabled: currentPokemon.egg, makeInvisibleText: currentPokemon.egg, placeholder: "Missing No.", value: currentPokemon.species, onChange: (e) => {
                            const edit = {
                                species: e.target.value,
                            };
                            this.props.editPokemon(edit, this.state.selectedId);
                            this.props.editPokemon({
                                types: matchSpeciesToTypes(e.target.value, currentPokemon.forme, getGameGeneration(this.props.game.name)),
                            }, this.state.selectedId);
                            this.props.selectPokemon(this.state.selectedId);
                        } })),
                React.createElement(CurrentPokemonInput, { labelName: "Nickname", inputName: "nickname", value: currentPokemon.nickname, placeholder: "Fluffy", type: "text", key: this.state.selectedId + 'nickname' })),
            React.createElement(CurrentPokemonLayoutItem, null,
                React.createElement(CurrentPokemonInput, { labelName: "Level", inputName: "level", placeholder: "5", value: currentPokemon.level, type: "number", key: this.state.selectedId + 'level' }),
                React.createElement(Autocomplete, { items: [...listOfLocations, ...customAreas], name: "met", label: "Met Location", placeholder: "Pallet Town", value: currentPokemon.met || '', onChange: (e) => {
                        var _a;
                        if (!((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value)) {
                            return;
                        }
                        const edit = {
                            met: e.target.value,
                        };
                        this.props.editPokemon(edit, this.state.selectedId);
                        this.props.selectPokemon(this.state.selectedId);
                    } }),
                React.createElement(CurrentPokemonInput, { labelName: "Met Level", inputName: "metLevel", placeholder: "5", value: currentPokemon.metLevel, type: "number", key: this.state.selectedId + 'metlevel' })),
            React.createElement(CurrentPokemonLayoutItem, null,
                React.createElement(CurrentPokemonInput, { labelName: "Gender", inputName: "gender", placeholder: "", value: currentPokemon.gender, type: "select", options: ['Neutral', 'Male', 'Female'], key: this.state.selectedId + 'gender' }),
                React.createElement(CurrentPokemonInput, { labelName: "Nature", inputName: "nature", placeholder: "Sassy", value: currentPokemon.nature, type: "select", options: listOfNatures, pokemon: currentPokemon, key: this.state.selectedId + 'nature' }),
                React.createElement(Autocomplete, { items: listOfAbilities, name: "ability", label: "Ability", placeholder: "", value: currentPokemon.ability || '', onChange: (e) => {
                        const edit = {
                            ability: e.target.value,
                        };
                        this.props.editPokemon(edit, this.state.selectedId);
                        this.props.selectPokemon(this.state.selectedId);
                    }, key: this.state.selectedId + 'ability' })),
            React.createElement(CurrentPokemonLayoutItem, { className: Styles.moveInputWrapper },
                React.createElement(CurrentPokemonInput, { labelName: "Moves", inputName: "moves", placeholder: "", value: currentPokemon.moves, type: "moves", key: this.state.selectedId + 'moves' }),
                React.createElement(Button, { className: Styles.moveEditButton, intent: Intent.PRIMARY, onClick: this.toggleDialog, minimal: true }, "Edit Moves")),
            React.createElement(MoveEditor, { isOpen: this.state.isMoveEditorOpen, toggleDialog: this.toggleDialog }),
            this.state.expandedView ? this.moreInputs(currentPokemon) : null,
            React.createElement("br", null),
            React.createElement(Button, { onClick: this.expandView, "data-expandedview": this.state.expandedView.toString(), intent: Intent.PRIMARY, className: cx(Classes.FILL, 'current-pokemon-more'), icon: this.state.expandedView ? 'symbol-triangle-up' : 'symbol-triangle-down' }, this.state.expandedView ? 'Less' : 'More')));
    }
}
export const CurrentPokemonEdit = connect((state) => ({
    box: state.box,
    selectedId: state.selectedId,
    pokemon: state.pokemon,
    game: state.game,
    editor: state.editor,
    customTypes: state.customTypes,
    customAreas: state.customAreas,
}), {
    selectPokemon,
    editPokemon,
    addPokemon,
})(CurrentPokemonEditBase);
