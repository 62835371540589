import * as React from 'react';
import { Alert, Intent, Icon, Popover, Position, PopoverInteractionKind, Classes } from '@blueprintjs/core';
import styled, { cx } from 'react-emotion';
import { deletePokemon, modifyDeletionConfirmation } from 'actions';
import { connect } from 'react-redux';
import { accentedE } from 'utils';
export const DeletePokemonButtonContainer = styled('div') `
    color: red;
    cursor: pointer;
`;
export class DeletePokemonButtonBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOn: false,
        };
        this.toggleDialog = this.toggleDialog.bind(this);
    }
    toggleDialog() {
        this.setState({
            dialogOn: !this.state.dialogOn,
        });
    }
    render() {
        console.log(this.props.id);
        return (React.createElement(DeletePokemonButtonContainer, null,
            React.createElement(Alert, { icon: "trash", isOpen: this.state.dialogOn && this.props.confirmation, onCancel: this.toggleDialog, onConfirm: (e) => {
                    this.props.id && this.props.deletePokemon(this.props.id);
                    this.toggleDialog();
                }, confirmButtonText: "Delete Pokemon", cancelButtonText: "Cancel", intent: Intent.DANGER },
                React.createElement("p", null, "This will delete the currently selected Pokemon. Are you sure you want to do that?"),
                React.createElement("label", { className: cx(Classes.CONTROL, Classes.CHECKBOX) },
                    React.createElement("input", { onChange: (event) => this.props.modifyDeletionConfirmation &&
                            this.props.modifyDeletionConfirmation(!event.target.checked), type: "checkbox" }),
                    React.createElement("span", { className: Classes.CONTROL_INDICATOR }),
                    "Don't Ask Me For Confirmation Again")),
            React.createElement(Popover, { interactionKind: PopoverInteractionKind.HOVER, position: Position.TOP, content: React.createElement("div", { style: { padding: '1rem' } }, `Delete Pok${accentedE}mon`) },
                React.createElement(Icon, { onClick: (e) => {
                        if (this.props.confirmation) {
                            this.toggleDialog();
                        }
                        else {
                            this.props.deletePokemon &&
                                this.props.id &&
                                this.props.deletePokemon(this.props.id);
                        }
                    }, icon: "trash", title: "Delete Pokemon" }))));
    }
}
export const DeletePokemonButton = connect((state) => ({
    confirmation: state.confirmation,
}), {
    deletePokemon,
    modifyDeletionConfirmation,
})(DeletePokemonButtonBase);
