import * as React from 'react';
import { BaseEditor } from 'components/BaseEditor';
import { Button, Intent, TextArea, Checkbox, Toaster, Classes, Spinner } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { css } from 'emotion';
const spinner = css `
    display: inline-block;
`;
export class BugReporterBase extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            userReport: '',
            userReportTitle: '',
            includeNuzlocke: true,
            stage: 1,
            isSending: false,
        };
        this.getButtonPokemon = (stage) => stage === 1 ? 'caterpie' : stage === 2 ? 'metapod' : 'butterfree';
        this.updateReport = (target) => (e) => {
            const text = e.target.value;
            const update = {
                [target]: text,
            };
            this.setState(update);
        };
        this.sendBugReport = () => {
            const { userReport, userReportTitle } = this.state;
            const { state } = this.props;
            const url = `${window.location.origin}/report`;
            this.setState({ isSending: true });
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
                body: JSON.stringify({
                    report: userReport,
                    title: userReportTitle,
                    data: this.state.includeNuzlocke ? JSON.stringify(state) : undefined,
                }),
            })
                .then((res) => res.json())
                .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const toaster = Toaster.create();
                    toaster.show({
                        message: 'Bug report sent!',
                        intent: Intent.SUCCESS,
                    });
                    this.setState({
                        userReport: '',
                        userReportTitle: '',
                        stage: this.state.stage + 1,
                        isSending: false,
                    });
                }
                else {
                    const toaster = Toaster.create();
                    toaster.show({
                        message: 'Bug report failed. Please try again.',
                        intent: Intent.DANGER,
                    });
                    this.setState({ isSending: false });
                }
            })
                .catch((err) => {
                const toaster = Toaster.create();
                toaster.show({
                    message: `Bug report failed. Please try again. ${err}`,
                    intent: Intent.DANGER,
                });
                this.setState({ isSending: false });
            });
        };
    }
    render() {
        const { userReport, userReportTitle, includeNuzlocke, stage, isSending } = this.state;
        const { defaultOpen } = this.props;
        return (React.createElement(BaseEditor, { icon: 'error', name: "Bug Reports and Feature Requests", defaultOpen: defaultOpen },
            React.createElement("div", { style: { margin: '.5rem' } },
                React.createElement("input", { style: {
                        width: '100%',
                        marginBottom: '0.25rem',
                    }, className: Classes.INPUT, required: true, type: "text", placeholder: "Issue Title", value: userReportTitle, onChange: this.updateReport('userReportTitle') }),
                React.createElement(TextArea, { placeholder: "Description (Optional). If relevant, please include info such as steps to reproduce, browser, and OS.", style: { width: '100%' }, value: userReport, onChange: this.updateReport('userReport') }),
                React.createElement("div", { style: {
                        padding: '.5rem',
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                    } },
                    React.createElement(Checkbox, { onChange: (e) => this.setState((state) => ({
                            includeNuzlocke: !state.includeNuzlocke,
                        })), checked: includeNuzlocke, label: 'include nuzlocke.json file' }),
                    React.createElement(Button, { disabled: !userReportTitle || isSending, onClick: this.sendBugReport, minimal: true, intent: Intent.DANGER },
                        "Submit",
                        ' ',
                        isSending ? React.createElement(Spinner, { className: spinner, size: 20 }) : React.createElement("img", { style: { height: '20px', verticalAlign: 'bottom', display: 'inline' }, alt: "", role: "presentation", src: `./icons/pokemon/regular/${this.getButtonPokemon(stage)}.png` }))))));
    }
}
export const BugReporter = connect((state) => ({ state }))(BugReporterBase);
