import * as React from 'react';
import { connect } from 'react-redux';
import { Cell, Column, Table, EditableCell, JSONFormat } from '@blueprintjs/table';
import { sortPokes, generateEmptyPokemon } from 'utils';
import { PokemonKeys } from 'models';
import { editPokemon as editPokemonType } from 'actions';
import { AddPokemonButton } from 'components/AddPokemonButton';
const determineCell = (key, value, id, editPokemon) => {
    if (key === 'extraData') {
        return (React.createElement(Cell, null,
            React.createElement(JSONFormat, null, value)));
    }
    if (key === 'id') {
        return React.createElement(Cell, null, id);
    }
    if (key === 'checkpoints') {
        return React.createElement(Cell, null,
            React.createElement(JSONFormat, null, value));
    }
    return (React.createElement(EditableCell, { onConfirm: (value) => {
            let transformedValue = value;
            if (key === 'moves' || key === 'types') {
                transformedValue = value === null || value === void 0 ? void 0 : value.split(',').map((s) => s.trim());
            }
            editPokemon({ [key]: transformedValue }, id);
        }, value: value }));
};
const cellRenderer = (pokemon, key, editPokemon) => (rowIndex) => {
    return determineCell(key, pokemon[rowIndex][key], pokemon[rowIndex].id, editPokemon);
};
export function renderColumns(pokemon, editPokemon) {
    return Object.keys(PokemonKeys).map((key) => {
        return (React.createElement(Column, { key: key, name: key, cellRenderer: cellRenderer(pokemon, key, editPokemon) }));
    });
}
export function MassEditorTableBase({ pokemon, editPokemon }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { numRows: pokemon.length, numFrozenColumns: 2 }, renderColumns(pokemon, editPokemon)),
        React.createElement("br", null),
        React.createElement(AddPokemonButton, { pokemon: generateEmptyPokemon(pokemon) })));
}
export const MassEditorTable = connect((state) => ({ pokemon: state.pokemon.sort(sortPokes) }), {
    editPokemon: editPokemonType,
})(MassEditorTableBase);
